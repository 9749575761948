import React from "react";
import AddSFBasicDetailsTab from "./AddSfBasicDetails";
import UpdateSfDocuments from "./UpdateSfDocuments";
import SFContactPerson from "./SFContactPerson";
import UpdateSFCallTyeMapping from "./UpdateSfCallTypeMapping";
import UploadSfServiceablefile from "./UploadSfServiceablefile";
import AddUpdateSubOffice from "./AddUpdateSubOffice";
import BankAccount from "./BankAccount";
import { SF_TYPE } from "../../../constant/status_constant";
import { UploadAggrementCopy } from "./UploadAggrementCopy";

const AddUpdateSF = ({ sfdetails, SetSfDetails, roleId }) => {
  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {/* /.col */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header p-2">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <a
                        id="basicdetails_tab"
                        className="nav-link active tabc"
                        href="#basicdetails"
                        data-toggle="tab"
                      >
                        Basic Details
                      </a>
                    </li>
                    {Object.keys(sfdetails).length > 0 && (
                      <>
                        <li className="nav-item">
                          <a
                            id="documents_tab"
                            className="nav-link tabc"
                            href="#documents"
                            data-toggle="tab"
                          >
                            Documents
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            id="contactperson_tab"
                            className="nav-link tabc"
                            href="#contactperson"
                            data-toggle="tab"
                          >
                            Contact Person
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            id="calltypemapping_tab"
                            className="nav-link tabc"
                            href="#calltypemapping"
                            data-toggle="tab"
                          >
                            Calltype Mapping
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            id="uploadpincode_tab"
                            className="nav-link tabc"
                            href="#uploadpincode"
                            data-toggle="tab"
                          >
                            Upload Serviceable Pincode
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            id="upcountry_tab"
                            className="nav-link tabc"
                            href="#upcountry"
                            data-toggle="tab"
                          >
                            Add Sub District office's
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            id="bankaccount_tab"
                            className="nav-link tabc"
                            href="#bankaccount"
                            data-toggle="tab"
                          >
                            Bank Account
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            id="aggrement_tab"
                            className="nav-link tabc"
                            href="#aggrement"
                            data-toggle="tab"
                          >
                            Aggrement Copy
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="tab-content">
                    {/* /.tab-pane */}
                    <div className="tab-pane tabc active" id="basicdetails">
                      <AddSFBasicDetailsTab
                        sfdetails={sfdetails}
                        SetSfDetails={SetSfDetails}
                        roleId={roleId}
                      />
                    </div>
                    {/* /.tab-pane */}
                    {Object.keys(sfdetails).length > 0 && (
                      <>
                        <div className="tab-pane tabc" id="documents">
                          <UpdateSfDocuments sfdetails={sfdetails} SetSfDetails={SetSfDetails} roleId={roleId}/>
                        </div>
                        <div className="tab-pane tabc" id="contactperson">
                          <SFContactPerson sfdetails={sfdetails} SetSfDetails={SetSfDetails} roleId={roleId}/>
                        </div>
                        <div className="tab-pane tabc" id="calltypemapping">
                          <UpdateSFCallTyeMapping sfdetails={sfdetails}roleId={roleId} />
                        </div>
                        <div className="tab-pane tabc" id="uploadpincode">
                          <UploadSfServiceablefile sfdetails={sfdetails} roleId={roleId}/>
                        </div>
                        <div className="tab-pane tabc" id="upcountry">
                          <AddUpdateSubOffice sfdetails={sfdetails} roleId={roleId}/>
                        </div>
                        <div className="tab-pane tabc" id="bankaccount">
                          <BankAccount sf_id={sfdetails.id}/>
                        </div>
                        <div className="tab-pane tabc" id="aggrement">
                          <UploadAggrementCopy entity_id={sfdetails.id} entity_type={SF_TYPE}/>
                        </div>
                        
                      </>
                    )}
                  </div>
                  {/* /.tab-content */}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
};

export default AddUpdateSF;
