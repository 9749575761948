import React, { useState, useEffect } from "react";
import { PARTNER_TYPE, SF_TYPE } from "../../../constant/status_constant";
import MainBody from "../../../component/MainBody";
import Select from "react-select";
import httpRequest from "../../../utils/Http";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const Addbanktransaction = () => {
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [entityList, setEntityList] = useState([]);
  const [bankNameList, setBankNameList] = useState([]);
  const entityTypeList = [
    {
      label: "Partner",
      value: PARTNER_TYPE,
    },
    {
      label: "Service Center",
      value: SF_TYPE,
    },
  ];

  const creditdebitList = [
    {
      label: "Credit",
      value: "Credit",
    },
    {
      label: "Debit",
      value: "Debit",
    },
  ];

  const transactionMode = [
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Cheque",
      value: "Cheque",
    },
    {
      label: "Online",
      value: "Online",
    },
    {
      label: "Upi",
      value: "Upi",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  useEffect(() => {
    (async () => {
      if (Number(formData.entity_type) === PARTNER_TYPE) {
        const partners = await httpRequest("getpartnerlist", {}, "GET");
        if (partners.status === true && partners.data) {
          setEntityList(partners.data["rows"]);
        }
      } else if (Number(formData.entity_type) === SF_TYPE) {
        const service_center = await httpRequest(
          "getsfdropdownlist",
          {},
          "GET"
        );
        if (service_center.status === true && service_center.data) {
          setEntityList(service_center.data);
        }
      }

      const bankLnamelist = await httpRequest("getallbanknamelist", {}, "GET");
      if (bankLnamelist.status === true && bankLnamelist.data) {
        setBankNameList(bankLnamelist.data);
      }
    })();
  }, [formData.entity_type]);

  
  const validateFrom = (data) => {
    const errors = {};
    if (!data.entity_type) {
      errors.entity_type = "Entity Type is required";
    }
    if (!data.entity_id) {
      errors.entity_id = "Company Name is required";
    }
    if (!data.bank_name) {
      errors.bank_name = "Bank Name is required";
    }
    if (!data.transaction_mode) {
      errors.transaction_mode = "Transaction Mode is required";
    }
    if (!data.credit_debit) {
      errors.credit_debit = "Credit / Debit is required";
    }
    if (!data.amount) {
      errors.amount = "Total Amount is required";
    } else if (Number(data.amount) <= 0) {
      errors.amount = "Total Amount should be greater than zero";
    }
    if (!data.transaction_id) {
      errors.transaction_id = "Transaction Id is required";
    }
    if (!data.transaction_date) {
      errors.transaction_date = "Transaction Date is required";
    }
    return errors;
  }
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setSubmit(false);
      (async () => {

        const data = await httpRequest(
          "addbanktransaction",
          formData,
          "POST"
        );

        if (data.status === true && data.message) {
          toast.success(data.message);
          setFormData({});
        } else {
          toast.error(data.message);
        }
      })();
    }
    //eslint-disable-next-line
}, [formErrors, isSubmit]);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Add Bank Statement"
        breadcrumbSubTitle=""
        component={
          <>
            <form onSubmit={onSubmitHandler}>
              <section className="content">
                <div className="container-fluid">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Bank Transaction</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <label>
                              Entity Type *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_type}
                              </span>
                            </label>
                            <Select
                              name="entity_type"
                              value={
                                entityTypeList.find(
                                  (option) =>
                                    option.value === formData.entity_type
                                ) || null
                              }
                              options={entityTypeList}
                              placeholder="Select Entity Type"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  entity_type: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Company Name *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_id}
                              </span>
                            </label>
                            <Select
                              name="entity_id"
                              value={
                                entityList.find(
                                  (option) =>
                                    option.value === formData.entity_id
                                ) || null
                              }
                              options={entityList}
                              placeholder="Select Company"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  entity_id: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Credit / Debit *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.credit_debit}
                              </span>
                            </label>
                            <Select
                              name="creditDebit"
                              value={
                                creditdebitList.find(
                                  (option) =>
                                    option.value === formData.credit_debit
                                ) || null
                              }
                              options={creditdebitList}
                              placeholder="Select Credit / Debit"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  credit_debit: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Transaction Mode *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.transaction_mode}
                              </span>
                            </label>
                            <Select
                              name="transactionMode"
                              value={
                                transactionMode.find(
                                  (option) =>
                                    option.value === formData.transaction_mode
                                ) || null
                              }
                              options={transactionMode}
                              placeholder="Select Transaction Mode"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  transaction_mode: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Bank Name *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.bank_name}
                              </span>
                            </label>
                            <Select
                              name="bank_name"
                              value={
                                bankNameList.find(
                                  (option) => option.label === formData.bank_name
                                ) || null
                              }
                              options={bankNameList}
                              placeholder="Select Bank Name"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  bank_name: val.label,
                                });
                              }}
                            />
                          </div>

                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Transaction Date *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.transaction_date}
                              </span>
                            </label>
                            <DatePicker
                              className="form-control"
                              minDate={
                                new Date(
                                  new Date().setDate(new Date().getDate() - 7)
                                )
                              }
                              dateFormat="dd/MM/yyyy"
                              selected={formData.transaction_date}
                              placeholderText="Select Transaction Date"
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  transaction_date: date,
                                });
                              }}
                            />
                          </div>

                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Transaction Id *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.transaction_id}
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Transaction Id"
                              className="form-control"
                              name="transactionId"
                              value={formData.transaction_id}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  transaction_id: e.target.value,
                                });
                              }}
                            />
                          </div>

                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Total Amount *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.amount}
                              </span>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Amount"
                              step={"0.02"}
                              className="form-control"
                              name="amount"
                              value={formData.amount}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  amount: e.target.value,
                                });
                              }}
                            />
                          </div>

                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Remarks {" "}
                              <span style={{ color: "red" }}>
                                {formErrors.remarks}
                              </span>
                            </label>
                            <textarea
                              type="text"
                              placeholder="Enter Remarks"
                              className="form-control"
                              name="remarks"
                              value={formData.remarks}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  remarks: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <div className="col-md-12">
                          <button className="btn btn-primary btn-lg col-md-12">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </form>
          </>
        }
      />
    </div>
  );
};

export default Addbanktransaction;
