import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MyDataTable from "../../../../component/MyDataTable";
import Select from "react-select";
import httpRequest from "../../../../utils/Http";
import { toast } from "react-toastify";
import { getdropdownList, openSignUrl } from "../../../../utils/HelperFunction";
import { UploadFileToS3 } from "../../../../utils/S3File";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DispatchChallanToSFTab = ({
  warehouse_id,
  reloadList,
  setFormErrors,
  formErrors,
  setReload,
}) => {
  const [formData, setFormData] = useState({ warehouse_id });
  const [serviceChecked, setServiceChecked] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [selectedScAndPartner, setSelectedScAndPartner] = useState(null);
  const [checkboxError, setCheckboxError] = useState("");
  const [isOpenForm, setOpenForm] = useState(false);
  const [courierNamelist, setCourierNameList] = useState([]);
  const [courierDate, setCourierDate] = useState();

  // Handle checkbox change
  const handleChargesCheckbox = (event, row) => {
    const { id, receiver_id } = row;

    if (event.target.checked) {
      if (
        !selectedScAndPartner ||
        selectedScAndPartner.receiver_id === receiver_id
      ) {
        setSelectedScAndPartner({ receiver_id });
        setCheckboxError("");
        setFormData((prevData) => ({
          ...prevData,
          receiver_id,
          data: [...(prevData.data || []), id],
        }));
        setServiceChecked((prevChecked) => [...prevChecked, id]);
      } else {
        setCheckboxError(
          "You can only select checkboxes with the same Service Center."
        );
      }
    } else {
      setServiceChecked((prevChecked) =>
        prevChecked.filter((checkedId) => checkedId !== id)
      );
      setFormData((prevData) => ({
        ...prevData,
        data: (prevData.data || []).filter((itemId) => itemId !== id),
      }));
      if (serviceChecked.length === 1) {
        setSelectedScAndPartner(null);
        setFormData((prevData) => ({ ...prevData, receiver_id: null }));
      }
    }
  };

  // Validate form function
  const validateForm = (value) => {
    const errors = {};
    if (!value.warehouse_id) {
      errors.warehouse_id = "Central Warehouse is Required";
    }
    if (!value.courier_name) {
      errors.courier_name = "Courier Name is Required";
    }

    if (!value.awb_number) {
      errors.awb_number = "AWB Number is Required";
    }

    if (!value.remarks) {
      errors.remarks = "Remarks is Required";
    }

    if (!value.courier_price) {
      errors.courier_price = "Courier Price is Required";
    }

    if (!value.courier_date) {
      errors.courier_date = "Courier Date is Required";
    }

    if (serviceChecked.length < 1) {
      errors.checkbox = "Select at least one checkbox";
    }

    return errors;
  };

  const closeForm = () => {
    setOpenForm(false);
  };

  useEffect(() => {
    if(formData.awb_number && formData.courier_name){
      (async () => {
        setFormData({...formData, courierRead: false})
        const courier = await httpRequest(
          `checkcourierexist`,
          {awb_number: formData.awb_number, courier_name: formData.courier_name, warehouse_id},
          "POST"
        );

        if (courier.status === true && courier.data) {
          setFormData({
            ...formData,
            courier_price: courier.data.courier_price,
            courier_date: courier.data.shippment_date,
            courierRead: true
          })
          setCourierDate(courier.data.shippment_date)
        } else if(courier.status === false){
          toast.error(courier.message);
          setFormData({
            ...formData,
            courier_name: null,
            awb_number: ''
          })
        }
      })()
    }

  }, [formData.courier_name, formData.awb_number])

  // Effect to submit form data when no errors and isSubmit is true
  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let postData = { ...formData };

        if (formData.courier_file) {
          const courier_file = await UploadFileToS3(
            "docFile",
            formData.courier_file
          );
          postData.courier_file = courier_file;
        }

        const data = await httpRequest(
          "updateforwardchallan",
          postData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          document.getElementById("courierupdate").reset();
          closeForm();
          toast.success(data.message);
          setFormData({ warehouse_id });
          setCourierDate("");
          setReload(true);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit]);

  const rejectForwardChallan = async (msl_id) => {
    const data = await httpRequest("rejectforwardchallan", { msl_id }, "POST");

    if (data.status === true && data.message) {
      toast.success(data.message);
      setReload(true);
    } else {
      toast.error(data.message);
    }
  };

  // Columns for the data table
  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Service Center",
      selector: (row) => row.receiver_service_center.display_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Msl</div>,
      selector: (row) => row.msl_id,
      cell: (row) => (
        <Link
          to={"javascript:void(0)"}
          onClick={() => openSignUrl(row.main_file)}
        >
          {row.msl_id}
        </Link>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Msl Date</div>,
      selector: (row) => new Date(row.msl_date).toDateString(),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Quantity</div>,
      selector: (row) => row.quantity,
      wrap: true,
    },
    {
      name: "Basic Amount",
      selector: (row) => row.taxable_value,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Gst Amount</div>,
      selector: (row) => row.gst_amount,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Invoice/Challan</div>,
      selector: (row) => (Number(row.is_invoice) === 1 ? "Invoice" : "Challan"),
      wrap: true,
    },
    {
      name: "Reverse Challan",
      cell: (row) =>
        Number(row.is_invoice) !== 1 && (
          <button
            className="btn btn-danger btn-sm"
            onClick={(e) => {
              rejectForwardChallan(row.id);
            }}
          >
            Reject
          </button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: "Select",
      cell: (row) => (
        <input
          type="checkbox"
          className="form-control charges_checkbox text-capitalize"
          name="charges_checkbox"
          id={`charges_checkbox_${row.id}`}
          checked={serviceChecked.includes(row.id)}
          onChange={(e) => handleChargesCheckbox(e, row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);

  // Function to handle form submission
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(true);
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
      getdropdownList(setCourierNameList, `getcouriernamelist?is_active=1`);
    })();
    //eslint-disable-next-line
  }, []);

  const formAddUpdate = () => {
    return (
      <>
        <div className="form-overlay">
          <div className="form-container">
            <div className="form-content">
              <form id="courierupdate" onSubmit={onSubmitHandler}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card" style={{ marginBottom: "0px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Update Shippment</h3>
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={closeForm}
                          style={{ float: "right" }}
                        >
                          X
                        </button>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_name}
                                </span>
                              </label>
                              <Select
                                name="courier_name"
                                value={
                                  courierNamelist.find(
                                    (option) =>
                                      option.label === formData.courier_name
                                  ) || null
                                }
                                options={courierNamelist}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    courier_name: val.label,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                AWB Number *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.awb_number}
                                </span>
                              </label>
                              <input
                                type="text"
                                name="awb_number"
                                value={formData.awb_number}
                                className="form-control"
                                placeholder="Enter AWB Number"
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    awb_number: val.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier Price *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_price}
                                </span>
                              </label>
                              <input
                                type="number"
                                min={0}
                                value={formData.courier_price}
                                readOnly={formData.courierRead === true ? true: false}
                                name="courier_name"
                                className="form-control"
                                placeholder="Enter Docket NUmber"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    courier_price: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier Date *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_date}
                                </span>
                              </label>
                              <DatePicker
                                className="form-control text-capitalize"
                                minDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() - 7)
                                  )
                                }
                                maxDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate())
                                  )
                                }
                                dateFormat="dd/MM/yyyy"
                                selected={courierDate}
                                readOnly={formData.courierRead === true ? true: false}
                                placeholderText="Select Courier Date"
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    courier_date: date,
                                  });
                                  setCourierDate(date);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier invoice file{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_file}
                                </span>
                              </label>
                              <input
                                type="file"
                                name="courier_file"
                                className="form-control"
                                readOnly={formData.courierRead === true ? true: false}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    courier_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Remarks *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.remarks}
                                </span>
                              </label>
                              <input
                                type="text"
                                name="remarks"
                                className="form-control"
                                value={formData.remarks}
                                placeholder="Enter Remarks"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    remarks: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer text-center"
                        style={{
                          marginBottom: "60px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-primary col-md-12"
                          style={{ marginLeft: "0px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {warehouse_id && (
        <>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <MyDataTable
                    reloadList={reloadList}
                    columns={columns}
                    getDataListURL={`getforwardchallannotdispatched?warehouse_id=${warehouse_id}`}
                    setReload={setReload}
                    exportName={"sparelist"}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              {serviceChecked.length > 0 && (
                <div className="row">
                <div className="col-md-12 text-right">
                  <span style={{ color: "red" }}>{formErrors.checkbox}</span>
                  <span style={{ color: "red" }}>{checkboxError}</span>
                  <button
                    className="btn btn-primary"
                    disabled={serviceChecked.length > 0 ? false : true}
                    onClick={() => {
                      setOpenForm(true);
                    }}
                  >
                    Dispatch Challan
                  </button>
                </div>
              </div>
              )}
              
            </div>
            {isOpenForm && formAddUpdate()}
          </section>
        </>
      )}
    </div>
  );
};

export default DispatchChallanToSFTab;
