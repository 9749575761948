import React, { useEffect, useState } from "react";
import MainBody from "../../../../component/MainBody";
import httpRequest from "../../../../utils/Http";
import Select from "react-select";
import Switch from "../../../../component/Switch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyDataTable from "../../../../component/MyDataTable";

const ApplianceMapping = () => {
  const [partnerlist, setPartnerlist] = useState([]);
  const [brandList, setBrandlist] = useState([]);
  const [categoryList, setCategorylist] = useState([]);
  const [capacityList, setCapacitylist] = useState([]);
  const [modellist, setModellist] = useState([]);
  const [appliancelist, setApplianceList] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [reloadList, setReload] = useState(false);
  const [formData, setFormData] = useState({appliance_id: null, model_id: null});
  const [formErrors, setFormErrors] = useState({});
  const [isOpenForm, setOpenForm] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const closeForm = () => {
    setOpenForm(false);
  };

  useEffect(() => {
    (async () => {
      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );
      console.log(partners);
      if (partners.status === true && partners.data) {
        setPartnerlist(partners.data["rows"]);
      }
      const listdata = await httpRequest(
        `getappliancelist?isactive=1`,
        {},
        "GET"
      );
      if (listdata.status === true && listdata.data) {
        setApplianceList(listdata.data["rows"]);
      }
    })();
  }, []);

  const filterMapping = async () => {
    if (filterData.partner_id && filterData.appliance_id) {
      setReload(true);
    } else {
      toast.error("Partner & Appliance both are mandatory");
    }
  };

  const filterView = () => {
    return (
      <>
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="form-group">
              <label>
                Partner *<span style={{ color: "red" }}></span>
              </label>
              <Select
                options={partnerlist}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    partner_id: val.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>
                Appliance *<span style={{ color: "red" }}></span>
              </label>
              <Select
                name="appliance_id"
                options={appliancelist}
                onChange={(val) => {
                  setFilterData({
                    ...filterData,
                    appliance_id: val.value,
                  });
                }}
              />
            </div>
          </div>
          <div
            className="col-md-4 d-flex align-items-end"
            style={{ marginTop: "14px" }}
          >
            <div className="col-md-6">
              <button
                type="submit"
                id="submit"
                className="btn btn-dark"
                onClick={() => {
                  filterMapping();
                }}
              >
                Submit
              </button>
            </div>
            <div className="col-md-6">
              <button
                type="submit"
                id="submit"
                className="btn btn-primary pull-right"
                onClick={() => {
                    setOpenForm(true);
                    setFormData({});
                  }}
              >
                Add New Mapping
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const updateToggleHandler = async (id, value) => {
    try {
      setReload(false);
      let postData = {
        id,
        is_active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updateappliancemappingstatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating the status.");
    }
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Partner",
      selector: (row) => row.partner["display_name"],
      cell: (row) => <div className="word-wrap">{row.partner["display_name"]}</div>,
    },
    {
      name: "Appliance",
      selector: (row) => row.appliance["appliance_name"],
      cell: (row) => <div className="word-wrap">{row.appliance["appliance_name"]}</div>,
    },
    {
      name: "Brand",
      selector: (row) => row.brand["brand_name"],
    },
    {
      name: "Model Number",
      selector: (row) => row.appliance_model["model_number"],
      cell: (row) => <div className="word-wrap">{row.appliance_model["model_number"]}</div>,
    },
    {
      name: "Category",
      selector: (row) => row.category["category_name"],
    },
    {
      name: "Capacity",
      selector: (row) => row.capacity["capacity_name"],
    },
    {
      name: "Status",
      selector: (row) =>
        Number(row.is_active) === 1 ? "Activated" : "DeActivated",
        cell: (row) => (
            <Switch
            isOn={Number(row.is_active) === 1 ? true : false}
            id={row.id}
            handleToggle={(e) => {
                updateToggleHandler(row.id, e.target.checked);
            }}
            onColor="#EF476F"
            />
        ),
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
      cell: (row) => <div className="word-wrap">{new Date(row.createdAt).toDateString()}</div>,
    },
    {
      name: "Update",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            setFormData({
              ...row
             })
      
             setOpenForm(true);
          }}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  const validateForm = (value) => {
    const errors = {};
    if (!value.partner_id) {
        errors.partner_id = "Partner is Required";
    }
    if (!value.appliance_id) {
      errors.appliance_id = "Appliance is Required";
    }

    if (!value.model_id) {
      errors.model_id = "Model Number is Required";
    }

    if (!value.brand_id) {
      errors.brand_id = "Brand is Required";
    }
    if (!value.category_id) {
      errors.category_id = "Category is Required";
    }
    if (!value.capacity_id) {
      errors.capacity_id = "Capacity is Required";
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit)
    {
      setSubmit(false);
      (async () => {
        const data = await httpRequest("addupdateappliancemapping", formData, "POST");

      if (data.status === true && data.message) {
          
          setReload(true);

          toast.success(data.message);
          setFormData({appliance_id: null, model_id: null});
          closeForm();
        } else {
          toast.error(data.message);
        }
      })();
    }
    //eslint-disable-next-line
  }, [formErrors, isSubmit]);

  useEffect(() => {
    if(formData.appliance_id){
      (async() => {
        const listdata = await httpRequest(
          `getmodellist?isactive=1&appliance_id=${formData.appliance_id}`,
          {},
          "GET"
        );
        if (listdata.status === true && listdata.data) {
          setModellist(listdata.data["rows"]);
        } else {
          setModellist([])
          setFormData({...formData, model_id: null})
        }
      })()
    }
  }, [formData.appliance_id])

  useEffect(() => {
    if(formData.model_id){
      (async() => {
        const listbrand = await httpRequest(
          `getbrandlist?isactive=1`,
          {},
          "GET"
        );
        if (listbrand.status === true && listbrand.data) {
          setBrandlist(listbrand.data["rows"]);
        } else {
          setBrandlist([])
          setFormData({...formData, brand_id: null})
        }
        const listcategory = await httpRequest(
          `getcategorylist?isactive=1`,
          {},
          "GET"
        );
        if (listcategory.status === true && listcategory.data) {
          setCategorylist(listcategory.data["rows"]);
        } else {
          setCategorylist([])
          setFormData({...formData, category_id: null})
        }
        const listcapacity = await httpRequest(
          `getcapacitylist?isactive=1`,
          {},
          "GET"
        );
        if (listcapacity.status === true && listcapacity.data) {
          setCapacitylist(listcapacity.data["rows"]);
        } else {
          setCapacitylist([])
          setFormData({...formData, capacity_id: null})
        }
      })()
    }
  }, [formData.model_id])

  const formAddUpdate = () => {
    return (
      <div className="form-overlay">
        <div className="form-container">
          <div className="form-content">
            <form id="assignPartner" onSubmit={onSubmitHandler}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" style={{ marginBottom: "0px" }}>
                    <div className="card-header">
                      <h3 className="card-title">
                        {formData.id ? "Update  Model" : "Add Model"}
                      </h3>
                      <button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={closeForm}
                        style={{ float: "right" }}
                      >
                        X
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Partner *
                              <span style={{ color: "red" }}>
                                {formErrors.partner_id}
                              </span>
                            </label>
                            <Select
                              defaultValue={formData?.partner?.display_name ? { label: formData.partner.display_name, value: formData.patner_id } : undefined}
                              name="partner_id"
                              options={partnerlist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  partner_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Appliance Name *
                              <span style={{ color: "red" }}>
                                {formErrors.appliance_id}
                              </span>
                            </label>
                            <Select
                              defaultValue={
                                formData?.appliance?.appliance_name
                                  ? {
                                      label: formData.appliance.appliance_name,
                                      value: formData.appliance_id,
                                    }
                                  : undefined
                              }
                              name="appliance_id"
                              options={appliancelist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  appliance_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Model Number *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.model_id}
                              </span>
                            </label>
                            <Select
                            defaultValue={formData?.appliance_model?.model_number ? { label: formData.appliance_model.model_number, value: formData.model_id } : undefined}
                              name="model_id"
                              options={modellist}
                              value={modellist.filter(
                                (option) => option.value === formData.model_id
                              )}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  model_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                        <div className="form-group">
                            <label>
                              Brand *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.brand_id}
                              </span>
                            </label>
                            <Select
                            defaultValue={formData?.brand?.brand_name ? { label: formData.brand.brand_name, value: formData.brand_id } : undefined}
                              name="brand_id"
                              value={brandList.filter(
                                (option) => option.value === formData.brand_id
                              )}
                              options={brandList}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  brand_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>
                              Category *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.category_id}
                              </span>
                            </label>
                            <Select
                             defaultValue={formData?.category?.category_name ? { label: formData.category.category_name, value: formData.category_id } : undefined}
                              name="category_id"
                              value={categoryList.filter(
                                (option) => option.value === formData.category_id
                              )}
                              options={categoryList}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  category_id: val.value,
                                });
                              }}
                            />
                          </div>
                          {/* /.form-group */}
                        </div>
                        <div className="col-md-6">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>
                              Capacity *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.capacity_id}
                              </span>
                            </label>
                            <Select
                            defaultValue={formData?.capacity?.capacity_name ? { label: formData.capacity.capacity_name, value: formData.capacity_id } : undefined}
                              name="capacity_id"
                              options={capacityList}
                              value={capacityList.filter(
                                (option) => option.value === formData.capacity_id
                              )}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  capacity_id: val.value,
                                });
                              }}
                            />
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-center"
                      style={{
                        marginBottom: "60px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary col-md-12"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <MainBody
        breadcrumbTitle="Appliance Mapping"
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <div className="card-header">{filterView()}</div>
                      <div className="card-body">
                        <MyDataTable
                          reloadList={reloadList}
                          columns={columns}
                          getDataListURL={`getappliancemappinglist?isactive=all&partner_id=${filterData.partner_id}&appliance_id=${filterData.appliance_id}`}
                          setReload={setReload}
                          exportName={"applianceMapping"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isOpenForm && formAddUpdate()}
            </section>
          </>
        }
      />
    </>
  );
};

export default ApplianceMapping;
