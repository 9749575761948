import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getUserDetails, login, logout } from "./reducers/auth";
import AdminRoutes from "./routes/AdminRoutes";
import ScRoutes from "./routes/ScRoutes";
import PartnerRoutes from "./routes/PartnerRoutes";
//import 'bootstrap';
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/js/bootstrap.js';
//import $ from 'jquery';
//import Popper from 'popper.js';

function App() {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const user = useSelector((state) => state.authReducer.currentUser);
  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState(0);
  const [entityType, setEntityType] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(login({ token }));
      dispatch(getUserDetails());
    } else {
      dispatch(logout())
    }
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setRoleId(user.role_id);
      setEntityType(user.entity_type)
    }
  }, [user]);

  return (
    <div className="wrapper">
      <BrowserRouter>
        {!isLoggedIn && (
          <>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="*" element={<Navigate to="/login" />} />
            </Routes>
          </>
        )}
        
        {isLoggedIn && Number(entityType) === 1 && <AdminRoutes isLoggedIn={isLoggedIn} roleId={roleId}/>}
        {isLoggedIn && Number(entityType) === 3 && <ScRoutes isLoggedIn={isLoggedIn}/>}
        {isLoggedIn && Number(entityType) === 2 && <PartnerRoutes isLoggedIn={isLoggedIn}/>}
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
