import React, { useEffect, useState } from "react";
import Select from "react-select";
import { LABEL_APPLIANCE } from "../../../constant/label_constant";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";

const UpdatePartForm = ({ row, opAppliance, setReload, partner_id }) => {
    const number8digitRegex = /^\d{8}$/;
    const [formData, setFormData] = useState(row);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setSubmit] = useState(false);
    const opGSTRate = [{
        label: '18', value: 18,
    }, {
        label: '28', value: 28
    }];

    useEffect(() => {
        if (row)
        {
            setFormData(row);

        }
    }, [row]);

    const validateFrom = (value) => {
        const errors = {};

        if (!value.part_id)
        {
            errors.part_id = "Invalid Form Data. Please refresh  try again";
        }

        if (!value.appliance_id)
        {
            errors.appliance_id = "Please select Appliance";
        }

        if (!value.part_type)
        {
            errors.part_type = "Part Type is mandatory";
        }

        if (!value.part_name)
        {
            errors.part_name = "Part Name is mandatory";
        }

        if (!value.part_number)
        {
            errors.part_number = "Part Name is mandatory";
        }
        if (!value.hsn_code)
        {
            errors.hsn_code = "HSN Code is mandatory";
        } else if (!number8digitRegex.test(value.hsn_code))
        {
            errors.hsn_code = "Invalid HSN Code";
        }

        if (!value.basic_rate)
        {
            errors.basic_rate = "Basic Buying Price is mandatory";
        } else if (value.basic_rate < 1)
        {
            errors.basic_rate = "Basic Buying Price should be greater than zero";
        }

        return errors;
    }

    const updateHandler = (e) => {
        e.preventDefault();
        setSubmit(false);
        setFormErrors(validateFrom(formData));
        setSubmit(true);
    }

    useEffect(() => {
        (async () => {
            formData.partner_id = partner_id;
            console.log(formData);
            if (Object.keys(formErrors).length === 0 && isSubmit)
            {

                //console.log(formData);
                const data = await httpRequest(
                     "updatepartdeatils",
                    formData,
                    'POST'
                );

                // console.log(data);
                if (data.status === true && data.message)
                {
                    toast.success( data.message)
                    setReload(true);
                } else
                {
                    toast.error( data.message)
                }
                setSubmit(false);
            }
        })();
    }, [isSubmit, formErrors]);

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="modal fade" id="modal-xl">
                                <div className="modal-dialog modal-xl">
                                    <form onSubmit={updateHandler}>
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">{row.part_name + " - " + row.part_number}</h4>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="row">

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                {LABEL_APPLIANCE} *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.appliance_id}
                                                                </span>
                                                            </label>
                                                            <Select
                                                                name="appliance_id"
                                                                options={opAppliance}
                                                                value={
                                                                    opAppliance.find(
                                                                      (option) =>
                                                                        option.value === formData.appliance_id
                                                                    ) || null
                                                                  }
                                                                onChange={(val) => {
                                                                    setFormData({ ...formData, appliance_id: val.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Part Type *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.part_type}
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                name="part_type"
                                                                defaultValue={row.part_type}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, part_type: e.target.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>


                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Part Name *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.part_name}
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                name="part_name"
                                                                defaultValue={row.part_name}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, part_name: e.target.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Part Number *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.part_number}
                                                                </span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                name="part_number"
                                                                defaultValue={row.part_number}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, part_number: e.target.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                HSN Code *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.hsn_code}
                                                                </span>
                                                            </label>
                                                            <input
                                                                type={"number"}
                                                                className="form-control"
                                                                name="hsn_code"
                                                                defaultValue={row.hsn_code}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, hsn_code: e.target.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Basic Buying Price *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.basic_rate}
                                                                </span>
                                                            </label>
                                                            <input
                                                                type={"number"}
                                                                step={"0.02"}
                                                                className="form-control"
                                                                name="basic_rate"
                                                                defaultValue={row.basic_rate}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, basic_rate: e.target.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Gst Rate *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.gst_rate}
                                                                </span>
                                                            </label>
                                                            <Select
                                                                name="gst_rate"
                                                                options={opGSTRate}
                                                                value={
                                                                    opGSTRate.find(
                                                                      (option) =>
                                                                        option.value === formData.gst_rate
                                                                    ) || null
                                                                  }
                                                                onChange={(val) => {
                                                                    setFormData({ ...formData, gst_rate: val.value });
                                                                    
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Partner OOW Part Margin *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.sf_oow_margin}
                                                                </span>
                                                            </label>
                                                            <input
                                                                type={"number"}
                                                                step={"0.02"}
                                                                className="form-control"
                                                                name="partner_oow_margin"
                                                                defaultValue={row.partner_oow_margin}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, partner_oow_margin: e.target.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                SF Part Margin *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.sf_oow_margin}
                                                                </span>
                                                            </label>
                                                            <input
                                                                type={"number"}
                                                                step={"0.02"}
                                                                className="form-control"
                                                                name="sf_oow_margin"
                                                                defaultValue={row.sf_oow_margin}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, sf_oow_margin: e.target.value });
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Is Defective Required *{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {formErrors.is_defective_required}
                                                                </span>
                                                            </label>
                                                            <input
                                                                type={"checkbox"}
                                                                className="form-control"
                                                                name="is_defective_required"
                                                                defaultChecked={row.is_defective_required}
                                                                onChange={(e) => {

                                                                    if (e.target.checked)
                                                                    {

                                                                        setFormData({ ...formData, is_defective_required: 1 });
                                                                    } else
                                                                    {

                                                                        setFormData({ ...formData, is_defective_required: 0 });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {/* /.form-group */}
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="modal-footer justify-content-between">
                                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                                <button type="submit" className="btn btn-primary">Save changes</button>
                                            </div>
                                        </div>
                                    </form>
                                    {/* /.modal-content */}
                                </div>
                                {/* /.modal-dialog */}
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </>
    )
}

export default UpdatePartForm;