import React, { useState, useEffect } from "react";
import Select from "react-select";
import httpRequest from "../../../utils/Http";
import { UploadFileToS3 } from "../../../utils/S3File";
import { toast } from "react-toastify";
import { SF_TYPE } from "../../../constant/status_constant";
import { Link } from "react-router-dom";
import { openSignUrl } from "../../../utils/HelperFunction";

const BankAccount = ({ sf_id }) => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({entity_id: sf_id, entity_type: SF_TYPE});
  const [bankNameList, setBankNameList] = useState([]);
  const accountType = [
    {
      label: "Savings",
      value: "Savings",
    },
    {
      label: "Current",
      value: "Current",
    },
  ];

  useEffect(() => {
    (async () => {
      const bankLnamelist = await httpRequest("getallbanknamelist", {}, "GET");
      if (bankLnamelist.status === true && bankLnamelist.data) {
        setBankNameList(bankLnamelist.data);
      }
      const bankAccount = await httpRequest(`getbankaccount?entity_id=${sf_id}&entity_type=${SF_TYPE}`, {}, "GET");
      if (bankAccount.status === true && bankAccount.message) {
        setFormData({
            ...formData,
            ...bankAccount.message
        })
      }

    })();
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (values) => {
    const errors = {};

    if (!values.ifsc_code) {
      errors.ifsc_code = "Enter IFSC Code";
    }

    if (!values.bank_name) {
      errors.bank_name = "Select Bank Name";
    }

    if (!values.account_type) {
      errors.account_type = "Select Account Type";
    }

    if (!values.beneficiary_name) {
      errors.beneficiary_name = "Enter Beneficiary Name";
    }
    if (!values.account_number) {
      errors.account_number = "Enter Account Number";
    }

    if (!values.cheque && !values.cheque_file) {
      errors.cheque_file = "Upload Cheque File";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const postData = formData;
        if (formData.cheque) {
          const cheque_file = await UploadFileToS3("docFile", formData.cheque);
          postData.cheque_file = cheque_file;
        }
        const data = await httpRequest(
          "addupdatebankaccount",
          postData,
          "POST"
        );
        if (data.status === true && data.message) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [isSubmit, formErrors]);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <form id="bankAccountform" onSubmit={onSubmitHandler}>
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Bank Account</h3>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        IFSC Code *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.ifsc_code}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        id="ifsc_code"
                        placeholder="Enter IFSC Code"
                        defaultValue={formData.ifsc_code}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ifsc_code: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Bank Name *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.bank_name}
                        </span>
                      </label>
                      <Select
                        name="bank_name"
                        value={
                          bankNameList.find(
                            (option) => option.label === formData.bank_name
                          ) || null
                        }
                        options={bankNameList}
                        placeholder="Select Bank Name"
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            bank_name: val.label,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Account Type *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.account_type}
                        </span>
                      </label>
                      <Select
                        name="account_type"
                        value={
                          accountType.find(
                            (option) => option.label === formData.account_type
                          ) || null
                        }
                        options={accountType}
                        placeholder="Select Account Type"
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            account_type: val.label,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Beneficiary Name *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.beneficiary_name}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        id="beneficiary_name"
                        placeholder="Enter Beneficiary Name"
                        defaultValue={formData.beneficiary_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            beneficiary_name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Bank Account Number *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.account_number}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        id="account_number"
                        placeholder="Enter Bank Account Number"
                        defaultValue={formData.account_number}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            account_number: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Cheque File *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.cheque_file}
                        </span>
                      </label>
                      <div className="input-group">
                      <input
                        type="file"
                        className="form-control rounded-0"
                        id="cheque"
                        name="cheque"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            cheque: e.target.files[0],
                          })
                        }
                      />
                     <span className="input-group-append">
                        <button type="button" target="_blank" onClick={() => {
                            openSignUrl(formData.cheque_file)
                        }} className="btn btn-default btn-flat"><i className="fas fa-eye"></i></button>
                        </span>
                      </div>
                     

                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <div className="row">
                  <span style={{ color: "red" }}>{formErrors.mandatory}</span>
                  <br></br>
                  <input
                    type="submit"
                    className="form-control btn btn-primary"
                    value={"Submit"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default BankAccount;
