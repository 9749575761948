import React from "react";
import MyDataTable from "../../../../component/MyDataTable";
import { NavLink } from "react-router-dom";
import { getPartWarranty } from "../../../../utils/HelperFunction";

const PartsOnApproval = ({ reloadList, setReload }) => {
  const columns = [
    {
      name: "SNo.",
      selector: (row) => "", // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="wrap-word">Spare Requested Id</div>,
      selector: (row) => row.requested_id,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Booking ID</div>,
      selector: (row) => row.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.bid)}`}>
          {row.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Partner</div>,
      selector: (row) => row.partner_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Service Center</div>,
      selector: (row) => row.sc_name,
      wrap: true,
    },
    {
      name: "Part Name",
      selector: (row) => `${row.part_name} / ${row.part_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Model Number</div>,
      selector: (row) => row.model_number,
      wrap: true,
    },
    {
      name: "Warranty",
      selector: (row) => getPartWarranty(row.warranty_status),
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.requested_quantity,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Age of Request</div>,
      selector: (row) => `${row.age_of_requested} days`,
      wrap: true,
    },
  ].filter(Boolean);
  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MyDataTable
                reloadList={reloadList}
                columns={columns}
                getDataListURL={`partsonapproval?getdata=true`}
                setReload={setReload}
                exportName={"sparelist"}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PartsOnApproval;
