import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import httpRequest from "../../../utils/Http";

const RejectForm = ({ row, action, setReload, closeForm }) => {
  const [formData, setFormData] = useState({bid : row.id});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  const validateForm = (value) => {
    const errors = {};

    if(!value.remarks){
        errors.remarks = "Remarks is required";
    }
    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
        if(action > 0){
            if (Object.keys(formErrors).length === 0 && isSubmit) {
                let data;
                if(action == 1){
                    data = await httpRequest("rejectfromreview", formData, "POST");
                } else {
                    data = await httpRequest("approveunderreviewbooking", formData, "POST");
                }
                
                if (data.status === true && data.message) {
                  toast.success(data.message);
                  setReload(true);
                  closeForm();
                } else {
                  toast.error(data.message);
                }
                setSubmit(false);
              }
        }
    })();
  }, [isSubmit, action, formErrors, formData, setReload, closeForm]);

  return (
    <>
      <div className="form-overlay">
        <div className="form-container" style={{ maxWidth: "500px" }}>
          <div className="form-content">
            <form id="assignPartner" onSubmit={onSubmitHandler}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" style={{ marginBottom: "0px" }}>
                    <div className="card-header">
                      <h3 className="card-title">{action == 1 ? (<>Reject</>) : (<>Approve</>)}  Booking - {row.booking_id}</h3>
                      <button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={closeForm}
                        style={{ float: "right" }}
                      >
                        X
                      </button>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Remarks *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.sc_id}
                              </span>
                            </label>
                            <textarea
                                name="remarks"
                                id="remarks"
                                value={formData.remarks}
                                placeholder="Enter remarks"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-center"
                      style={{
                        marginBottom: "60px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <button type="submit" id="submit" className="btn btn-primary col-md-12" style={{margin: "0px"}}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectForm;
