import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UploadFileToS3 } from "../../../utils/S3File";

const AddWarehouseList = ({ partnerId, role_id }) => {
  const [formData, setFormData] = useState({ partner_id: partnerId });
  const [formErrors, setFormErrors] = useState({});
  const [cityOption, setCityOption] = useState([]);
  const [cityValue, setCityValue] = useState(null);
  const [employeeAgentList, setEmployeeAgentList] = useState([]);
  const [agentList, setAgent] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);

  const selectAllStateOption = {
    value: "selectAll",
    label: "Select All",
  };

  useEffect(() => {
    (async () => {
      const agentData = await httpRequest(
        `getAllDropDownAgentList?partner_id=${partnerId}`,
        {},
        "GET"
      );
      if (agentData.status === true && agentData.message) {
        //console.log(roleData.message);
        setAgent(agentData.message);
      }
      const agentData1 = await httpRequest(
        `getWarehouseemployeeList`,
        {},
        "GET"
      );
      if (agentData1.status === true && agentData1.message) {
        //console.log(roleData.message);
        setEmployeeAgentList(agentData1.message);
      }

      const stateData = await httpRequest("getAllStateList", {}, "GET");
      if (stateData.status === true && stateData.message) {
        stateData.message.unshift(selectAllStateOption);
        setStateList(stateData.message);
      }

      const warehouselist = await httpRequest(`getWarehouseList?partner_id=${partnerId}`, {}, "GET");

      if (warehouselist.status == true && warehouselist.data) {
        //console.log(roleData.message);
        setWarehouseList(warehouselist.data);
      }
    })();
  }, []);

  const getAllCity = async (pincode, city = "", callback) => {
    setCityOption([]);
    setCityValue(null);

    const listdata = await httpRequest(
      `getAllCity?pincode=${pincode}`,
      {},
      "GET"
    );

    if (listdata.status === true && listdata.message) {
      setCityOption(listdata.message);
      setFormData((prevFormData) => ({
        ...prevFormData,
        state: listdata.message[0].state,
        pincode,
      }));

      if (city) {
        const c = listdata.message.find((option) => option.label === city);
        setCityValue(c);
      }

      // Execute the callback after updating city options
      if (callback) {
        callback();
      }
    }
  };

  const handleSelectChange = (selected) => {
    if (selected.some((option) => option.value === "selectAll")) {
      setFormData({ ...formData, warehouse_state_mapping: stateList.slice(1) });
      setSelectedOptions(stateList.slice(1));
    } else {
      setSelectedOptions(
        selected.filter((option) => option.value !== "selectAll")
      );
      setFormData({
        ...formData,
        warehouse_state_mapping: selected.filter(
          (option) => option.value !== "selectAll"
        ),
      });
    }
  };

  const validateFrom = (value) => {
    let errors = {};
    if (!value.display_name) {
      errors.display_name = "Display Name is required";
    }
    if (!value.address) {
      errors.address = "Address is required";
    }
    if (!value.city) {
      errors.city = "City is required";
    }
    if (!value.pincode) {
      errors.pincode = "Pincode is required";
    }

    if (!value.partner_contact_person) {
      errors.partner_contact_person = "Partner Contact Person is required";
    }

    if (!value.warehouse_contact_person) {
      errors.warehouse_contact_person = "Warehouse Contact Person is required";
    }

    if (!value.gst_number) {
      errors.gst_number = "GST Number is required";
    } else {
      let regx = new RegExp(
        "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
      );
      if (!regx.test(value.gst_number)) {
        errors.gst_number = "Please enter valid GST Number";
      }
    }

    if (
      value.pre_gst_number &&
      value.gst_number &&
      value.gst_number != value.pre_gst_number
    ) {
      if (!value.gst_file) {
        errors.gst_file = "GST File is Required (Only Image & PDF file)";
      }
    }

    if (!value.gst_file && !value.pre_gst_number) {
      errors.gst_file = "GST File is Required (Only Image & PDF file)";
    }

    if (!value.warehouse_state_mapping) {
      errors.warehouse_state_mapping = "Warehouse State mapping is required";
    } else {
      if (value.warehouse_state_mapping.length === 0) {
        errors.warehouse_state_mapping = "Warehouse State mapping is required";
      }
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const form = {};
        for (const property in formData) {
          if (formData.hasOwnProperty(property)) {
            if (typeof formData[property] === "string") {
              form[property] = formData[property];
            } else {
              form[property] = JSON.stringify(formData[property]);
            }
          }
        }

        if (formData["gst_file"]) {
          const gstfile = await UploadFileToS3("docFile", formData["gst_file"]);
          form.gst_file = gstfile;
        } else if (formData["pre_gst_file"]) {
          form.gst_file = formData["pre_gst_file"];
        }

        const data = await httpRequest(`addwarehouse`, form, "POST");

        if (data.status === true && data.message) {
          setWarehouseList(data.data);
          toast.success(data.message);
          setFormData({ partner_id: partnerId });
          document.getElementById("addwarehouse").reset();
          setSelectedOptions([]);
          setCityValue("");
          toggleDivVisibility();
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [isSubmit, formErrors]);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);

    if (isDivVisible == false) {
      setFormData({ partner_id: partnerId });
    }
  };

  const updateRow = (row) => {
    const defaultSelectedOptions = row.warehouse_state_mappings.map((item) => ({
      value: item.state.value,
      label: item.state.label,
    }));

    setIsDivVisible(true);
    getAllCity(row.pincode, row.city, () => {
      setSelectedOptions(defaultSelectedOptions);

      setFormData({
        ...formData,
        partner_id: partnerId,
        warehouse_id: row.warehouse_id,
        display_name: row.display_name,
        address: row.address,
        pincode: row.pincode,
        city: row.city,
        gst_number: row.gst_detail.gst_number,
        partner_contact_person: row.partner_contact_person,
        warehouse_contact_person: row.warehouse_contact_person,
        warehouse_state_mapping: defaultSelectedOptions,
        gst_id: row.gst_detail.id,
        pre_gst_file: row.gst_detail.gst_file,
        pre_gst_number: row.gst_detail.gst_number,
        state: { id: row.state_id, value: row.state_id },
      });
    });
  };

  const updateToggleHandler = async (warehouse_id, value) => {
    try {
      let postData = {
        warehouse_id,
        active: value === true ? 0 : 1,
      };
      const data = await httpRequest("updatewarehousestatus", postData, "POST");

      if (data.status === true && data.message) {
        setWarehouseList(data.data);

        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {}
  };

  return (
    <>
      {isDivVisible && (
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Add Warehouse</h3>
                <div className="card-tools">
                  <span style={{ float: "right" }}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={toggleDivVisibility}
                    >
                      {isDivVisible === false ? (
                        ""
                      ) : (
                        <i className="fas fa-minus"></i>
                      )}
                    </button>
                  </span>
                </div>
              </div>
              <form
                className="form-horizontal"
                id="addwarehouse"
                onSubmit={onSubmitHandler}
                action="javascritp:void(0)"
              >
                {/* /.card-header */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Display Name *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.display_name}
                          </span>
                        </label>
                        <input
                          defaultValue={formData.display_name}
                          type="text"
                          className="form-control"
                          name="display_name"
                          placeholder="Enter Display Name"
                          id="display_name"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              display_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Pincode *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.pincode}
                          </span>
                        </label>
                        <input
                          defaultValue={formData.pincode}
                          type="text"
                          className="form-control"
                          name="pincode"
                          placeholder="Enter Pincode"
                          id="pincode"
                          onChange={(e) => {
                            if (e.target.value.length === 6) {
                              setFormData({
                                ...formData,
                                pincode: e.target.value,
                              });

                              getAllCity(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Address *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.address}
                          </span>
                        </label>
                        <input
                          defaultValue={formData.address}
                          type="text"
                          className="form-control"
                          name="address"
                          placeholder="Enter Warehouse Address"
                          id="display_name"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              address: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          City *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.city}
                          </span>
                        </label>
                        <Select
                          name="city"
                          placeholder="Select City"
                          value={cityValue}
                          options={cityOption}
                          onChange={(val) => {
                            setFormData({ ...formData, city: val.value });
                            setCityValue(val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          GST Number *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.gst_number}
                          </span>
                        </label>
                        <input
                          value={formData.gst_number}
                          type="text"
                          className="form-control text-uppercase"
                          name="gst_number"
                          placeholder="Enter GST Number"
                          id="display_name"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              gst_number: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Employee Warehose POC *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.warehouse_contact_person}
                          </span>
                        </label>
                        <Select
                          value={
                            employeeAgentList.filter(
                              (option) =>
                                option.value ===
                                formData.warehouse_contact_person
                            )[0]
                          }
                          name="manager_id"
                          options={employeeAgentList}
                          onChange={(val) => {
                            setFormData({
                              ...formData,
                              warehouse_contact_person: val.value,
                            });
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Warehouse State Mapping *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.warehouse_state_mapping}
                          </span>
                        </label>

                        <Select
                          name="warehouse_state_mapping"
                          options={stateList}
                          isMulti
                          value={selectedOptions}
                          onChange={handleSelectChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          GST File *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.gst_file}
                          </span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="gst_file"
                          onChange={(e) => {
                            var files = e.target.files;
                            if (files && files[0]) {
                              var file_name = e.target.files[0]["name"];
                              const regex = new RegExp(
                                "(.*?)(pdf|png|jpg|jpeg)$"
                              );
                              if (!regex.test(file_name.toLowerCase())) {
                                setFormErrors({
                                  gst_file:
                                    "GST File is Required (Only PDF & Image file)",
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  gst_file: e.target.files[0],
                                });
                              }
                            } else {
                              setFormErrors({
                                ...formErrors,
                                gst_file:
                                  "GST File is Required (Only PDF & Image) file allow",
                              });
                            }
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Partner Warehose POC *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.partner_contact_person}
                          </span>
                        </label>
                        <Select
                          value={
                            agentList.filter(
                              (option) =>
                                option.value === formData.partner_contact_person
                            )[0]
                          }
                          name="manager_id"
                          options={agentList}
                          onChange={(val) => {
                            setFormData({
                              ...formData,
                              partner_contact_person: val.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-footer col-md-12">
                      <div className="row">
                        <input
                          type="submit"
                          className="form-control btn btn-primary"
                          value={"Submit"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      )}

      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Warehouse List </h3>
              {role_id == 1 && (
                <span style={{ float: "right" }}>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={toggleDivVisibility}
                  >
                    {isDivVisible === false ? "Add Warehouse" : ""}
                  </button>
                </span>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>SNo.</th>
                      <th>Display Name</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>Pincode</th>
                      <th>State</th>
                      <th>State Mapping</th>
                      {role_id == 1 && (
                        <>
                          <th>Edit</th>
                          <th>Status</th>
                        </>
                      )}
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {warehouseList.map((item, index) => (
                      <tr key={item.warehouse_id}>
                        <td>{index + 1}</td>
                        <td>{item.display_name}</td>
                        <td>{item.address}</td>
                        <td>{item.city}</td>
                        <td>{item.pincode}</td>
                        <td>{item.state.state}</td>

                        <td
                          style={{
                            wordBreak: "break-word",
                            "overflow-wrap": "break-word",
                          }}
                        >
                          {item.warehouse_state_mappings
                            .map((items) => items.state.label)
                            .join(", ")}
                        </td>
                        {role_id == 1 && (
                          <>
                            <td>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => updateRow(item)}
                              >
                                Edit
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-info"
                                onClick={() => {
                                  updateToggleHandler(
                                    item.warehouse_id,
                                    item.active
                                  );
                                }}
                              >
                                {item.active === true
                                  ? "Activated"
                                  : "DeActivated"}
                              </button>
                            </td>
                          </>
                        )}

                        <td>{moment(item.createdAt).format("DD MMM YYYY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddWarehouseList;
