import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { LABEL_JOB } from "../../constant/label_constant";
import { useSelector } from "react-redux";
import {
  ACCOUNTS_INVOICE_ONLY,
  ONLY_IM_ACTION_PERMISSION,
} from "../../constant/action_permission";
import { PARTNER_TYPE, SF_TYPE } from "../../constant/status_constant";
import { BACKEND_API_BASE_URL } from "../../utils/BackendBaseUrl";

const Asidebar = ({ isDisplay, setDisplay }) => {
  let user = useSelector((state) => state.authReducer.currentUser);

  // State to track which dropdown is open
  const [openDropdowns, setOpenDropdowns] = useState({});

  // Toggle function for dropdowns
  const toggleDropdown = (name) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  return (
    <div>
      {/* Main Sidebar Container */}
      <aside
        onMouseEnter={() => setDisplay(false)}
        className="main-sidebar main-sidebar-custom sidebar-light-primary"
        style={{ borderRight: "1px solid rgb(222, 226, 230)", overflowY: "auto" }}
      >
        {/* Brand Logo */}
        <NavLink to={"#"} className="brand-link">
          <img
            src="../../dist/img/a-removebg-preview.png"
            alt="Logo"
            className={`brand-image ${!isDisplay ? "brandtextnot" : ""} `}
            style={{ height: "190px", marginTop: "6px" }}
          />
          <span className="brand-text font-weight-light">
            {/* {process.env.REACT_APP_TITLE} */}
            <img
              src="../../dist/img/logo.jpg"
              alt="Logo"
              className=""
              style={{ width: "190px" }}
            />
          </span>
        </NavLink>
        {/* Sidebar */}
        <div className="sidebar" >
          {/* Sidebar user (optional) */}

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-child-indent"
              // data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

              <li className="nav-item">
                <NavLink to={"/dashboard"} className="nav-link" exact={true}>
                  <i
                    className="nav-icon fas fa-home"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Dashboard</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={"/search"} className="nav-link" exact={true}>
                  <i
                    className="nav-icon fas fa-search"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Search</p>
                </NavLink>
              </li>

              {/* Job Dropdown */}
              <li
                className={`nav-item has-treeview ${
                  openDropdowns["job"] ? "menu-open" : ""
                }`}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  onClick={() => toggleDropdown("job")}
                >
                  <i
                    className="nav-icon fas fa-book"
                    style={{ fontSize: "15px" }}
                  />
                  <p>
                    {LABEL_JOB}
                    <i
                      className={`right fas fa-angle-${
                        openDropdowns["job"] ? "down" : "left"
                      }`}
                      style={{ marginLeft: "auto" }}
                    />
                  </p>
                </Link>
                <ul
                  className={`nav nav-treeview ${
                    openDropdowns["job"] ? "d-block" : "d-none"
                  }`}
                >
                  <li className="nav-item">
                    <NavLink to={"/assignsc"} className="nav-link" exact={true}>
                      <i
                        className="far fa-a nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Assign {LABEL_JOB}</p>
                    </NavLink>
                    <NavLink
                      to={"/getcancelledlist"}
                      className="nav-link"
                      exact={true}
                    >
                      <i
                        className="far fa-c nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Cancelled {LABEL_JOB}</p>
                    </NavLink>
                    <NavLink
                      to={"/getcompletedlist"}
                      className="nav-link"
                      exact={true}
                    >
                      <i
                        className="far fa-c nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Completed {LABEL_JOB}</p>
                    </NavLink>
                    <NavLink
                      to={"/pendingbooking"}
                      className="nav-link"
                      exact={true}
                    >
                      <i
                        className="far fa-p nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Pending {LABEL_JOB}</p>
                    </NavLink>
                    <NavLink
                      to={"/pendingunderreview"}
                      className="nav-link"
                      exact={true}
                    >
                      <i
                        className="far fa-r nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Completed In Review</p>
                    </NavLink>
                    <NavLink
                      to={"/cancelledreview"}
                      className="nav-link"
                      exact={true}
                    >
                      <i
                        className="far fa-r nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Cancelled In Review</p>
                    </NavLink>
                    <NavLink
                      to={"/addmisccharge"}
                      className="nav-link"
                      exact={true}
                    >
                      <i
                        className="far fa-m nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Miscellaneous Charge</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <NavLink to={"/spareparts"} className="nav-link" exact="true">
                  <i
                    className="nav-icon fas fa-gear"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Spare Parts</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to={"/partner-list"} className="nav-link" exact={true}>
                  <i
                    className="nav-icon fas fa-building"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Partners</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/viewservicecenter"}
                  className="nav-link"
                  exact={true}
                >
                  <i
                    className="nav-icon fas fa-screwdriver-wrench"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Service Center</p>
                </NavLink>
              </li>

              {ACCOUNTS_INVOICE_ONLY.includes(user.role_id) && (
                <>
                  <li
                    className={`nav-item has-treeview ${
                      openDropdowns["accounts"] ? "menu-open" : ""
                    }`}
                  >
                    <Link
                      to={"#"}
                      className="nav-link"
                      onClick={() => toggleDropdown("accounts")}
                    >
                      <i
                        className="nav-icon fas fa-file-invoice"
                        style={{ fontSize: "15px" }}
                      />
                      <p>
                        Accounts
                        <i
                          className={`right fas fa-angle-${
                            openDropdowns["accounts"] ? "down" : "left"
                          }`}
                          style={{ marginLeft: "auto" }}
                        />
                      </p>
                    </Link>
                    <ul
                      className={`nav nav-treeview ${
                        openDropdowns["accounts"] ? "d-block" : "d-none"
                      }`}
                    >
                      <li className="nav-item">
                        <NavLink
                          to={"/createmanualinvoice"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-a nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Add Manual Invoice</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={"/addbanktransaction"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-a nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Add Bank Statement</p>
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to={`/getbalancesummary`}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-b nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Balance Summary</p>
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to={"/generateinvoice"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-c nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Create Service Invoice</p>
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to={"/customeroowinvoice"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-c nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Customer Invoice</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={`/invoicesummary/${btoa(PARTNER_TYPE)}`}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-p nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Partner Invoice</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={`/invoicesummary/${btoa(SF_TYPE)}`}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-s nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>SC Invoice</p>
                        </NavLink>
                      </li>
                      
                    </ul>
                  </li>
                </>
              )}

              {ONLY_IM_ACTION_PERMISSION.includes(user.role_id) && (
                <>
                  <li
                    className={`nav-item has-treeview ${
                      openDropdowns["warehouse"] ? "menu-open" : ""
                    }`}
                  >
                    <Link
                      to={"#"}
                      className="nav-link"
                      onClick={() => toggleDropdown("warehouse")}
                    >
                      <i
                        className="nav-icon fas fa-store"
                        style={{ fontSize: "15px" }}
                      />
                      <p>
                        Warehouse
                        <i
                          className={`right fas fa-angle-${
                            openDropdowns["warehouse"] ? "down" : "left"
                          }`}
                          style={{ marginLeft: "auto" }}
                        />
                      </p>
                    </Link>
                    <ul
                      className={`nav nav-treeview ${
                        openDropdowns["warehouse"] ? "d-block" : "d-none"
                      }`}
                    >
                      <li
                        className={`nav-item has-treeview ${
                          openDropdowns["msl"] ? "menu-open" : ""
                        }`}
                      >
                        <Link
                          to={"#"}
                          className="nav-link"
                          onClick={() => toggleDropdown("msl")}
                        >
                          <i
                            className="nav-icon fas fa-m"
                            style={{ fontSize: "15px" }}
                          />
                          <p>
                            Msl
                            <i
                              className={`right fas fa-angle-${
                                openDropdowns["msl"] ? "down" : "left"
                              }`}
                              style={{ marginLeft: "auto" }}
                            />
                          </p>
                        </Link>
                        <ul
                          className={`nav nav-treeview ${
                            openDropdowns["msl"] ? "d-block" : "d-none"
                          }`}
                        >
                          <li className="nav-item">
                            <NavLink
                              to={"/uploadinwardmsl"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Msl Inward</p>
                            </NavLink>
                            <NavLink
                              to={"/whintranistmsl"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Msl In-Transit</p>
                            </NavLink>
                            <NavLink
                              to={"/whmsldisputed"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Msl Disputed List</p>
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li
                        className={`nav-item has-treeview ${
                          openDropdowns["part"] ? "menu-open" : ""
                        }`}
                      >
                        <Link
                          to={"#"}
                          className="nav-link"
                          onClick={() => toggleDropdown("part")}
                        >
                          <i
                            className="nav-icon fas fa-p"
                            style={{ fontSize: "15px" }}
                          />
                          <p>
                            Part Master
                            <i
                              className={`right fas fa-angle-${
                                openDropdowns["part"] ? "down" : "left"
                              }`}
                              style={{ marginLeft: "auto" }}
                            />
                          </p>
                        </Link>
                        <ul
                          className={`nav nav-treeview ${
                            openDropdowns["part"] ? "d-block" : "d-none"
                          }`}
                        >
                          <li className="nav-item">
                            <NavLink
                              to={"/partlist"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Part List</p>
                            </NavLink>
                            <NavLink
                              to={"/bomlist"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Bom List</p>
                            </NavLink>
                            <NavLink
                              to={"/uploadpartmaster"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Upload Part List</p>
                            </NavLink>

                            <NavLink
                              to={"/uploadbom"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Upload Part BOM</p>
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to={"/warehousestock"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-w nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Warehouse Stock</p>
                        </NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink
                          to={"/warehousetask"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-w nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Warehouse Task</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={"/partnerwhtask"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="far fa-p nav-icon"
                            style={{ fontSize: "15px" }}
                          />
                          <p>Partner Warehouse Task</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              )}

              <>
                <li
                  className={`nav-item has-treeview ${
                    openDropdowns["report"] ? "menu-open" : ""
                  }`}
                >
                  <Link
                    to={"#"}
                    className="nav-link"
                    onClick={() => toggleDropdown("report")}
                  >
                    <i
                      className="nav-icon fa-solid fa-file-circle-plus"
                      style={{ fontSize: "15px" }}
                    ></i>

                    <p>
                      Reports
                      <i
                        className={`right fas fa-angle-${
                          openDropdowns["report"] ? "down" : "left"
                        }`}
                        style={{ marginLeft: "auto" }}
                      />
                    </p>
                  </Link>
                  <ul
                    className={`nav nav-treeview ${
                      openDropdowns["report"] ? "d-block" : "d-none"
                    }`}
                  >
                    <li className="nav-item">
                      <NavLink
                        to={`${BACKEND_API_BASE_URL}downloadallscdetails`}
                        className="nav-link"
                        target="_blank"
                        exact={true}
                      >
                        <i
                          className="nav-icon fas fa-a"
                          style={{ fontSize: "15px" }}
                        ></i>
                        <p>ASC Report</p>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to={"/bookingsummary"}
                        className="nav-link"
                        exact={true}
                      >
                        <i
                          className="nav-icon fas fa-b"
                          style={{ fontSize: "15px" }}
                        ></i>
                        <p>Booking Summary</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={"/checkserviceavailablity"}
                        className="nav-link"
                        exact={true}
                      >
                        <i
                          className="nav-icon fas fa-c"
                          style={{ fontSize: "15px" }}
                        ></i>
                        <p>Serviceable Area</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={"/sparesummary"}
                        className="nav-link"
                        exact={true}
                      >
                        <i
                          className="nav-icon fas fa-s"
                          style={{ fontSize: "15px" }}
                        ></i>
                        <p>Spare Summary</p>
                      </NavLink>
                    </li>
                    
                  </ul>
                </li>
              </>

              {user && Number(user.role_id) === 1 && (
                <>
                  <li className="nav-header">Master Configuration</li>
                  <li
                    className={`nav-item has-treeview ${
                      openDropdowns["master"] ? "menu-open" : ""
                    }`}
                  >
                    <Link
                      to={"#"}
                      className="nav-link"
                      onClick={() => toggleDropdown("master")}
                    >
                      <i
                        className="nav-icon fas fa-gear"
                        style={{ fontSize: "15px" }}
                      />
                      <p>
                        Master
                        <i
                          className={`right fas fa-angle-${
                            openDropdowns["master"] ? "down" : "left"
                          }`}
                          style={{ marginLeft: "auto" }}
                        />
                      </p>
                    </Link>
                    <ul
                      className={`nav nav-treeview ${
                        openDropdowns["master"] ? "d-block" : "d-none"
                      }`}
                    >
                      <li
                        className={`nav-item has-treeview ${
                          openDropdowns["appliance"] ? "menu-open" : ""
                        }`}
                      >
                        <Link
                          to={"#"}
                          className="nav-link"
                          onClick={() => toggleDropdown("appliance")}
                        >
                          <i
                            className="nav-icon fas fa-a"
                            style={{ fontSize: "15px" }}
                          />
                          <p>
                            Appliance
                            <i
                              className={`right fas fa-angle-${
                                openDropdowns["appliance"] ? "down" : "left"
                              }`}
                              style={{ marginLeft: "auto" }}
                            />
                          </p>
                        </Link>
                        <ul
                          className={`nav nav-treeview ${
                            openDropdowns["appliance"] ? "d-block" : "d-none"
                          }`}
                        >
                          <li className="nav-item">
                            <NavLink
                              to={"/appliance-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Appliance</p>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to={"/category-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Category</p>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to={"/capacity-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Capacity</p>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to={"/brand-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Brand</p>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to={"/calltypegroup-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Calltype Group</p>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to={"/calltype-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Call Type</p>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to={"/model-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Model</p>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to={"/appliance-mapping-list"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Mapping</p>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li
                        className={`nav-item has-treeview ${
                          openDropdowns["call"] ? "menu-open" : ""
                        }`}
                      >
                        <Link
                          to={"#"}
                          className="nav-link"
                          exact={true}
                          onClick={() => toggleDropdown("call")}
                        >
                          <i
                            className="nav-icon fas fa-c"
                            style={{ fontSize: "15px" }}
                          />
                          <p>
                            Call Charges
                            <i
                              className={`right fas fa-angle-${
                                openDropdowns["call"] ? "down" : "left"
                              }`}
                              style={{ marginLeft: "auto" }}
                            />
                          </p>
                        </Link>
                        <ul
                          className={`nav nav-treeview ${
                            openDropdowns["call"] ? "d-block" : "d-none"
                          }`}
                        >
                          <li className="nav-item">
                            <NavLink
                              to={"/addnewcharges"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Add Charges</p>
                            </NavLink>
                            <NavLink
                              to={"/chargeslist"}
                              className="nav-link"
                              exact={true}
                            >
                              <i
                                className="far fa-circle nav-icon"
                                style={{ fontSize: "15px" }}
                              />
                              <p>Charges List</p>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to={"/cancellationreason"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="nav-icon fas fa-c"
                            style={{ fontSize: "15px" }}
                          ></i>
                          <p>Cancellation Reason</p>
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to={"/reschedulereason"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="nav-icon fas fa-r"
                            style={{ fontSize: "15px" }}
                          ></i>
                          <p>Reschedule Reason</p>
                        </NavLink>
                      </li>
                      <li className="nav-item" style={{ marginBottom: "50px" }}>
                        <NavLink
                          to={"/sellerchannel"}
                          className="nav-link"
                          exact={true}
                        >
                          <i
                            className="nav-icon fas fa-s"
                            style={{ fontSize: "15px" }}
                          ></i>
                          <p>Seller Channel</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        <br />
        {/* /.sidebar */}
        {/* <div className="sidebar-custom"></div> */}
        {/* /.sidebar-custom */}
      </aside>
    </div>
  );
};

export default Asidebar;
