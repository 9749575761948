import React, { useState, useEffect } from "react";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import { getdropdownList } from "../../../utils/HelperFunction";
import Select from "react-select";
import MyDataTable from "../../../component/MyDataTable";
import UpdatePartForm from "./UpdatePartForm";
import { useSelector } from "react-redux";
import { UPDATE_PART_MASTER_ACTION_PERMISSION } from "../../../constant/action_permission";
import { PARTNER_TYPE } from "../../../constant/status_constant";

const PartList = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [applianceops, setApplianceOps] = useState([]);
  const [partnerlist, setPartnerlist] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [reloadList, setReload] = useState(true);
  const [updateRow, setUpdateRow] = useState({});
  const [isFilterOpen, setisFilterOpen] = useState(true);
  

  useEffect(() => {
    (async () => {
      if(Number(loggedInUser.entity_type) === PARTNER_TYPE ){
        setFormData({...formData, partner_id: loggedInUser.entity_id})
      } else {
        const partners = await httpRequest(
          "getpartnerlist?is_active=1",
          {},
          "GET"
        );
        //console.log(partners);
        if (partners.status === true && partners.data) {
          const { appliance_id, ...rest } = formData;
          setFormData(rest);
          setPartnerlist(partners.data["rows"]);
        }
      }
      
    })();
    //eslint-disable-next-line
  }, [loggedInUser]);

  useEffect(() => {
    if (formData.partner_id) {
      (async () => {
        getdropdownList(
          setApplianceOps,
          `appliancelistmapping?is_active=1&partner_id=${formData.partner_id}`
        );
      })();
    }
  }, [formData.partner_id]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    //console.log(value);
    if (!value.partner_id) {
      errors.partner_id = "Partner is Required";
    }
    if (!value.appliance_id) {
      errors.appliance_id = "Appliance is Required";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        setReload(true);
        setSubmit(false)
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Part Type",
      selector: (row) => row.part_type,
      wrap: true
    },
    {
      name: "Part Name",
      selector: (row) => row.part_name,
      wrap: true
    },
    {
      name: <div className="word-wrap">Part Number</div>,
      selector: (row) => row.part_number,
      wrap: true
    },
    {
      name: "HSN Code",
      selector: (row) => row.hsn_code,
    },
    {
      name: <div className="word-wrap">Basic Price</div>,
      selector: (row) => row.basic_rate,
    },
    {
      name: <div className="word-wrap">GST Rate (%)</div>,
      selector: (row) => row.gst_rate,
    },
    {
      name: <div className="word-wrap">Total Price</div>,
      selector: (row) => (row.basic_rate * (1 + row.gst_rate / 100)).toFixed(2),
    },
    Number(loggedInUser.entity_type) == 1 && {
      name: <div className="word-wrap">SF Buying Price</div>,
      selector: (row) =>
        (
          row.basic_rate *
          (1 + row.gst_rate / 100) *
          (1 + row.partner_oow_margin / 100)
        ).toFixed(2),
    },
    Number(loggedInUser.entity_type) == 1 && {
      name: <div className="word-wrap">Customer Price</div>,
      selector: (row) =>
        (
          row.basic_rate *
          (1 + row.gst_rate / 100) *
          (1 + (row.sf_oow_margin + row.partner_oow_margin) / 100)
        ).toFixed(2),
    },
    {
      name: <div className="word-wrap">Is Defective Required</div>,
      selector: (row) =>
        Number(row.is_defective_required) === 1 ? "YES" : "NO",
    },
    {
      name: <div className="word-wrap">Create Date</div>,
      selector: (row) => new Date(row.createdAt).toDateString(),
      wrap: true
    },
    UPDATE_PART_MASTER_ACTION_PERMISSION.includes(loggedInUser.role_id) && {
      name: "Update",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setUpdateRow(row);
          }}
          data-toggle="modal"
          data-target="#modal-xl"
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);;

  return (
    <div>
      <MainBody
        breadcrumbTitle="Part List"
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <>
           {isFilterOpen && (
            <section className="content">
              <div className="container-fluid">
                <form
                  id="uploadpart"
                  onSubmit={onSubmitHandler}
                  encType="multipart/form-data"
                >
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">
                        {Number(loggedInUser.entity_type) == 1 ? 'Filter By both Partner & Appliance' : 'Filter Appliance'}
                        
                      </h3>
                      <div className="card-tools" onClick={() => {
                        setisFilterOpen(false)
                    }}>
                        <i className="fas fa-minus"></i>
                    </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {Number(loggedInUser.entity_type) == 1 && (
                          <>
                           <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Partner *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.partner_id}
                              </span>
                            </label>
                            <Select
                              name="partner_id"
                              value={
                                partnerlist.find(
                                  (option) =>
                                    option.value === formData.partner_id
                                ) || null
                              }
                              options={partnerlist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  partner_id: val.value,
                                  appliance_id: null,
                                  brand_id: null,
                                  category_id: null,
                                  capacity_id: null,
                                  model_id: null,
                                  model_number: "",
                                });
                              }}
                            />
                          </div>
                        </div>
                          </>
                        )}
                       

                        <div className="col-md-4">
                          <div className="form-group is-invalid ">
                            <label>
                              Appliance *
                              <span style={{ color: "red" }}>
                                {formErrors.appliance_id}
                              </span>
                            </label>
                            <Select
                              name="appliance_id"
                              options={applianceops}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  appliance_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>
                              .{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_file}
                              </span>
                            </label>
                            <input
                              type="submit"
                              className="form-control btn btn-primary"
                              value={"Submit"}
                            />
                          </div>

                          {/* /.form-group */}
                        </div>
                        {/* /.col */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
            )}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Part List</h3>
                        <div
                          className="card-tools"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setisFilterOpen(true);
                          }}
                        >
                          <i className="fas fa-filter"></i>
                        </div>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={`getpartmasterlist?appliance_id=${formData.appliance_id}&partner_id=${formData.partner_id}`}
                        setReload={setReload}
                        exportName={"partlist"}
                      />
                    </div>
                  </div>
                  <UpdatePartForm
                        row={updateRow}
                        opAppliance={applianceops}
                        setReload={setReload}
                        partner_id={formData.partner_id}

                    />
                  <div className="col-md-6"></div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default PartList;
