import React from "react";
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <div>
      <footer className="main-footer" style={{borderTop: "0px"}}>
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 1.0
        </div>
        <strong>
          Copyright © 2024 <Link to={"#"}>{process.env.REACT_APP_TITLE}</Link>.
        </strong>{" "}
        All rights reserved.
      </footer>
    </div>
  );
};

export default Footer;
