import React from "react";
import { NavLink } from "react-router-dom";
import { getPartWarranty } from "../../../../utils/HelperFunction";
import { PART_DELIVERED } from "../../../../constant/status_constant";
import MyDataTable from "../../../../component/MyDataTable";

const PartDelivered = ({reloadList, setReload}) => {

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Booking ID",
      selector: (row) => row.booking_detail.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
          {row.booking_detail.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
        name: <div>Customer Name</div>,
        selector: (row) => row.booking_detail.user_detail.customer_name,
        wrap: true,
    },
    {
        name: "Appliance",
        selector: (row) => row.booking_detail.appliance.appliance_name,
        wrap: true,
    },
    {
      name: <div className="word-wrap">Requested Part Name</div>,
      selector: (row) => row.requested_part.part_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Requested Part Number</div>,
      selector: (row) => row.requested_part.part_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Requested Model Number</div>,
      selector: (row) => row.requested_model.model_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Shipped Part Name</div>,
      selector: (row) => row.shipped_part.part_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Shipped Part Number</div>,
      selector: (row) => row.shipped_part.part_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Shipped Quantity</div>,
      selector: (row) => row.shipped_quantity,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Shipped Model Number</div>,
      selector: (row) => row.shipped_model.model_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Serial Number</div>,
      selector: (row) => row.booking_detail.user_appliance_detail.sf_serial_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Warranty Status</div>,
      selector: (row) => getPartWarranty(row.warranty_status),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Requested / Received Date</div>,
      selector: (row) =>
        `${new Date(
          row.createdAt
        ).toDateString()}/ ${new Date(
          row.forward_part_ack_date
        ).toDateString()}`,
      wrap: true,
    },
  ];

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Part Delivered (Not Conusmed)</h3>
                </div>
                <MyDataTable
                  reloadList={reloadList}
                  columns={columns}
                  getDataListURL={`getsparedetailslist?status=${PART_DELIVERED}`}
                  setReload={setReload}
                  exportName={"partordered"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PartDelivered;
