import React, { useEffect, useState } from "react";
import Select from "react-select";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ONLY_RM_ASM_ACTION_PERMISSION } from "../../../constant/action_permission";

const UpdateSFCallTyeMapping = ({ sfdetails, roleId }) => {
  const [applianceData, setApplianceData] = useState([]);
  const [allCallTypeGroup, setCallTypeGroup] = useState({});
  const [formData, setFormData] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  const setselecteddata = (val, item, index) => {
    formData[index].calltype_group = val;
    setFormData(formData);
  };

  useEffect(() => {
    (async () => {
      const listdata = await httpRequest(
        "getsfcalltypemappinglistview?sf_id=" + sfdetails.id,
        {},
        "GET"
      );
      if (listdata.status == true && listdata.data) {
        setApplianceData(listdata.data);
        setFormData(listdata.data);
      }

      const listdata2 = await httpRequest("getcalltypegrouplist", {}, "GET");
      if (listdata2.status == true && listdata2.data) {
        setCallTypeGroup(listdata2.data["rows"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isSubmit) {
        let formData1 = new FormData();
        formData1.append("formdata", JSON.stringify(formData));

        const data = await httpRequest(
          "updatecalltypedata?sf_id=" + sfdetails.id,
          formData,
          "POST"
        );
        if (data.status === true && data.message) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [isSubmit]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setSubmit(true);
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">Update Call Type Mapping</h3>
            </div>
            <form id="calltypeeditor" onSubmit={onSubmitHandler}>
              <div className="card-body">
                <div className="row">
                  {applianceData.map((item, index) => (
                    <div key={"colsm-" + index} className="col-12 col-sm-6">
                      <div key={"formgroup-" + index} className="form-group">
                        <label key={"label-" + index}>
                          {item.appliance_name}

                          {/* {item.calltype_group.length > 0 && (
                                                        setFormData({ ...formData, calltype_group: item.calltype_group });
                                                    )} */}
                        </label>
                        <input
                          type="hidden"
                          name="appliance_id"
                          value={item.appliance_id}
                        />
                        <Select
                          defaultValue={item.calltype_group}
                          key={index}
                          isMulti={true}
                          name="calltype_group_id"
                          options={allCallTypeGroup}
                          onChange={(val) => {
                            setselecteddata(val, item, index);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && (
                  <div className="card-footer">
                    <div className="row">
                      <input
                        type="submit"
                        className="form-control btn btn-primary"
                        value={"Submit"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateSFCallTyeMapping;
