import React, { useState, useEffect } from "react";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import { openSignUrl } from "../../../utils/HelperFunction";
import { Link } from "react-router-dom";
import Select from "react-select";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "../../../asset/css/excelcss.css";
import { toast } from "react-toastify";
import MyDataTable from "../../../component/MyDataTable";
import { useSelector } from "react-redux";
import { PARTNER_TYPE } from "../../../constant/status_constant";

const MslInward = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [partnerlist, setPartnerlist] = useState([]);
  const [fromGstList, setFromGstList] = useState([]);
  const [warehouselist, setWarehouselist] = useState([]);
  const [rows, setRows] = useState(null);
  const [cols, setCols] = useState(null);
  const [isSubmit, setSubmit] = useState(false);
  const [reloadList, setReload] = useState(true);
  const opIsInvoice = [
    {
      label: "Challan",
      value: 2,
    },
    {
      label: "Invoice",
      value: 1,
    },
  ];

  useEffect(() => {
    (async () => {
      if (Number(loggedInUser.entity_type) == PARTNER_TYPE) {
        setFormData({ ...formData, partner_id: loggedInUser.entity_id });
      } else {
        const partners = await httpRequest(
          "getpartnerlist?is_active=1",
          {},
          "GET"
        );
        if (partners.status === true && partners.data) {
          const { from_gst_id, ...rest } = formData;
          setFormData(rest);
          setPartnerlist(partners.data["rows"]);
        }
      }

      const warehouse = await httpRequest("getcwhdropdownlist", {}, "GET");
      if (warehouse.status === true && warehouse.data) {
        setWarehouselist(warehouse.data);
      }
    })();
    //eslint-disable-next-line
  }, [loggedInUser]);

  useEffect(() => {
    (async () => {
      if (Number(loggedInUser.entity_type) == PARTNER_TYPE) {
        setFormData({ ...formData, partner_id: loggedInUser.entity_id });
      }
      if(formData.partner_id){
        const from_gst = await httpRequest(
          `getgstnumberdropdownlist?entity_id=${formData.partner_id}`,
          {},
          "GET"
        );
        if (from_gst.status === true && from_gst.data) {
          setFromGstList(from_gst.data);
        }
      }

    })();
    //eslint-disable-next-line
  }, [formData.partner_id, loggedInUser]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    if (!value.partner_id) {
      errors.partner_id = "Partner is Required";
    }
    if (!value.from_gst_id) {
      errors.from_gst_id = "Partner Gst is Required";
    }
    if (!value.warehouse_id) {
      errors.warehouse_id = "Central Warehouse is Required";
    }
    if (!value.is_invoice) {
      errors.is_invoice = "Msl Type is Required";
    }
    if (!value.file) {
      errors.file = "Excel File is Required (Only xlsx & xls file)";
    }
    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        document.getElementById("uploadpart").reset();
        const data = await httpRequest(
          "processuploadmslinward",
          formData,
          "POST",
          "multipart/form-data"
        );

        if (data.status === true && data.message) {
          toast.success(data.message);
          setFormData({});
          setRows(null);
          setCols(null);
          setReload(true);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "File Name",
      selector: (row) => row.file_name,
      cell: (row) => (
        <Link
          to={"javascript:void(0)"}
          className="btn-link text-primary"
          onClick={() => openSignUrl(row.file_path)}
        >
          {row.file_name}
        </Link>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Response",
      selector: (row) => <div className="word-wrap">{row.response}</div>,
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
  ];

  return (
    <div>
      <MainBody
        breadcrumbTitle="Upload MSL"
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <form
                id="uploadpart"
                onSubmit={onSubmitHandler}
                encType="multipart/form-data"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">
                            Upload Msl in Central Warehouse
                          </h3>
                          <span className="float-right">
                            <Link
                              to={"javascript:void(0)"}
                              className="btn-link text-primary"
                              onClick={() =>
                                openSignUrl("samplefolder/mslinward.xlsx")
                              }
                            >
                              Download Sample File
                            </Link>
                          </span>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {Number(loggedInUser.entity_type) !==
                              PARTNER_TYPE && (
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    Partner *{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.partner_id}
                                    </span>
                                  </label>
                                  <Select
                                    name="partner_id"
                                    value={
                                      partnerlist.find(
                                        (option) =>
                                          option.value === formData.partner_id
                                      ) || null
                                    }
                                    options={partnerlist}
                                    onChange={(val) => {
                                      setFormData({
                                        ...formData,
                                        partner_id: val.value,
                                        from_gst_id: null,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  Partner Gst Number *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.from_gst_id}
                                  </span>
                                </label>
                                <Select
                                  name="partner_id"
                                  value={
                                    fromGstList.find(
                                      (option) =>
                                        option.value === formData.from_gst_id
                                    ) || null
                                  }
                                  options={fromGstList}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      from_gst_id: val.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  Central Warehouse *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.warehouse_id}
                                  </span>
                                </label>
                                <Select
                                  name="warehouse_id"
                                  value={
                                    warehouselist.find(
                                      (option) =>
                                        option.value === formData.warehouse_id
                                    ) || null
                                  }
                                  options={warehouselist}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      warehouse_id: val.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  Msl Type *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.is_invoice}
                                  </span>
                                </label>
                                <Select
                                  name="is_invoice"
                                  options={opIsInvoice}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      is_invoice: val.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              {/* /.form-group */}
                              <div className="form-group">
                                <label>
                                  Upload Part File *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.part_file}
                                  </span>
                                </label>
                                <input
                                  name="file"
                                  type="file"
                                  className="form-control"
                                  accept=".xlsx, .xls"
                                  onChange={(e) => {
                                    var files = e.target.files;
                                    if (files && files[0]) {
                                      var file_name = e.target.files[0]["name"];
                                      const regex = new RegExp(
                                        "(.*?)(xlsx|xls)$"
                                      );
                                      if (
                                        !regex.test(file_name.toLowerCase())
                                      ) {
                                        setFormErrors({
                                          part_file:
                                            "Part File is Required (Only xlsx & xls file)",
                                        });
                                      } else {
                                        setRows(null);
                                        setCols(null);
                                        ExcelRenderer(
                                          e.target.files[0],
                                          (err, resp) => {
                                            //handle fail case here
                                            if (err) {
                                              // console.log(err);
                                            }
                                            //handle success case here
                                            else {
                                              //  console.log(resp.cols);
                                              setRows(resp.rows);
                                              setCols(resp.cols);
                                            }
                                          }
                                        );
                                        setFormData({
                                          ...formData,
                                          file: e.target.files[0],
                                        });
                                      }
                                    } else {
                                      setFormErrors({
                                        model: "Only xlsx & xls file allow",
                                      });
                                    }
                                  }}
                                />
                              </div>

                              {/* /.form-group */}
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  .{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.to_gst_id}
                                  </span>
                                </label>
                                <input
                                  type="submit"
                                  value={"Submit"}
                                  className="btn btn-primary form-control"
                                />
                              </div>
                            </div>

                            {/* /.form-group */}
                          </div>
                          <div className="row">
                            <div className={rows && "col-md-12 restrict-card"}>
                              {rows && (
                                <OutTable
                                  data={rows}
                                  columns={cols}
                                  tableClassName="table table-bordered table-striped dataTable dtr-inline"
                                  tableHeaderRowClass="heading"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">File Upload History</h3>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={
                          "getfileuploadhistory?type=MSL-INWARD-EXCEL"
                        }
                        setReload={setReload}
                        exportName={"fileuploadlist"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default MslInward;
