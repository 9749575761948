import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import httpRequest from "../../../utils/Http";

const AssignVendor = ({ bid, setReload, closeForm }) => {
  const [formData, setFormData] = useState({});
  const [sclist, setSclist] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  useEffect(() => {
    if (bid) {
      setFormData((prev) => ({ ...prev, id: bid }));
      (async () => {
        const scdata = await httpRequest(`getSflistforAssignBooking?bid=${bid}`, {}, "GET");
        if (scdata.status === true && scdata.data) {
          setSclist(scdata.data);
        } else {
          setSclist([]);
        }
      })();
    }
  }, [bid]);

  const validateForm = (value) => {
    const errors = {};

    if (!value.sc_id) {
      errors.sc_id = "Service Center is required";
    }
    if(!value.remarks){
        errors.remarks = "Remarks is required";
    }
    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest("processassignvendor", formData, "POST");

        if (data.status === true && data.message) {
          toast.success(data.message);
          setReload(true);
          closeForm();
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [isSubmit, formErrors, formData, setReload, closeForm]);

  return (
    <>
      <div className="form-overlay">
        <div className="form-container" style={{ maxWidth: "500px" }}>
          <div className="form-content">
            <form id="assignPartner" onSubmit={onSubmitHandler}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" style={{ marginBottom: "0px" }}>
                    <div className="card-header">
                      <h3 className="card-title">Assign Vendor</h3>
                      <button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={closeForm}
                        style={{ float: "right" }}
                      >
                        X
                      </button>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Service Center *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.sc_id}
                              </span>
                            </label>
                            <Select
                              name="sc_id"
                              placeholder="Select Service Center"
                              value={sclist.find((option) => option.value === formData.sc_id) || null}
                              options={sclist}
                              onChange={(val) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  sc_id: val.value,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Remarks *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.sc_id}
                              </span>
                            </label>
                            <textarea
                                name="remarks"
                                id="remarks"
                                value={formData.remarks}
                                placeholder="Enter remarks"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-center"
                      style={{
                        marginBottom: "60px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <button type="submit" id="submit" className="btn btn-primary col-md-12" style={{margin: "0px"}}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignVendor;
