import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MyDataTable from "../../../../component/MyDataTable";
import { getPartWarranty } from "../../../../utils/HelperFunction";
import httpRequest from "../../../../utils/Http";
import Select from "react-select";
import { toast } from "react-toastify";

const ReceivedReverseParts = ({ setReload, reloadList, warehouse_id }) => {
  const [partnerlist, setPartnerlist] = useState([]);
  const [formData, setFormData] = useState({ warehouse_id, partner_id: null });
  const [formErrors, setFormErrors] = useState({});
  const [serviceChecked, setServiceChecked] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [selectedScAndPartner, setSelectedScAndPartner] = useState(null);

  useEffect(() => {
    (async () => {
      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );
      if (partners.status === true && partners.data) {
        setPartnerlist(
          partners.data["rows"].map((partner) => ({
            value: partner.partner_id,
            label: partner.display_name,
          }))
        );
      }
    })();
  }, []);

  const handleCheckbox = (row) => {
    const {
      id,
      bid,
      requested_part,
      shipped_quantity,
      booking_detail,
      requested_model,
      msl_line_item,
    } = row;

    const { part_id, part_name, part_number } = requested_part;
    const { partner_id, booking_id, user_appliance_detail } = booking_detail;
    const { serial_number } = user_appliance_detail;
    const { model_id, model_number } = requested_model;
    const {MslSaleItemModel, MSLPurchaseItemModel} = msl_line_item;
    let is_invoice;
    let to_gst_id;

    if(MSLPurchaseItemModel?.msl_detail){
      is_invoice = MSLPurchaseItemModel.msl_detail.is_invoice;
      to_gst_id = MSLPurchaseItemModel.msl_detail.to_gst_id;
    } else {
      is_invoice = MslSaleItemModel.msl_detail.is_invoice;
      to_gst_id = MslSaleItemModel.msl_detail.to_gst_id;
    }

    setFormData((prevData) => ({
      ...prevData,
      is_invoice,
      to_gst_id,
      partner_id,
      data: [
        ...(prevData.data || []),
        {
          id,
          partner_id,
          serial_number,
          bid,
          shipped_quantity,
          booking_id,
          model_id,
          is_invoice,
          model_number,
          part_id,
          part_number,
          part_name,
          to_gst_id,
        },
      ],
    }));

    return true


    // if (event.target.checked) {
    //   if (!selectedScAndPartner) {
    //     setSelectedScAndPartner({ is_invoice, to_gst_id, partner_id });
    //     setServiceChecked((prevChecked) => [...prevChecked, id]);
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       is_invoice,
    //       to_gst_id,
    //       data: [
    //         ...(prevData.data || []),
    //         {
    //           id,
    //           partner_id,
    //           serial_number,
    //           bid,
    //           shipped_quantity,
    //           booking_id,
    //           model_id,
    //           is_invoice,
    //           model_number,
    //           part_id,
    //           part_number,
    //           part_name,
    //           to_gst_id,
    //         },
    //       ],
    //     }));
    //   }
    //  else  if (
    //     selectedScAndPartner.is_invoice === is_invoice &&
    //     selectedScAndPartner.partner_id === partner_id &&
    //     selectedScAndPartner.to_gst_id === to_gst_id
    //   ) {
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       data: [
    //         ...(prevData.data || []),
    //         {
    //           id,
    //           partner_id,
    //           serial_number,
    //           bid,
    //           shipped_quantity,
    //           booking_id,
    //           model_id,
    //           is_invoice,
    //           model_number,
    //           part_id,
    //           part_number,
    //           part_name,
    //           to_gst_id
    //         },
    //       ],
    //     }));
    //     setServiceChecked((prevChecked) => [...prevChecked, id]);
    //   } else {
    //     toast.error(
    //       "You can only select checkboxes with the same type invoice/Challan & Gst Number"
    //     );
    //   }
    // } else {
    //   setServiceChecked((prevChecked) =>
    //     prevChecked.filter((sid) => sid !== id)
    //   );
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     data: (prevData.data || []).filter((item) => item.id !== id),
    //   }));
    //   if (serviceChecked.length === 1) {
    //     setSelectedScAndPartner(null);
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       is_invoice: null,
    //       to_gst_id:null
    //     }));
    //   }
    // }
  };

  const generateChallan = (e, row) => {
    e.preventDefault();
   const confirm = window.confirm("Are you sure you want to create challan/invoice?");
   if(confirm){
    handleCheckbox(row)
    console.log(formErrors)
    setSubmit(false);
    setFormErrors(validateForm(formData));
    setSubmit(true);
   }
    
  };

  const getInvoiceChallan = (row) => {
    if(row.msl_line_item.MSLPurchaseItemModel?.msl_detail){
      return row.msl_line_item.MSLPurchaseItemModel.msl_detail.is_invoice == 1 ? 'Invoice' : 'Challan'
    } else {
      return row.msl_line_item.MslSaleItemModel.msl_detail.is_invoice == 1 ? 'Invoice' : 'Challan'
    }
  }

  const validateForm = (value) => {
    const errors = {};
    if (!value.warehouse_id) {
      errors.warehouse_id = "Central Warehouse is Required";
    }
    if (!value.partner_id) {
      errors.partner_id = "Partner is Required";
    }
    // if (serviceChecked.length < 1) {
    //   errors.checkbox = "Select at least one checkbox";
    // }
    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log('h2')
        const data = await httpRequest(
          "generatechallansendtopartner",
          formData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          setReload(true);
        } else {
          toast.error(data.message);
        }
      }
    })();
  }, [isSubmit, formErrors, setReload, formData]);

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="word-wrap">Part Requested Id</div>,
      selector: (row) => row.id,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Partner name</div>,
      selector: (row) => row.booking_detail.partner.display_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Booking ID</div>,
      selector: (row) => row.booking_detail.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
          {row.booking_detail.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Appliance</div>,
      selector: (row) => `${row.booking_detail.appliance.appliance_name}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Part Name / Number</div>,
      selector: (row) =>
        `${row.requested_part.part_name} / ${row.requested_part.part_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Model Number / Serial Number</div>,
      selector: (row) =>
        `${row.requested_model.model_number} / ${row.booking_detail.user_appliance_detail.sf_serial_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Quantity</div>,
      selector: (row) => `${row.shipped_quantity}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Warranty Status / Consumption</div>,
      selector: (row) =>
        `${getPartWarranty(row.warranty_status)} / ${
          Number(row.consumption_id) === 1 ? "Part Consumed" : "Part Not Consumed"
        }`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Received Date</div>,
      selector: (row) =>
        new Date(row.defective_received_ack_date_warehouse).toDateString(),
      wrap: true,
    },
    {
      name: (
        <div className="word-wrap">
          Part Received On Invoice / Challan from Partner
        </div>
      ),
      selector: (row) =>
        getInvoiceChallan(row)
    },
    {
      name: "Create",
      cell: (row) =>
        <button className="btn btn-primary" onClick={(e) => {
          generateChallan(e, row)
        }}>Create</button>
        ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  useEffect(() => {
    if (formData.partner_id) {
      setFormData({ partner_id: formData.partner_id, warehouse_id });
      setSelectedScAndPartner(null);
      setServiceChecked([]);
      setReload(true);
    }
  }, [formData.partner_id, setReload]);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Partner *<span style={{ color: "red" }}></span>
                    </label>
                    <Select
                      name="partner_id"
                      value={
                        partnerlist.find(
                          (option) => option.value === formData.partner_id
                        ) || null
                      }
                      options={partnerlist}
                      onChange={(val) => {
                        setFormData({
                          ...formData,
                          partner_id: val.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-md-4" style={{ marginTop: "14px" }}>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary"
                      disabled={serviceChecked.length > 0 ? false : true}
                      onClick={(e) => {
                        generateChallan(e);
                      }}
                    >
                      Generate Challan
                    </button>
                  </div>
                </div> */}
              </div>

              <MyDataTable
                reloadList={reloadList}
                columns={columns}
                getDataListURL={`getreversepartreceivedbywarehouse?warehouse_id=${warehouse_id}&partner_id=${formData.partner_id}`}
                setReload={setReload}
                exportName={"partlist"}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReceivedReverseParts;
