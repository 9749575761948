import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import httpRequest from "../../utils/Http";
import MainBody from "../../component/MainBody";
import {
  getdropdownList,
  getPartWarranty,
  isHsnCode,
} from "../../utils/HelperFunction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { UploadFileToS3 } from "../../utils/S3File";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SingleDispatchParts = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [modelNumberList, setModelNumberList] = useState([]);
  const [suggestionText, setSuggestionText] = useState("");
  const [partTypelist, setPartTypelist] = useState([]);
  const [partNameList, setPartNameList] = useState([]);
  const [partWarrantyList, setpartWarrantyList] = useState([]);
  const [quantitylist, setQuantityList] = useState([]);
  const [courierNamelist, setCourierNameList] = useState([]);
  const [partnerGstlist, setPartnerGstlist] = useState([]);
  const [warehouseGstlist, setWarehouseGstlist] = useState([]);
  const [courierDate, setCourierDate] = useState();
  const [challanDate, setChallanDate] = useState();
  const [isSubmit, setSubmit] = useState(false);

  useEffect(() => {
    (async () => {
      getdropdownList(setCourierNameList, `getcouriernamelist?is_active=1`);
      getdropdownList(
        setPartnerGstlist,
        `getpartnerwhgstdropdown?partner_id=${loggedInUser.entity_id}`
      );
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Number(formData.is_invoice) === 1) {
      (async () => {
        getdropdownList(setWarehouseGstlist, `getcwhdropdownlist`);
      })();
    }
    //eslint-disable-next-line
  }, [formData.is_invoice]);

  useEffect(() => {
    if (id) {
      const requested_id = atob(id);
      if (requested_id) {
        (async () => {
          const parts = await httpRequest(
            `getsparedetailslist?id=${requested_id}`,
            {},
            "GET"
          );
          //console.log(partners);
          if (parts.status === true && parts.data) {
            const data = parts.data["rows"][0];
            setFormData({
              id: data.id,
              bid: data.bid,
              booking_id: data.booking_detail.booking_id,
              sc_id: data.sc_id,
              partner_id: data.booking_detail.partner_id,
              req_part_type: data.requested_part.part_type,
              part_type: data.requested_part.part_type,
              req_part_name: data.requested_part.part_name,
              part_name: data.requested_part.part_name,
              req_part_number: data.requested_part.part_number,
              part_number: data.requested_part.part_number,
              warranty_status: data.warranty_status,
              model_number: data.requested_model.model_number,
              model_id: data.requested_model_id,
              req_model_number: data.requested_model.model_number,
              req_model_id: data.requested_model_id,
              appliance_id: data.booking_detail.appliance_id,
              appliance_name: data.booking_detail.appliance.appliance_name,
              requested_quantity: data.requested_quantity,
              customer_name: data.booking_detail.user_detail.customer_name,
              customer_mobile_number:
                data.booking_detail.booking_primary_mobile_number,
              serial_number:
                data.booking_detail.user_appliance_detail.serial_number,
              problem_description: data.problem_description,
              part_id: data.requested_part_id,
              quantity: data.requested_quantity,
              part_warranty: data.warranty_status,
              is_invoice: data.warranty_status == 2 ? 1 : 2,
            });

            console.log(formData);
          }
        })();
      }
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      //console.log('.......hi.......')
      if (formData.partner_id && formData.appliance_id) {
        //   console.log('IN')
        if (suggestionText.length > 3) {
          //     console.log('IN1')
          const listdata = await httpRequest(
            `getmodellist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&search=${suggestionText}`,
            {},
            "GET"
          );
          if (listdata.status === true && listdata.data) {
            setModelNumberList(listdata.data["rows"]);
          } else {
            setModelNumberList([]);
            const { model_id, ...newFormData } = formData;
            setFormData(newFormData);
          }
        } else if (!suggestionText) {
          const { model_number, model_id, ...newFormData } = formData;
          setFormData(newFormData);
        }
      } else if (suggestionText) {
        setFormErrors({ model_number: "Please select appliance first" });
        const { model_number, model_id, ...newFormData } = formData;
        setFormData(newFormData);
      }
    })();
    //eslint-disable-next-line
  }, [suggestionText]);

  const suggestionHandler = (model) => {
    // console.log(model);
    setFormData({
      ...formData,
      model_number: model.model_number,
      model_id: model.id,
    });
    setModelNumberList([]);
  };

  useEffect(() => {
    (async () => {
      if (formData.bid) {
        const booking_charge = await httpRequest(
          `getbookingcharge?bid=${formData.bid}`,
          {},
          "GET"
        );

        if (booking_charge.status === true && booking_charge.data) {
          const parse = JSON.parse(
            booking_charge.data[0].call_type.allowed_warranty_status
          );
          setpartWarrantyList(parse);
        }
      }
    })();
  }, [formData.bid]);

  useEffect(() => {
    (async () => {
      if (formData.appliance_id && formData.partner_id && formData.model_id) {
        const partTypelist = await httpRequest(
          `getbomparttypelist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&model_id=${formData.model_id}`,
          {},
          "GET"
        );

        if (partTypelist.status === true && partTypelist.data) {
          setPartTypelist(partTypelist.data);
        }
      }
    })();
  }, [formData.partner_id, formData.model_id, formData.appliance_id]);

  useEffect(() => {
    (async () => {
      if (
        formData.appliance_id &&
        formData.partner_id &&
        formData.model_id &&
        formData.part_type
      ) {
        const partNameList = await httpRequest(
          `getbompartnamelist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&model_id=${formData.model_id}&part_type=${formData.part_type}`,
          {},
          "GET"
        );

        if (partNameList.status === true && partNameList.data) {
          setPartNameList(partNameList.data);
        }
      }
    })();
  }, [
    formData.partner_id,
    formData.model_id,
    formData.appliance_id,
    formData.part_type,
  ]);

  useEffect(() => {
    if (partNameList.length > 0) {
      const data =
        partNameList.find((option) => option.value === formData.part_id) ||
        null;

      if (data) {
        setFormData({
          ...formData,
          basic_rate: data.basic_rate,
          gst_rate: data.gst_rate,
          hsn_code: data.hsn_code,
        });
      }
    }
  }, [partNameList]);

  useEffect(() => {
    (async () => {
      if (formData.model_id && formData.part_id) {
        const partTypelist = await httpRequest(
          `getbommaxqauntity?model_id=${formData.model_id}&part_id=${formData.part_id}`,
          {},
          "GET"
        );

        if (partTypelist.status === true && partTypelist.data) {
          setQuantityList(partTypelist.data);
        }
      }
    })();
  }, [formData.model_id, formData.part_id]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setFormErrors(await validateForm(formData));
    setSubmit(true);
  };

  const validateForm = async (value) => {
    const errors = {};

    if (!value.model_number) {
      errors.challan_number = "Model Number is required";
    }
    if (!value.part_type) {
      errors.part_type = "Part Type is mandatory";
    }

    if (!value.part_id) {
      errors.part_id = "Part Name is mandatory";
    }

    if (!value.part_warranty) {
      errors.part_warranty = "Part Warranty is mandatory";
    }

    if (!value.quantity) {
      errors.quantity = "Quantity is mandatory";
    }

    if (!value.remarks) {
      errors.remarks = "Problem Description is mandatory";
    }

    if (!value.challan_number) {
      errors.challan_number = "Challan Number is required";
    } else {
      const checkchallanExit = await httpRequest(
        `checkchallanexist`,
        { challan_number: value.challan_number },
        "POST"
      );

      if (checkchallanExit.status === true) {
        errors.challan_number = "Challan Number already uploaded.";
      }
    }

    if (!value.basic_rate) {
      errors.basic_rate = "Basic Rate is required";
    } else {
      if (value.basic_rate < 1) {
        errors.basic_rate = "Basic Rate is invalid";
      }
    }
    if (!value.gst_rate) {
      errors.gst_rate = "Gst Rate is required";
    } else {
      if (value.gst_rate != 18 && value.gst_rate != 28) {
        errors.gst_rate = "Gst Rate is invalid";
      }
    }
    if (!value.hsn_code) {
      errors.hsn_code = "Hsn Rate is required";
    } else {
      if (!isHsnCode(value.hsn_code)) {
        errors.hsn_code = "Hsn Rate is invalid";
      }
    }

    if (!value.challan_date) {
      errors.challan_date = "Challan Date is required";
    }

    if (!value.courier_name) {
      errors.courier_name = "Courier Name is required";
    }

    if (!value.awb_number) {
      errors.awb_number = "AWb number is required";
    }

    if (!value.courier_price) {
      errors.courier_price = "Courier Price is required";
    }

    if (!value.courier_date) {
      errors.courier_date = "Courier Date is required";
    }
    if (!value.remarks) {
      errors.remarks = "Remarks is required";
    }

    if (!value.challan_file) {
      errors.challan_file = "Challan file is required";
    }
    if (!value.from_gst_id) {
      errors.from_gst_id = "Dispatch Gst Number is required";
    }

    if (Number(value.is_invoice) === 1 && !value.warehouse_id) {
      errors.warehouse_id = "Bill to Gst Number is required";
    }

    return errors;
  };

  useEffect(() => {
    if(formData.awb_number && formData.courier_name){
      (async () => {
        setFormData({...formData, courierRead: false})
        const courier = await httpRequest(
          `checkcourierexist`,
          {awb_number: formData.awb_number, courier_name: formData.courier_name},
          "POST"
        );

        if (courier.status === true && courier.data) {
          setFormData({
            ...formData,
            courier_price: courier.data.courier_price,
            courier_date: courier.data.shippment_date,
            courierRead: true
          })
          setCourierDate(courier.data.shippment_date)
        } else if(courier.status === false){
          toast.error(courier.message);
          setFormData({
            ...formData,
            courier_name: null,
            awb_number: ''
          })
        }
      })()
    }

  }, [formData.courier_name, formData.awb_number])

  useEffect(() => {
    (async () => {
      console.log(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const postData = { ...formData };
        if (formData.courier_file) {
          const courier_file = await UploadFileToS3(
            "docFile",
            formData.courier_file
          );
          postData.courier_file = courier_file;
        }

        if (formData.challan_file) {
          const challan_file = await UploadFileToS3(
            "docFile",
            formData.challan_file
          );
          postData.challan_file = challan_file;
        }
        const data = await httpRequest(
          "dispatchforwardshippmentfrompartner",
          postData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          setFormData({});
          navigate("/spareparts");
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Dispatch Parts"
        breadcrumbSubTitle={formData.booking_id}
        navButton=""
        title={""}
        component={
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">
                        Part Ordered Details / {formData.booking_id}
                      </h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Customer Name </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="appliance_name"
                                className="form-control"
                                value={formData.customer_name}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Customer Mobile </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="mobile_number"
                                className="form-control"
                                value={formData.customer_mobile_number}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Appliance </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="warranty_status"
                                className="form-control"
                                value={formData.appliance_name}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Model Number </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="warranty_status"
                                className="form-control"
                                value={formData.req_model_number}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Serial Number </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="serial_number"
                                className="form-control"
                                value={formData.serial_number}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Part Type * </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="part_type"
                                className="form-control"
                                value={formData.req_part_type}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Part Name </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="part_name"
                                className="form-control"
                                value={formData.req_part_name}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Part Number </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="part_name"
                                className="form-control"
                                value={formData.req_part_number}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Requested Quantity </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="part_name"
                                className="form-control"
                                value={formData.requested_quantity}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Warranty Status </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="warranty_status"
                                className="form-control"
                                value={getPartWarranty(
                                  formData.warranty_status
                                )}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Problem Description </label>
                            <div className="select2-purple">
                              <input
                                type="text"
                                name="warranty_status"
                                className="form-control"
                                value={formData.problem_description}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form id="addbasicdetails" onSubmit={onSubmitHandler}>
                    <div className="card card-default">
                      <div className="card-header">
                        <h3 className="card-title">Dispatch Parts</h3>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Shipped Model Number *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.model_number}
                                </span>
                              </label>
                              <div className="input-container">
                                <input
                                  type="text"
                                  value={formData.model_number}
                                  className={
                                    !formErrors.model_number
                                      ? "form-control form-icon-input "
                                      : "is-invalid form-icon-input form-control "
                                  }
                                  name="model_number"
                                  placeholder="Enter Model Number"
                                  id="model_number"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      model_number: e.target.value,
                                      model_id: null,
                                    });

                                    setSuggestionText(e.target.value);
                                    if (!e.target.value) {
                                      setModelNumberList([]);
                                    }
                                  }}
                                  onBlur={(e) => {
                                    setTimeout(() => {
                                      setModelNumberList([]);
                                      //getCategoryList();
                                    }, 100);
                                  }}
                                />
                                {modelNumberList &&
                                  modelNumberList.length > 0 && (
                                    <div className="suggestion-dialog scrollbar-overlay">
                                      {modelNumberList &&
                                        modelNumberList.map((suggestion, i) => {
                                          return (
                                            <div
                                              key={i}
                                              className="col-md-12 justify-content-md-center suggestion-model"
                                              onClick={() => {
                                                suggestionHandler(suggestion);
                                              }}
                                            >
                                              {suggestion.model_number}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  )}
                              </div>
                            </div>
                            {/* /.form-group */}
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Part Type *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.part_type}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <Select
                                  value={
                                    partTypelist.find(
                                      (option) =>
                                        option.value === formData.part_type
                                    ) || null
                                  }
                                  name="part_type"
                                  placeholder="Select Part Type"
                                  options={partTypelist}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      part_type: val.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Part Name *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.part_id}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <Select
                                  value={
                                    partNameList.find(
                                      (option) =>
                                        option.value === formData.part_id
                                    ) || null
                                  }
                                  name="part_id"
                                  placeholder="Select Part Name"
                                  options={partNameList}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      part_id: val.value,
                                      basic_rate: val.basic_rate,
                                      gst_rate: val.gst_rate,
                                      part_name: val.part_name,
                                      part_number: val.part_number,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Part Warranty *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.part_warranty}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <Select
                                  value={
                                    partWarrantyList.find(
                                      (option) =>
                                        option.value === formData.part_warranty
                                    ) || null
                                  }
                                  name="part_warranty"
                                  placeholder="Select Part Warranty"
                                  options={partWarrantyList}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      part_warranty: val.value,
                                      is_invoice: val.value == 2 ? 1 : 2,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Quantity *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.quantity}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <Select
                                  value={
                                    quantitylist.find(
                                      (option) =>
                                        option.value === formData.quantity
                                    ) || null
                                  }
                                  name="quantity"
                                  placeholder="Select Quantity"
                                  options={quantitylist}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      quantity: val.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Baisc Rate *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.basic_rate}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <input
                                  type="number"
                                  step={".2"}
                                  min={0}
                                  placeholder="Enter Baisc Rate"
                                  className="form-control"
                                  value={formData.basic_rate}
                                  readOnly={
                                    formData.part_warranty == 2 ? true : false
                                  }
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      basic_rate: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Gst Rate *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.gst_rate}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <input
                                  type="number"
                                  step={".2"}
                                  placeholder="Enter GST Rate"
                                  min={0}
                                  className="form-control"
                                  value={formData.gst_rate}
                                  readOnly={
                                    formData.part_warranty == 2 ? true : false
                                  }
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      gst_rate: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                HSN Code *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.hsn_code}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  placeholder="Enter Hsn Code"
                                  min={0}
                                  className="form-control"
                                  value={formData.hsn_code}
                                  readOnly={
                                    formData.part_warranty == 2 ? true : false
                                  }
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      hsn_code: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Remarks *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.quantity}
                                </span>
                              </label>
                              <div className="select2-purple">
                                <input
                                  type="text"
                                  placeholder="Enter Remarks"
                                  className="form-control"
                                  value={formData.remarks}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      remarks: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-default">
                      <div className="card-header">
                        <h3 className="card-title">
                          {Number(formData.is_invoice) === 1
                            ? "Invoice"
                            : "Challan"}
                        </h3>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                {Number(formData.is_invoice) === 1
                                  ? "Invoice"
                                  : "Challan"}{" "}
                                Number *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.challan_number}
                                </span>
                              </label>
                              <input
                                type="text"
                                name="challan_number"
                                value={formData.challan_number}
                                className="form-control"
                                placeholder="Enter Challan Number"
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    challan_number: val.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                {Number(formData.is_invoice) === 1
                                  ? "Invoice"
                                  : "Challan"}{" "}
                                Date *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.challan_date}
                                </span>
                              </label>
                              <DatePicker
                                className="form-control text-capitalize"
                                minDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() - 7)
                                  )
                                }
                                dateFormat="dd/MM/yyyy"
                                selected={challanDate}
                                placeholderText="Select Challan Date"
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    challan_date: date,
                                  });
                                  setChallanDate(date);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                {Number(formData.is_invoice) === 1
                                  ? "Invoice"
                                  : "Challan"}{" "}
                                file *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.challan_file}
                                </span>
                              </label>
                              <input
                                type="file"
                                name="challan_file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    challan_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Dispatch Gst Number *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.from_gst_id}
                                </span>
                              </label>
                              <Select
                                name="from_gst_id"
                                value={
                                  partnerGstlist.find(
                                    (option) =>
                                      option.value === formData.from_gst_id
                                  ) || null
                                }
                                options={partnerGstlist}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    from_gst_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {Number(formData.is_invoice) === 1 && (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  Bill to Gst Number *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.warehouse_id}
                                  </span>
                                </label>
                                <Select
                                  name="warehouse_id"
                                  value={
                                    warehouseGstlist.find(
                                      (option) =>
                                        option.value === formData.warehouse_id
                                    ) || null
                                  }
                                  options={warehouseGstlist}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      warehouse_id: val.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="card card-default">
                      <div className="card-header">
                        <h3 className="card-title">Docket</h3>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_name}
                                </span>
                              </label>
                              <Select
                                name="courier_name"
                                value={
                                  courierNamelist.find(
                                    (option) =>
                                      option.label === formData.courier_name
                                  ) || null
                                }
                                options={courierNamelist}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    courier_name: val.label,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                AWB Number *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.awb_number}
                                </span>
                              </label>
                              <input
                                type="text"
                                name="awb_number"
                                value={formData.awb_number}
                                className="form-control"
                                placeholder="Enter AWB Number"
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    awb_number: val.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier Price *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_price}
                                </span>
                              </label>
                              <input
                                type="number"
                                min={0}
                                value={formData.courier_price}
                                name="courier_name"
                                className="form-control"
                                readOnly={formData.courierRead === true ? true: false}
                                placeholder="Enter Docket NUmber"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    courier_price: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier Date *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_date}
                                </span>
                              </label>
                              <DatePicker
                                className="form-control text-capitalize"
                                minDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() - 7)
                                  )
                                }
                                maxDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate())
                                  )
                                }
                                dateFormat="dd/MM/yyyy"
                                readOnly={formData.courierRead === true ? true: false}
                                selected={courierDate}
                                placeholderText="Select Courier Date"
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    courier_date: date,
                                  });
                                  setCourierDate(date);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier invoice file{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_file}
                                </span>
                              </label>
                              <input
                                type="file"
                                name="courier_file"
                                className="form-control"
                                readOnly={formData.courierRead === true ? true: false}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    courier_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer text-center"
                        style={{
                          marginBottom: "60px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-primary  col-md-12"
                          style={{ marginLeft: "0px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        }
      />
    </div>
  );
};

export default SingleDispatchParts;
