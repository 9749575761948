import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MyDataTable from "../../component/MyDataTable";
import { getDateDiff, getPartWarranty } from "../../utils/HelperFunction";
import WarehouseReverse from "./WarehouseReverse";

const DefectiveChallanGenerate = ({setReverseReload}) => {
  const [reloadList, setReload] = useState(true);
  const [editRow, setEditRow] = useState({});
  const [isOpenForm, setOpenForm] = useState(false);


  const closeForm = () => {
    setOpenForm(false);
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="word-wrap">Part Requested ID</div>,
      selector: (row) => row.id,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Booking ID</div>,
      selector: (row) => row.booking_detail.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
          {row.booking_detail.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Customer Name</div>,
      selector: (row) => row.booking_detail.user_detail.customer_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Appliance / Brand</div>,
      selector: (row) =>
        `${row.booking_detail.appliance.appliance_name} / ${row.booking_detail.user_appliance_detail.brand.brand_name}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Requested Part Name / Number</div>,
      selector: (row) =>
        `${row.requested_part.part_name} /  ${row.requested_part.part_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Shipped Part Name/ Number</div>,
      selector: (row) =>
        `${row.shipped_part.part_name} /  ${row.shipped_part.part_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Requested / Shipped Model Number</div>,
      selector: (row) =>
        `${row.requested_model.model_number} / ${row.shipped_model.model_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Serial Number</div>,
      selector: (row) =>
        row.booking_detail.user_appliance_detail.sf_serial_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Consumption</div>,
      selector: (row) =>
        Number(row.consumption_id) == 1 ? "Part Consumed" : "Part Not Consumed",
      wrap: true,
    },
    {
      name: <div className="word-wrap">Warranty Status</div>,
      selector: (row) => getPartWarranty(row.warranty_status),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Quantity</div>,
      selector: (row) => row.shipped_quantity,
      wrap: true,
    },
    {
      name: "Age",
      selector: (row) =>
        `${getDateDiff(row.booking_detail.sc_closed_date)} days`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Create Challan</div>,
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={(e) => {
            setEditRow(row);
            setOpenForm(true);
          }}
        >
          Create
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">
                    Reverse Challan To be Generated
                  </h3>
                </div>
                <MyDataTable
                  reloadList={reloadList}
                  columns={columns}
                  getDataListURL={`getdefectivetobeshppementlist?isdefective=1`}
                  setReload={setReload}
                  exportName={"partordered"}
                />
              </div>
            </div>
          </div>
        </div>
        {isOpenForm && (
          <WarehouseReverse
            closeForm={closeForm}
            setReload={setReload}
            partner_id={editRow.booking_detail.partner_id}
            state_id={editRow.booking_detail.booking_state}
            spare_id={editRow.id}
            bid={editRow.bid}
            serial_number={
              editRow.booking_detail.user_appliance_detail.sf_serial_number
            }
            part_name={editRow.requested_part.part_name}
            part_number={editRow.requested_part.part_number}
            model_number={editRow.requested_model.model_number}
            booking_id={editRow.booking_detail.booking_id}
            setEditRow={setEditRow}
            setReverseReload={setReverseReload}
          />
        )}
      </section>
    </div>
  );
};

export default DefectiveChallanGenerate;
