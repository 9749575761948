import React from 'react'
import MyDataTable from '../../../../component/MyDataTable';
import { NavLink } from 'react-router-dom';

const EstimatePending = ({reloadList, setReload}) => {
    const columns = [
        {
          name: "SNo.",
          selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
          cell: (row, index) => index + 1, // Use cell to render the incrementing number
        },
        {
          name: "Booking ID",
          selector: (row) => row.booking_detail.booking_id,
          cell: (row) => (
            <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
              {row.booking_detail.booking_id}
            </NavLink>
          ),
          wrap: true,
        },
        {
          name: "Customer Name",
          selector: (row) => row.booking_detail.user_detail.customer_name,
          wrap: true,
        },
        {
          name: "Mobile Number",
          selector: (row) => row.booking_detail.booking_primary_mobile_number,
          wrap: true,
        },
        {
          name: "Part Name",
          selector: (row) => row.requested_part.part_name,
          wrap: true,
        },
        {
          name: "Part Number",
          selector: (row) => row.requested_part.part_number,
          wrap: true,
        },
        {
          name: "Estimate Cost",
          selector: (row) => "Rs." + row.estimate_price,
          wrap: true,
        },
        {
          name: "Estimate Given Date",
          selector: (row) => new Date(row.estimate_date).toDateString(),
          wrap: true,
        },
      ].filter(Boolean);
  return (
    <div>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Estimate on Approval</h3>
              </div>
              <MyDataTable
                reloadList={reloadList}
                columns={columns}
                getDataListURL={`getsparedetailslist?estimategiven=1`}
                setReload={setReload}
                exportName={"sparelist"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default EstimatePending