export const CALL_EDIT_ACTION_PERMISSION = [1, 2, 3, 4,7,8, 10,13,14];
export const RESCHEDULE_ACTION_PERMISSION = [1, 2, 7, 8, 10, 16, 17];
export const COMPLETION_ACTION_PERMISSION = [1, 2, 10, 16, 17];
export const CANCELLATION_ACTION_PERMISSION = [1, 2, 10, 16, 17];
export const SF_ACTION_PERMISSION = [16, 17]
export const UPDATE_PART_MASTER_ACTION_PERMISSION = [1, 5, 6, 15]
export const FINAL_ClOSURE = [1,2]
export const CALL_ASSIGN_ACTION_PERMISSION = [1, 3, 4]
export const ONLY_RM_ASM_ACTION_PERMISSION = [1, 3, 4]
export const ONLY_IM_ACTION_PERMISSION = [1, 5, 6]
export const ACCOUNTS_INVOICE_ONLY = [1, 9]