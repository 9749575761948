import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import httpRequest from "../../../../utils/Http";
import { getdropdownList } from "../../../../utils/HelperFunction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainBody from "../../../../component/MainBody";

const ChargesList = () => {
  const [opAppiance, setOpAppliance] = useState([]);
  const [appliaceValue, setApplianceValue] = useState(null);
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [opBrand, setOpBrand] = useState([]);
  const [brandValue, setBrandValue] = useState(null);
  const [opCategory, setOpCategory] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [opCapacity, setOpCapacity] = useState([]);
  const [capacityValue, setCapacityValue] = useState(null);
  const [opCalltype, setOpCallType] = useState([]);
  const [calltypeValue, setCallTypeValue] = useState(null);
  const [tuploadedData, setUploadedData] = useState([]);
  const [partnerlist, setPartnerlist] = useState([]);
  const [partnerId, setPartnerId] = useState([]);
  const violationRef = useRef(null);
  const [ischecked, setChecked] = useState(false);
  const [checkboxinfo, setCheckboxInfo] = useState({
    id: [],
  });

  const goToViolation = () => {
    violationRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    (async () => {
      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );
      console.log(partners);
      if (partners.status === true && partners.data) {
        setPartnerlist(partners.data["rows"]);
      }
    })();
    //eslint-disable-next-line
  }, []);

  const getApplianceList = (partnerId) => {
    setApplianceValue(null);
    setBrandValue(null);
    setCategoryValue(null);
    setCapacityValue(null);
    setCallTypeValue(null);

    getdropdownList(
      setOpAppliance,
      `appliancelistmapping?is_active=1&partner_id=${partnerId}`
    );
  };

  const getBrandlList = async (applianceID) => {
    setBrandValue(null);
    setCategoryValue(null);
    setCapacityValue(null);
    setCallTypeValue(null);

    getdropdownList(
      setOpBrand,
      `getbrandlistmapping?partner_id=${partnerId}&appliance_id=${applianceID}`
    );

    getdropdownList(
      setOpCallType,
      "getcalltypelist" + "?isactive=true&appliance_id=" + applianceID
    );
  };

  const getCategoryList = async (brand) => {
    if (brand) {
      const listdata1 = await httpRequest(
        "getcategorylistmapping",
        {
          appliance_id: formData.appliance_id,
          partner_id: formData.partner_id,
          brand,
        },
        "POST"
      );
      if (listdata1.status === true && listdata1.data) {
        setCategoryValue(null);
        setCapacityValue(null);
        setOpCategory(listdata1.data);
      }
    }
  };

  const getCapacityList = async (category) => {
    if (category) {
      const listdata1 = await httpRequest(
        "getcapacitylistmapping",
        {
          appliance_id: formData.appliance_id,
          partner_id: formData.partner_id,
          brand: formData.brand,
          category,
        },
        "POST"
      );
      if (listdata1.status === true && listdata1.data) {
        setCapacityValue(null);
        setOpCapacity(listdata1.data);
      }
    }
  };

  const validateFrom = (value) => {
    const errors = {};
    if (!value.appliance_id) {
      errors.appliance_id = "Appliance is required";
    }
    if (!value.brand) {
      errors.brand = "Brand is required";
    }
    if (!value.category) {
      errors.category = "Category is required";
    }

    console.log("Errors ", errors);

    return errors;
  };

  useEffect(() => {
    (async () => {
      
      //console.log(JSON.stringify(formData));
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest("filterchargeslist", formData, "POST");
        setSubmit(false);
        if (data.status === true && data.data) {
          if (data.data.length > 0) {
            setUploadedData(data.data);

            unselectAllCheckboxt();

            setTimeout(function () {
              setSubmit(false);
              goToViolation();
            }, 500);
          } else {
            unselectAllCheckboxt();
          }
        } else {
          toast.error(data.message);
          unselectAllCheckboxt();
          setUploadedData([]);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const unselectAllCheckboxt = () => {
    var clist = document.getElementsByClassName("allcheckbox");
    for (var i = 0; i < clist.length; ++i) {
      clist[i].checked = "";
    }
    setCheckboxInfo({
      id: [],
    });
  };

  const hanldeAllCheckbox = (e) => {
    var clist = document.getElementsByClassName("allcheckbox");
    if (e.target.checked) {
      for (var i = 0; i < clist.length; ++i) {
        clist[i].checked = "checked";
        checkboxinfo.id.push(clist[i].value);
      }
    } else {
      unselectAllCheckboxt();
    }
  };

  const deleteCharges = async (e) => {
    e.preventDefault();
    if (checkboxinfo.id.length > 0) {
      const data = await httpRequest("deletechargelist", checkboxinfo, "POST");

      if (data.status === true && data.message) {
        toast.success(data.success);
        setSubmit(true);
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Please select atleast one checkbox");
    }
    console.log(checkboxinfo);
    return false;
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const { id } = checkboxinfo;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setCheckboxInfo({
        id: [...id, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setCheckboxInfo({
        id: id.filter((e) => e !== value),
      });
      var clist = document.getElementById("checkAll");
      for (var i = 0; i < clist.length; ++i) {
        clist[i].checked = "";
      }
    }
  };

  return (
    <>
      <MainBody
        breadcrumbTitle="Call Charges"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            <form id="form" onSubmit={onSubmitHandler}>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    {/* left column */}
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Filter Charges</h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Partner *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.partner_id}
                                  </span>
                                </label>
                                <Select
                                  name="partner_id"
                                  options={partnerlist}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      partner_id: val.value,
                                    });
                                    setPartnerId(val.value);
                                    getApplianceList(val.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Appliance *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.appliance_id}
                                  </span>
                                </label>
                                <Select
                                  name="appliance_id"
                                  options={opAppiance}
                                  value={appliaceValue}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      appliance_id: val.value,
                                    });
                                    setApplianceValue(val);
                                    getBrandlList(val.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Brand *
                                  <span style={{ color: "red" }}>
                                    {formErrors.brand}
                                  </span>
                                </label>
                                <Select
                                  isMulti={true}
                                  name="brand"
                                  value={brandValue}
                                  options={opBrand}
                                  onChange={(val) => {
                                    setFormData({ ...formData, brand: val });
                                    setBrandValue(val);
                                    getCategoryList(val);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Category *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.category}
                                  </span>
                                </label>
                                <Select
                                  isMulti={true}
                                  name="category"
                                  value={categoryValue}
                                  options={opCategory}
                                  onChange={(val) => {
                                    setFormData({ ...formData, category: val });
                                    setCategoryValue(val);
                                    getCapacityList(val);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Capacity
                                  <span style={{ color: "red" }}>
                                    {formErrors.capacity}
                                  </span>
                                </label>
                                <Select
                                  isMulti={true}
                                  name="capacity"
                                  value={capacityValue}
                                  options={opCapacity}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      capacity: val.val,
                                    });
                                    setCapacityValue(val);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Call Type
                                  <span style={{ color: "red" }}>
                                    {formErrors.calltype}
                                  </span>
                                </label>
                                <Select
                                  name="calltype"
                                  value={calltypeValue}
                                  options={opCalltype}
                                  onChange={(val) => {
                                    setFormData({ ...formData, calltype: val.value });
                                    setCallTypeValue(val);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer text-center">
                          <button
                            type="submit"
                            id="submit"
                            className="btn btn-primary col-md-6"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </form>
            <section
              className={`content ${
                tuploadedData.length > 0 ? "" : "hide_display"
              }`}
              id="duplicate_order"
              ref={violationRef}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">Charges Already Uploaded</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <form id="chargesDelete" onSubmit={deleteCharges}>
                            <table className="table table-bordered  table-hover table-striped data dataTable no-footer ">
                              <thead>
                                <tr>
                                  <th>SNo.</th>
                                  <th>Appliance</th>
                                  <th>Brand</th>
                                  <th>Category</th>
                                  <th>Capacity</th>
                                  <th>Call Type</th>
                                  <th>Call Charge</th>
                                  <th>Customer Payable</th>
                                  <th>Sf Total Payout(With Tax)</th>
                                  <th>Sf GST Rate (%)</th>
                                  <th>Invoice POD</th>
                                  <th>Serial Number POD</th>
                                  <th>Upcountry</th>
                                  <th>Created By</th>
                                  <th>Create Date</th>
                                  <th>
                                    Select{" "}
                                    <input
                                      type="checkbox"
                                      id="checkAll"
                                      onClick={hanldeAllCheckbox}
                                    />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {tuploadedData.map((element, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{element.appliance_name}</td>
                                        <td>{element.brand}</td>
                                        <td>{element.category}</td>
                                        <td>{element.capacity}</td>
                                        <td>{element.call_type}</td>
                                        <td>{element.call_charges}</td>
                                        <td>{element.customer_payable}</td>
                                        <td>{element.sf_total_payout}</td>
                                        <td>{element.sf_gst_rate}</td>
                                        <td>
                                          {Number(element.invoice_pod) === 1
                                            ? "YES"
                                            : "NO"}
                                        </td>
                                        <td>
                                          {Number(element.serial_number_pod) === 1
                                            ? "YES"
                                            : "NO"}
                                        </td>
                                        <td>
                                          {Number(element.upcountry_paid_by) === 0
                                            ? "No"
                                            : Number(element.upcountry_paid_by) === 1 ? "Upcountry Free for Customer": "Upcountry Paid for Customer"}
                                        </td>
                                        <td>{element.agent_name}</td>
                                        <td>
                                          {new Date(
                                            element.createdAt
                                          ).toDateString()}
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            className="form-control allcheckbox"
                                            name="checkbox"
                                            onChange={handleCheckboxChange}
                                            value={element.id}
                                            id={"checkbox_" + element.id}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <input
                                      type="submit"
                                      className="btn btn-info"
                                      value="Delete"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
};

export default ChargesList;
