import React, {  useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { getPartWarranty } from "../../../../utils/HelperFunction";
import MyDataTable from "../../../../component/MyDataTable";
import httpRequest from "../../../../utils/Http";

const DefectivePartToBeSendToPartner = ({ setReload, reloadList }) => {
  const [formData, setFormData] = useState({ partner_id: null });


  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="word-wrap">Part Requested Id</div>,
      selector: (row) => row.id,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Partner name</div>,
      selector: (row) => row.booking_detail.partner.display_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Booking ID</div>,
      selector: (row) => row.booking_detail.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
          {row.booking_detail.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Appliance</div>,
      selector: (row) => `${row.booking_detail.appliance.appliance_name}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Part Name / Number</div>,
      selector: (row) =>
        `${row.requested_part.part_name} / ${row.requested_part.part_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Model Number / Serial Number</div>,
      selector: (row) =>
        `${row.requested_model.model_number} / ${row.booking_detail.user_appliance_detail.sf_serial_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Quantity</div>,
      selector: (row) => `${row.shipped_quantity}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Warranty Status / Consumption</div>,
      selector: (row) =>
        `${getPartWarranty(row.warranty_status)} / ${
          Number(row.consumption_id) === 1 ? "Part Consumed" : "Part Not Consumed"
        }`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Received Date</div>,
      selector: (row) =>
        new Date(row.defective_received_ack_date_warehouse).toDateString(),
      wrap: true,
    },
  ];

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">

              <MyDataTable
                reloadList={reloadList}
                columns={columns}
                getDataListURL={`getreversepartreceivedbywarehouse?partner_id=${formData.partner_id}`}
                setReload={setReload}
                exportName={"partlist"}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DefectivePartToBeSendToPartner;
