import React, { useState, useEffect } from "react";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import { getdropdownList, openSignUrl } from "../../../utils/HelperFunction";
import { Link } from "react-router-dom";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "../../../asset/css/excelcss.css";
import Select from "react-select";
import { toast } from "react-toastify";
import MyDataTable from "../../../component/MyDataTable";

const UploadPartMaster = () => {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [applianceops, setApplianceOps] = useState([]);
  const [partnerlist, setPartnerlist] = useState([]);
  const [rows, setRows] = useState(null);
  const [cols, setcols] = useState(null);
  const [isSubmit, setSubmit] = useState(false);
  const [reloadList, setReload] = useState(true);

  useEffect(() => {
    (async () => {
      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );
      //console.log(partners);
      if (partners.status === true && partners.data) {
        const { appliance_id, ...rest } = formData;
        setFormData(rest);
        setPartnerlist(partners.data["rows"]);
      }
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formData.partner_id) {
      (async () => {
        getdropdownList(
          setApplianceOps,
          `appliancelistmapping?is_active=1&partner_id=${formData.partner_id}`
        );
      })();
    }
  }, [formData.partner_id]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    //console.log(value);
    if (!value.partner_id) {
      errors.partner_id = "Partner is Required";
    }
    if (!value.appliance_id) {
      errors.appliance_id = "Appliance is Required";
    }

    if (!value.file) {
      errors.part_file = "Part File is Required (Only xlsx & xls file)";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        document.getElementById("uploadpart").reset();
        const data = await httpRequest(
          "processuploadpart",
          formData,
          "POST",
          "multipart/form-data"
        );

        if (data.status === true && data.message) {
          toast.success(data.message);
          setFormData({});
          setRows(null);
          setcols(null);
          setReload(true);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "File Name",
      selector: (row) => row.file_name,
      cell: (row) => (
        <Link
          to={"javascript:void(0)"}
          className="btn-link text-primary"
          onClick={() => openSignUrl(row.file_path)}
        >
          {row.file_name}
        </Link>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Response",
      selector: (row) => <div className="word-wrap">{row.response}</div>,
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
  ];

  return (
    <div>
      <MainBody
        breadcrumbTitle="Upload Part Master"
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <form
                  id="uploadpart"
                  onSubmit={onSubmitHandler}
                  encType="multipart/form-data"
                >
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Upload Part</h3>
                      <span className="float-right">
                        <Link
                          to={"javascript:void(0)"}
                          className="btn-link text-primary"
                          onClick={() =>
                            openSignUrl("samplefolder/partmaster.xlsx")
                          }
                        >
                          Download Sample File
                        </Link>
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Partner *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.partner_id}
                              </span>
                            </label>
                            <Select
                              name="partner_id"
                              value={
                                partnerlist.find(
                                  (option) =>
                                    option.value === formData.partner_id
                                ) || null
                              }
                              options={partnerlist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  partner_id: val.value,
                                  appliance_id: null,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group is-invalid ">
                            <label>
                              Appliance *
                              <span style={{ color: "red" }}>
                                {formErrors.appliance_id}
                              </span>
                            </label>
                            <Select
                              name="appliance_id"
                              options={applianceops}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  appliance_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>
                              Upload Part File *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_file}
                              </span>
                            </label>
                            <input
                              name="file"
                              type="file"
                              className="form-control"
                              accept=".xlsx, .xls"
                              onChange={(e) => {
                                var files = e.target.files;
                                if (files && files[0]) {
                                  var file_name = e.target.files[0]["name"];
                                  const regex = new RegExp("(.*?)(xlsx|xls)$");
                                  if (!regex.test(file_name.toLowerCase())) {
                                    setFormErrors({
                                      part_file:
                                        "Part File is Required (Only xlsx & xls file)",
                                    });
                                  } else {
                                    setRows(null);
                                    setcols(null);
                                    ExcelRenderer(
                                      e.target.files[0],
                                      (err, resp) => {
                                        //handle fail case here
                                        if (err) {
                                          // console.log(err);
                                        }
                                        //handle success case here
                                        else {
                                          //  console.log(resp.cols);
                                          setRows(resp.rows);
                                          setcols(resp.cols);
                                        }
                                      }
                                    );
                                    setFormData({
                                      ...formData,
                                      file: e.target.files[0],
                                    });
                                  }
                                } else {
                                  setFormErrors({
                                    model: "Only xlsx & xls file allow",
                                  });
                                }
                              }}
                            />
                          </div>

                          {/* /.form-group */}
                        </div>
                        {/* /.col */}
                      </div>
                      <div className="row">
                        <div className={rows && "col-md-12 restrict-card"}>
                          {rows && (
                            <OutTable
                              data={rows}
                              columns={cols}
                              tableClassName="table table-bordered table-striped dataTable dtr-inline"
                              tableHeaderRowClass="heading"
                            />
                          )}
                        </div>
                      </div>
                      <br />
                      <div className="card-footer">
                        <div className="row">
                          <input
                            type="submit"
                            className="form-control btn btn-primary"
                            value={"Submit"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">File Upload History</h3>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={
                          "getfileuploadhistory?type=PART-MASTER-MAPPING_EXCEL"
                        }
                        setReload={setReload}
                        exportName={"fileuploadlist"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
          </>
        }
      />
    </div>
  );
};

export default UploadPartMaster;
