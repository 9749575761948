import React, { useEffect, useState } from "react";
import MainBody from "../../../component/MainBody";
import CompanyBasicDetails from "./CompanyBasicDeatils"; // Corrected the import statement
import { Link, useParams } from "react-router-dom";
import httpRequest from "../../../utils/Http";
import {
  isValidGstNumber,
  isValidPanNumber,
  openSignUrl,
} from "../../../utils/HelperFunction";
import { UploadFileToS3 } from "../../../utils/S3File";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddWarehouseList from "./AddWarehouseList";
import PartnerLogins from "./PartnerLogins";
import PartnerUpcountry from "./PartnerUpcountry";
import { useSelector } from "react-redux";
import UpdateKam from "./UpdateKam";
import { PARTNER_TYPE } from "../../../constant/status_constant";
import { UploadAggrementCopy } from "../serviceCenter/UploadAggrementCopy";

const AddUpdatePartner = () => {
  const user = useSelector((state) => state.authReducer.currentUser);
  // const [reloadList, setReload] = useState(true);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState({});
  const { partnerId } = useParams();

  useEffect(() => {
    if (partnerId) {
      (async () => {
        const partners = await httpRequest(
          `getpartnerlist?partner_id=${partnerId}`,
          {},
          "GET"
        );
        console.log(partners.data["rows"]);
        if (
          partners.status === true &&
          partners.data &&
          partners.data["rows"].length > 0
        ) {
          const partner = partners.data["rows"][0];
          setPartnerDetails(partner);

          setFormData({
            partner_id: partner.partner_id,
            gst_number: partner.gst_details?.gst_number || "",
            pan_number: partner.pan_number || "",
            gst_id: partner.gst_details?.id || "",
          });
        }
      })();
    }
  }, [partnerId]);

  const validateFrom = (value) => {
    const errors = {};

    if (!value.pan_number) {
      errors.pan_number = "Pan Number is Required";
    } else {
      if (!value.pan_file && value.pan_number !== partnerDetails.pan_number) {
        errors.pan_file = "Pan File is Required (Only Image & PDF file)";
      }

      if (!isValidPanNumber(value.pan_number)) {
        errors.pan_number = "Pan Number is not valid";
      }
    }

    if (!value.gst_number) {
      errors.gst_number = "GST Number is Required";
    } else {
      if (
        !value.gst_file &&
        partnerDetails.gst_details?.gst_number &&
        value.gst_number !== partnerDetails.gst_details.gst_number
      ) {
        errors.gst_file = "GST File is Required (Only Image & PDF file)";
      }

      if (!isValidGstNumber(value.gst_number)) {
        errors.gst_number = "GST Number is not valid";
      }

      // let state = Number(value.gst_number.slice(0, 2));
      // if (state !== partnerDetails.state_id) {
      //   errors.gst_number = "Gst Number is not valid. State is not matching";
      // }

      // if (value.gst_number.slice(2, 12) !== value.pan_number) {
      //   errors.gst_number = "GST Number should be match with Pan Number.";
      // }
    }

    if (
      value.pan_number === partnerDetails.pan_number &&
      value.gst_number === (partnerDetails.gst_details?.gst_number || "") &&
      !value.pan_file &&
      !value.gst_file
    ) {
      errors.mandatory = "There is no change in the existing data.";
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let formData1 = {};
        formData1.partner_id = formData.partner_id;
        formData1.gst_id = formData.gst_id;
        if (formData.pan_number) {
          formData1.pan_number = formData.pan_number;
        }

        if (formData.pan_file) {
          const panfile = await UploadFileToS3("docFile", formData.pan_file);
          formData1.pan_file = panfile;
        } else {
          formData1.pan_file = partnerDetails.pan_file;
        }

        if (formData.gst_file) {
          const gstfile = await UploadFileToS3("docFile", formData.gst_file);
          formData1.gst_file = gstfile;
        }

        if (formData.gst_number) {
          formData1.gst_number = formData.gst_number;
          formData1.pincode = partnerDetails.pincode;
          formData1.city = partnerDetails.city;
          formData1.state_id = partnerDetails.state_id;

          if (!formData.gst_file) {
            formData1.gst_file = partnerDetails.gst_details?.gst_file || "";
          }
        }

        document.getElementById("cdocuments").reset();
        // console.log(formData1);
        const data = await httpRequest(
          "updatecompanydocuments",
          formData1,
          "POST"
        );
        if (data.status === true && data.message) {
          toast.success(data.message);

          delete formData.gst_file;
          delete formData.pan_file;

          setPartnerDetails(data.data);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [isSubmit, formErrors]);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Partners"
        breadcrumbSubTitle=""
        navButton=""
        title=""
        component={
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#basicdetails"
                            data-toggle="tab"
                          >
                            Basic Details
                          </a>
                        </li>
                        {partnerDetails?.partner_id && (
                          <>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="#documents"
                                data-toggle="tab"
                              >
                                Documents
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="#cwh"
                                data-toggle="tab"
                              >
                                {partnerDetails.is_default == 1
                                  ? "Central Warehouse"
                                  : "Warehouse"}
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="#upcountry"
                                data-toggle="tab"
                              >
                                Upcountry
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="#partnerlogins"
                                data-toggle="tab"
                              >
                                Logins
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="#kam"
                                data-toggle="tab"
                              >
                                KAM
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="#aggrementcp"
                                data-toggle="tab"
                              >
                                Aggrement Copy
                              </a>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">
                        <div className="active tab-pane" id="basicdetails">
                          <CompanyBasicDetails
                            partnerDetails={partnerDetails}
                            setPartnerDetails={setPartnerDetails}
                            role_id ={user.role_id}
                          />
                        </div>
                        {partnerDetails?.partner_id && (
                          <>
                            <div className="tab-pane" id="documents">
                              <form
                                className="form-horizontal"
                                id="cdocuments"
                                onSubmit={onSubmitHandler}
                                action="javascript:void(0)"
                              >
                                <div className="form-group row">
                                  <label
                                    htmlFor="pan_number"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Pan Number *{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.pan_number}
                                    </span>
                                  </label>
                                  <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="pan_number"
                                      placeholder="Enter Pan Number"
                                      defaultValue={formData.pan_number}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          pan_number: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor="pan_file"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Pan File *{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.pan_file}
                                    </span>
                                  </label>
                                  <div className="col-sm-8">
                                    <input
                                      type="file"
                                      className="form-control"
                                      id="pan_file"
                                      accept=".PDF, .pdf, .png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
                                      onChange={(e) => {
                                        var files = e.target.files;
                                        if (files && files[0]) {
                                          var file_name =
                                            e.target.files[0]["name"];
                                          const regex = new RegExp(
                                            "(.*?)(PDF|pdf|png|PNG|jpg|JPG|jpeg|JPEG)$"
                                          );
                                          if (
                                            !regex.test(file_name.toLowerCase())
                                          ) {
                                            setFormErrors({
                                              pan_file:
                                                "Pan File is Required (Only PDF & Image file)",
                                            });
                                          } else {
                                            setFormData({
                                              ...formData,
                                              pan_file: e.target.files[0],
                                            });
                                          }
                                        } else {
                                          setFormErrors({
                                            ...formErrors,
                                            pan_file:
                                              "Pan File is Required (Only PDF & Image) file allow",
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {partnerDetails.pan_file && (
                                    <Link
                                      to={"javascript:void(0)"}
                                      className="btn-link col-sm-2 text-primary"
                                      onClick={() =>
                                        openSignUrl(partnerDetails.pan_file)
                                      }
                                    >
                                      View
                                    </Link>
                                  )}
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor="gst_number"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Gst Number *{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.gst_number}
                                    </span>
                                  </label>
                                  <div className="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="gst_number"
                                      placeholder="Enter Gst Number"
                                      defaultValue={formData.gst_number}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          gst_number: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor="gst_file"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Gst File{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.gst_file}
                                    </span>
                                  </label>
                                  <div className="col-sm-8">
                                    <input
                                      type="file"
                                      className="form-control"
                                      id="gst_file"
                                      onChange={(e) => {
                                        var files = e.target.files;
                                        if (files && files[0]) {
                                          var file_name =
                                            e.target.files[0]["name"];
                                          const regex = new RegExp(
                                            "(.*?)(pdf|png|jpg|jpeg)$"
                                          );
                                          if (
                                            !regex.test(file_name.toLowerCase())
                                          ) {
                                            setFormErrors({
                                              gst_file:
                                                "GST File is Required (Only PDF & Image file)",
                                            });
                                          } else {
                                            setFormData({
                                              ...formData,
                                              gst_file: e.target.files[0],
                                            });
                                          }
                                        } else {
                                          setFormErrors({
                                            ...formErrors,
                                            gst_file:
                                              "GST File is Required (Only PDF & Image) file allow",
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {partnerDetails.gst_details?.gst_file && (
                                    <Link
                                      to={"javascript:void(0)"}
                                      className="btn-link col-sm-2 text-primary"
                                      onClick={() =>
                                        openSignUrl(
                                          partnerDetails.gst_details.gst_file
                                        )
                                      }
                                    >
                                      View
                                    </Link>
                                  )}
                                </div>
                                {user && user.role_id == 1 && (
                                  <div className="form-group row">
                                    <div className="offset-sm-2 col-sm-10">
                                      <span style={{ color: "red" }}>
                                        {formErrors.mandatory}
                                      </span>
                                      <br />
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </form>
                            </div>

                            <div className="tab-pane" id="cwh">
                              <AddWarehouseList partnerId={partnerId} role_id={user.role_id} />
                            </div>
                            <div className="tab-pane" id="upcountry">
                              <PartnerUpcountry
                                partnerDetails={partnerDetails}
                                setPartnerDetails={setPartnerDetails}
                                role_id={user.role_id}
                              />
                            </div>
                            <div className="tab-pane" id="partnerlogins">
                              <PartnerLogins partnerId={partnerId} 
                              role_id={user.role_id}
                              partnerDetails={partnerDetails}
                              />
                              
                            </div>
                            <div className="tab-pane" id="kam">
                              <UpdateKam partner_id={partnerId} 
                              role_id={user.role_id}
                              />
                              
                            </div>
                            <div className="tab-pane" id="aggrementcp">
                              <UploadAggrementCopy entity_id={partnerId} 
                              entity_type={PARTNER_TYPE}
                              />
                              
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      />
    </div>
  );
};

export default AddUpdatePartner;
