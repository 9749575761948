import React, { useState } from 'react'
import Navbar from './Navbar'
import Asidebar from './Asidebar'

const Header = () => {
  const [isDisplay, setDisplay] = useState(false)
  return (
    <div>
               <Asidebar isDisplay={isDisplay} setDisplay={setDisplay}/>
               <Navbar setDisplay={setDisplay} isDisplay={isDisplay}/>
    </div>
  )
}

export default Header