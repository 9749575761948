import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import httpRequest from "../../utils/Http";

const WarehouseReverse = ({ partner_id, spare_id, bid, serial_number,part_name, part_number,
  model_number, state_id, booking_id, setEditRow, setReload, closeForm, setReverseReload }) => {
  const [formData, setFormData] = useState({partner_id, spare_id, bid,serial_number, part_name,
    booking_id,  part_number, model_number, state_id})
  const [warehouselist, setWarehouselist] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  useEffect(() => {
    if (partner_id && state_id && spare_id) {
      setFormData((prev) => ({ ...prev, spare_id, partner_id, state_id, bid, 
        booking_id,
        serial_number, part_name, part_number, model_number
       }));
      (async () => {
        const scdata = await httpRequest(`getpartnercentralwarehouse?partner_id=${partner_id}&state_id=${state_id}`, {}, "GET");
        if (scdata.status === true && scdata.data) {
          setWarehouselist(scdata.data);
        } else {
          setFormData((prev) => ({ ...prev, warehouse_id: null }));
          setWarehouselist([]);
        }
      })();
    }
  }, [partner_id, state_id, spare_id]);

  const validateForm = (value) => {
    const errors = {};

    if (!value.warehouse_id) {
      errors.warehouse_id = "Warehouse is required";
    }
    if(!value.spare_id){
        errors.spare_id = "Mandatory field is required";
    }
    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };


  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest("generatedefectivechallan", formData, "POST");

        if (data.status === true && data.message) {
          toast.success(data.message);
          setReload(true);
          setReverseReload(true)
          setEditRow({})
          closeForm();
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [isSubmit, formErrors, formData, setReload, closeForm]);

  return (
    <>
      <div className="form-overlay">
        <div className="form-container" style={{ maxWidth: "500px" }}>
          <div className="form-content">
            <form id="assignPartner" onSubmit={onSubmitHandler}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" style={{ marginBottom: "0px" }}>
                    <div className="card-header">
                      <h3 className="card-title">Select Warehouse for Reverse</h3>
                      <button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={closeForm}
                        style={{ float: "right" }}
                      >
                        X
                      </button>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Warehouse List *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.warehouse_id}
                              </span>
                            </label>
                            <Select
                              name="warehouse_id"
                              placeholder="Select Warehouse"
                              value={warehouselist.find((option) => option.value === formData.warehouse_id) || null}
                              options={warehouselist}
                              onChange={(val) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  warehouse_id: val.value,
                                }));
                              }}
                            />
                          </div>
                        </div>
                       
                      </div>
                    </div>
                    <div
                      className="card-footer text-center"
                      style={{
                        marginBottom: "60px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <button type="submit" id="submit" className="btn btn-primary col-md-12" style={{margin: "0px"}}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseReverse;
