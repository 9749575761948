import { useEffect, useState } from "react";
import Select from "react-select";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CompanyBasicDetails = ({ partnerDetails, setPartnerDetails, role_id }) => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const getCompanyType = [
    { value: "Individual", label: "Individual" },
    { value: "Proprietorship Firm", label: "Proprietorship Firm" },
    { value: "Partnership Firm", label: "Partnership Firm" },
    { value: "Private Ltd Company", label: "Private Ltd Company" },
    { value: "Public Ltd Company", label: "Public Ltd Company" },
  ];

  const [isSubmit, setSubmit] = useState(false);
  const [stateOption, setStateOption] = useState([]);
  const [stateValue, setstateValue] = useState(null);
  const [cityOption, setCityOption] = useState([]);
  const [cityValue, setCityValue] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [ctypeValue, setCtypeValue] = useState({});

  useEffect(() => {
    if (partnerDetails && Object.keys(partnerDetails).length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        company_name: partnerDetails.company_name,
        partner_id: partnerDetails.partner_id,
        display_name: partnerDetails.display_name,
        company_type: partnerDetails.company_type,
        pincode: partnerDetails.pincode,
        state_id: partnerDetails.state_id,
        city: partnerDetails.city,
        company_address: partnerDetails.company_address,
      }));
      
      setCtypeValue(
        getCompanyType.find((x) => x.value === partnerDetails.company_type)
      );

      getAllCity(partnerDetails.pincode);
    }
  }, [partnerDetails]);

  const validateFrom = (value) => {
    const errors = {};
    //console.log(value);
    if (!value.company_name) {
      errors.company_name = "Company Name is Required";
    }

    if (!value.display_name) {
      errors.display_name = "Display Name is Required";
    }
    if (!value.company_type) {
      errors.company_type = "Company Type is Required";
    }
    // Check if pincode is provided
    if (!value.pincode) {
      errors.pincode = "Pincode is Required";
    } else {
      // Check if pincode is a 6-digit number
      const pincodePattern = /^[0-9]{6}$/;
      if (!pincodePattern.test(value.pincode)) {
        errors.pincode = "Pincode must be a 6-digit number";
      }
    }

    if (!value.state_id) {
      errors.state_id = "State is Required";
    }

    if (!value.city) {
      errors.city = "City is Required";
    }
    if (!value.company_address) {
      errors.company_address = "Company Address is Required";
    }

    // if (
    //   value.pan_number == partnerDetails.pan_number &&
    //   value.gst_number == partnerDetails.gst_details.gst_number &&
    //   !value.pan_file &&
    //   !value.gst_file
    // ) {
    //   errors.mandatory = "There is no change in the existing data.";
    // }
    //console.log(errors);
    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };
  const getAllCity = (pincode) => {
    if (pincode) {
      (async () => {
        try {
          setStateOption([]);
          setstateValue(null);
          setCityOption([]);
          setCityValue(null);
  
          const listdata = await httpRequest(
            "getallcity?pincode=" + pincode,
            {},
            "GET"
          );
  
          console.log("API Response:", listdata); // Log API response
  
          if (listdata.status === true && listdata.message) {
            const state = listdata.message[0]?.state;
            if (state) {
              console.log("State ID:", state.id);
  
              setStateOption([state]);
              setFormData((prevFormData) => ({
                ...prevFormData,
                state_id: state.id,
                pincode,
              }));
              setstateValue(state);
              setCityOption(listdata.message);
  
              const selectedValue = listdata.message.find(
                (x) => x.value === partnerDetails.city
              );
              setCityValue(selectedValue);
              console.log("Selected City:", selectedValue);
            }
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      })();
    }
  };
  

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest(
          "addupdatecompanybasicdetails",
          formData,
          "POST"
        );
        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          document.getElementById("basicDetailsform").reset();

          if (!formData.partner_id) {
            navigate(`/updatepartner/${data.data.partner_id}`);
          }

          setPartnerDetails(data.data);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <>
      <form
        className="form-horizontal"
        id="basicDetailsform"
        onSubmit={onSubmitHandler}
      >
        <div className="form-group row">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            Company Name *{" "}
            <span style={{ color: "red" }}>{formErrors.company_name}</span>
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              id="company_name"
              placeholder="Enter Company Name"
              name="company_name"
              defaultValue={partnerDetails.company_name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  company_name: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            Display Name *{" "}
            <span style={{ color: "red" }}>{formErrors.display_name}</span>
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              id="display_name"
              name="display_name"
              placeholder="Enter Display Name"
              defaultValue={partnerDetails.display_name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  display_name: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            Compnay Type *{" "}
            <span style={{ color: "red" }}>{formErrors.company_type}</span>
          </label>
          <div className="col-sm-8">
            <Select
              name="company_type"
              value={ctypeValue}
              options={getCompanyType}
              placeholder="Select Company Type"
              onChange={(val) => {
                setFormData({ ...formData, company_type: val.value });
                setCtypeValue(val);
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            pincode * <span style={{ color: "red" }}>{formErrors.pincode}</span>
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              id="pincode"
              name="pincode"
              className="form-control"
              placeholder="Select Pincode"
              defaultValue={partnerDetails.pincode}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  pincode: e.target.value,
                });

                getAllCity(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            State *{""}
            <span style={{ color: "red" }}>{formErrors.state_id}</span>
          </label>
          <div className="col-sm-8">
            {formData.pincode && stateValue === null && "Loading..."}
            <Select
              name="state"
              value={stateValue}
              options={stateOption}
              placeholder="Select State"
              onChange={(val) => {
                setFormData({ ...formData, state_id: val.value });
                setstateValue(val);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            City *{""}
            <span style={{ color: "red" }}>{formErrors.city}</span>
          </label>
          <div className="col-sm-8">
            {formData.pincode && cityValue === null && "Loading..."}
            <Select
              name="city"
              placeholder="Select City"
              value={cityValue}
              options={cityOption}
              onChange={(val) => {
                setFormData({ ...formData, city: val.value });
                setCityValue(val);
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="address" className="col-sm-2 col-form-label">
            Address *{" "}
            <span style={{ color: "red" }}>{formErrors.company_address}</span>
          </label>
          <div className="col-sm-8">
            <textarea
              className="form-control"
              id="company_address"
              placeholder="Enter Company Address"
              name="company_address"
              defaultValue={partnerDetails.company_address}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  company_address: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </div>
        {role_id == 1 && (
          <div className="form-group row">
            <div className="offset-sm-2 col-sm-10">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default CompanyBasicDetails;
