import React from "react";
import Header from "../header/servicecenter/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/admin/Dashboard";
import Footer from "../header/Footer";
import PendingList from "../pages/admin/booking/PendingList";
import BookingDetails from "../pages/admin/booking/BookingDetails";
import DATA_NOT_FOUND from "../pages/404";
import AdvanceSearch from "../pages/admin/booking/AdvanceSearch";
import BookingReschedule from "../pages/admin/booking/BookingReschedule";
import BookingCancel from "../pages/admin/booking/BookingCancel";
import UpdateBooking from "../pages/serviceCenter/UpdateBooking";
import PartOrderRequest from "../pages/serviceCenter/PartOrderRequest";
import SpareActions from "../pages/serviceCenter/SpareActions";
import CompleteBooking from "../pages/admin/booking/CompleteBooking";
import CompletedBookingList from "../pages/admin/booking/CompletedBookingList";
import CancelledBookingList from "../pages/admin/booking/CancelledBookingList";
import BookingSummary from "../pages/admin/reports/BookingSummary";
import SpareSummary from "../pages/admin/reports/SpareSummary";
import CustomerOOWInvoice from "../pages/admin/invoice/CustomerOOWInvoice";
import InvoiceSummary from "../pages/admin/invoice/InvoiceSummary";
import ResetPassword from "../pages/ResetPassword";


const ScRoutes = ({isLoggedIn}) => {

  const createRoutes = () => (
    <>
      <Route excat path="/dashboard" element={<Dashboard />} />
      <Route excat path="/pendingbooking" element={<PendingList/>} />
      <Route excat path="/getbookingdetails" element={<BookingDetails />} />
      <Route excat path="/updatebooking/:bid/:isPartOrder" element={<UpdateBooking />} />
      <Route excat path="/requestpartorder/:bid" element={<PartOrderRequest />} />
      <Route excat path="/completebooking/:bid" element={<CompleteBooking />} />
      <Route excat path="/search" element={<AdvanceSearch />} />
      <Route excat path="/reschedule/:id" element={<BookingReschedule />} />
      <Route excat path="/cancel/:id" element={<BookingCancel />} />
      <Route excat path="/spareparts" element={<SpareActions />} />
      <Route excat path="/getcompletedlist" element={<CompletedBookingList />} />
      <Route excat path="/getcancelledlist" element={<CancelledBookingList />} />
      <Route excat path="/bookingsummary" element={<BookingSummary />} />
      <Route excat path="/bookingsummary/:filter" element={<BookingSummary />} />
      <Route excat path="/sparesummary" element={<SpareSummary />} />
      <Route excat path="/customeroowinvoice" element={<CustomerOOWInvoice />} />
      <Route excat path="/invoicesummary/:entype" element={<InvoiceSummary />} />
      <Route excat path="/404" element={<DATA_NOT_FOUND />} />
      <Route excat path="/resetpassword" element={<ResetPassword />} />
    </>
  );
  return (
    <div>
      <Header />
      <Routes>
        {createRoutes()}
        <Route
          excat
          path="*"
          element={<Navigate to={isLoggedIn ? "/dashboard" : "/"} />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default ScRoutes;