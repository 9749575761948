import React, { useEffect, useState } from "react";
import Select from "react-select";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { openSignUrl } from "../../../utils/HelperFunction";

const GenerateInvoice = () => {
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [entityList, setEntityList] = useState([]);
  const [formData, setFormData] = useState({});
  const currentYear = new Date().getFullYear(); // Get the current year
  const [openpopUp, setOpenPopUp] = useState(false);
  const [response, setResponse] = useState({});

  const handleChange = (maskedValue, selectedMonth) => {

    console.log(maskedValue);
    setFormData({ ...formData, month: maskedValue });
  };

  const entityTypeList = [
    {
      label: "Partner",
      value: "Partner",
    },
    {
      label: "Service Center",
      value: "ServiceCenter",
    },
  ];

  const invoiceVersion = [
    { label: "Draft", value: "Draft" },
    { label: "Final", value: "Final" },
  ];

  const invoiceType = [
    { label: "Service Claim", value: "Service Claim" },
    { label: "Service Commission", value: "Service Commission" },
  ];

  useEffect(() => {
    (async () => {
      if (formData.entity_type === "Partner") {
        const partners = await httpRequest("getpartnerlist", {}, "GET");
        if (partners.status === true && partners.data) {
          setEntityList(partners.data["rows"]);
        }
      } else if (formData.entity_type === "ServiceCenter") {
        const service_center = await httpRequest(
          "getsfdropdownlist",
          {},
          "GET"
        );
        if (service_center.status === true && service_center.data) {
          setEntityList(service_center.data);
        }
      }
    })();
  }, [formData.entity_type]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (values) => {
    const errors = {};

    if (!values.entity_type) {
      errors.entity_type = "Entity Type is Required";
    } else if (!values.entity_id) {
      errors.entity_id = "Enter Company Name";
    } else if (!values.invoice_version) {
      errors.invoice_version = "Invoice Version is Required";
    } else if (!values.invoice_type) {
      errors.invoice_type = "Invoice Type is Required";
    } else if (!values.month) {
      errors.month = "Month is Required";
    }

    console.log(errors);

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formData);
      setSubmit(false);
      (async () => {
        const data = await httpRequest(
          "generateserviceinvoice",
          formData,
          "POST"
        );

        if (data.status === true && data.message) {
          setOpenPopUp(true);
          setResponse(data);

          setFormData({});
        } else {
          toast.error(data.message);
        }
      })();
    }
    //eslint-disable-next-line
  }, [formErrors, isSubmit]);

  const alertResponse = () => {
    return (
      <>
        <div className="form-overlay">
          <div
            className="form-container"
            style={{ maxWidth: "400px", padding: "50px" }}
          >
            <i
              className="fa fa-times"
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => {
                setOpenPopUp(false);
                setResponse({});
              }}
            ></i>
            <div className="form-content">
              <h5>{response.message}</h5>
              <br />
              <br />
              <p>
                Click to download invoice PDF:{" "}
                <Link
                  to={"#"}
                  onClick={() => {
                    openSignUrl(response.main_file);
                  }}
                  rel="noopener noreferrer"
                >
                  Download
                </Link>
              </p>
              <p>
                Click here to download invoice Excel:{" "}
                <Link
                  to={"#"}
                  onClick={() => {
                    openSignUrl(response.annx_file);
                  }}
                  rel="noopener noreferrer"
                >
                  Download
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle="Invoice"
        breadcrumbSubTitle="Generate Invoice"
        component={
          <section className="content">
            <div className="container-fluid">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header"><h3 className="card-title">Generate Invoice</h3></div>
                  <div className="card-body">
                    <form
                      className="form-horizontal"
                      id="basicDetailsform"
                      onSubmit={onSubmitHandler}
                    >
                      <div className="form-group row">
                        <label
                          htmlFor="inputName"
                          className="col-sm-2 col-form-label"
                        >
                          Entity Type *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.entity_type}
                          </span>
                        </label>
                        <div className="col-sm-4">
                          <Select
                            name="entity_type"
                            value={
                              entityTypeList.find(
                                (option) =>
                                  option.value === formData.entity_type
                              ) || null
                            }
                            options={entityTypeList}
                            placeholder="Select Entity Type"
                            onChange={(val) => {
                              setFormData({
                                ...formData,
                                entity_type: val.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="inputName"
                          className="col-sm-2 col-form-label"
                        >
                          Company *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.entity_id}
                          </span>
                        </label>
                        <div className="col-sm-4">
                          <Select
                            name="entity_id"
                            value={
                              entityList.find(
                                (option) => option.value === formData.entity_id
                              ) || null
                            }
                            options={entityList}
                            placeholder="Select Company"
                            onChange={(val) => {
                              setFormData({
                                ...formData,
                                entity_id: val.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="inputName"
                          className="col-sm-2 col-form-label"
                        >
                          Invoice Verison *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.invoice_version}
                          </span>
                        </label>
                        <div className="col-sm-4">
                          <Select
                            name="invoice_version"
                            value={
                              invoiceVersion.find(
                                (option) =>
                                  option.value === formData.invoice_version
                              ) || null
                            }
                            options={invoiceVersion}
                            placeholder="Select Invoice Verison"
                            onChange={(val) => {
                              setFormData({
                                ...formData,
                                invoice_version: val.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="inputName"
                          className="col-sm-2 col-form-label"
                        >
                          Invoice Type *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.invoice_type}
                          </span>
                        </label>
                        <div className="col-sm-4">
                          <Select
                            name="entity_id"
                            value={
                              invoiceType.find(
                                (option) =>
                                  option.value === formData.invoice_type
                              ) || null
                            }
                            options={invoiceType}
                            placeholder="Select Invoice Type"
                            onChange={(val) => {
                              setFormData({
                                ...formData,
                                invoice_type: val.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="inputName"
                          className="col-sm-2 col-form-label"
                        >
                          Invoice Monh *{" "}
                          <span style={{ color: "red" }}>
                            {formErrors.month}
                          </span>
                        </label>
                        <div className="col-sm-4">
                          <MonthPickerInput
                            year={currentYear}
                            onChange={handleChange}
                            inputProps={{
                              placeholder: "Select a month",
                              className: "form-control",
                            }}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="form-group row">
                        <button className="btn btn-primary col-sm-6">
                          Generate Invoice
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {response && openpopUp && alertResponse()}
          </section>
        }
      />
    </div>
  );
};

export default GenerateInvoice;
