import React, {useState} from "react";
import MainBody from "../../../component/MainBody";
import MyDataTable from "../../../component/MyDataTable";
import { useParams } from "react-router-dom";

const MslPartLineItem = () => {
  const { part_number, purchase_item_id } = useParams();
  const [reloadList, setReload] = useState(true);

  const getStatusMessage = (status) => {
    switch (Number(status)) {
      case 0:
        return "Not Acknowledged";
      case 1:
        return "Ok Acknowledged";
      case 2:
        return "Damage Received";
      case 3:
        return "Not Received";
      default:
        return "Acknowledged";
    }
  };

  const columns = [
    {
        name: 'SNo.',
        selector: (row, index) => index + 1,
    },
    {
        name: 'Part Number',
        selector: () => part_number,
    },
    {
        name: 'quantity',
        selector: (row) => row.quantity,
    },
    {
        name: 'Serial Number',
        selector: (row) => row.serial_number,

    },
    {
        name: 'Status',
        selector: (row) =>
            getStatusMessage(row.status),
    },
    {
        name: 'Acknowledged',
        selector: (row) =>
            row.ack_date? new Date(row.ack_date).toDateString(): ''
    },
];

  return (
    <div>
      <>
        <MainBody
          breadcrumbTitle="Msl Part Details"
          breadcrumbSubTitle={""}
          navButton={""}
          title={""}
          component={
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">
                          <b>{`Part Number - ${part_number}`}</b>
                        </h3>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        setReload={setReload}
                        getDataListURL={`getmslpartlineitem?purchase_item_id=${purchase_item_id}`}
                      />
                    </div>
                  </div>

                  <div className="col-md-6"></div>
                </div>
              </div>
            </section>
          }
        />
      </>
    </div>
  );
};

export default MslPartLineItem;
