import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MyDataTable from "../../component/MyDataTable";
import httpRequest from "../../utils/Http";
import { toast } from "react-toastify";

const EstimatePending = ({reloadList, setReload}) => {
  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Booking ID",
      selector: (row) => row.booking_detail.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
          {row.booking_detail.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.booking_detail.user_detail.customer_name,
      wrap: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.booking_detail.booking_primary_mobile_number,
      wrap: true,
    },
    {
      name: "Part Name",
      selector: (row) => row.requested_part.part_name,
      wrap: true,
    },
    {
      name: "Part Number",
      selector: (row) => row.requested_part.part_number,
      wrap: true,
    },
    {
      name: "Estimate Cost",
      selector: (row) => "Rs." + row.estimate_price,
      wrap: true,
    },
    {
      name: "Estimate Given Date",
      selector: (row) => new Date(row.estimate_date).toDateString(),
      wrap: true,
    },
    {
      name: "Approve",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleApprove(row);
          }}
        >
          Approve
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: "Reject",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => {
            handleRejection(row);
          }}
        >
          Reject
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);

  const handleRejection = async (row) => {
    const shouldPerformAction = window.confirm(
      "Are you sure you want to reject estimate?"
    );
    if (shouldPerformAction) {
      const data = await httpRequest(
        `rejectestimate/${row.id}/${row.bid}`,
        {},
        "POST"
      );
      if (data.status === true && data.message) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setReload(true);
    }
  };

  const handleApprove = async (row) => {
    const shouldPerformAction = window.confirm(
      "Are you sure you want to approve estimate?"
    );
    if (shouldPerformAction) {
      const data = await httpRequest(
        `approveestimate/${row.id}/${row.bid}`,
        {},
        "POST"
      );
      if (data.status === true && data.message) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setReload(true);
    }
  };

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Estimate To Be Approved</h3>
                </div>
                <MyDataTable
                  reloadList={reloadList}
                  columns={columns}
                  getDataListURL={`getsparedetailslist?estimategiven=1`}
                  setReload={setReload}
                  exportName={"sparelist"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EstimatePending;
