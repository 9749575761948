import React, { useEffect, useState } from "react";
import httpRequest from "../../../utils/Http";
import Select from "react-select";
import { toast } from "react-toastify";
import MyDataTable from "../../../component/MyDataTable";
import Switch from "../../../component/Switch";

const UpdateKam = ({ partner_id, role_id }) => {
  const [formData, setFormData] = useState({ partner_id });
  const [isSubmit, setSubmit] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [kamlist, setkamlist] = useState([]);
  const [reloadList, setReload] = useState(true);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  useEffect(() => {
    (async () => {
      const agentData = await httpRequest(`getalldropdownkamlist`, {}, "GET");
      if (agentData.status === true && agentData.message) {
        //console.log(roleData.message);
        setkamlist(agentData.message);
      }
    })();
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (data) => {
    const errors = {};
    if (!data.partner_id) {
      errors.partner_id = "Partner Id is required";
    }
    if (!data.agent_id) {
        errors.agent_id = "Key Account Manager is required";
      }
    return errors;
  };


  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest("associatekamwithpartner", formData, "POST");
        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          document.getElementById("kamcreate").reset();
          setFormData({ partner_id });
          setReload(true);
          setIsDivVisible(false);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (_, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Name",
      selector: (row) => row.agent_detail.name,
      wrap: true
    },
    {
      name: "Email ID",
      selector: (row) => row.agent_detail.email,
      wrap: true
    },

    {
      name: "Mobile Number",
      selector: (row) => row.agent_detail.mobile_number,
      wrap: true
    },
    role_id == 1 && {
      name: "Status",
      selector: (row) =>
        Number(row.active) === 1 ? "Activated" : "DeActivated",
      cell: (row) => (
        <Switch
          isOn={Number(row.active) === 1 ? true : false}
          id={row.kam_id}
          handleToggle={(e) => {
            updateToggleHandler(row.kam_id, e.target.checked);
          }}
          onColor="#EF476F"
        />
      ),
      wrap: true
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
      wrap: true
    },
  ].filter(Boolean);


  const updateToggleHandler = async (kam_id, value) => {
    try {
      setReload(false);
      let postData = {
        kam_id,
        active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updatekamstatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);

        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {}
  };


  return (
    <div>
      {isDivVisible && (
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-default">
                <div className="card-header">
                  <h3 className="card-title">Add Key Account Manager</h3>
                  <div className="card-tools">
                    <button
                      className="btn btn-sm btn-tools"
                      onClick={toggleDivVisibility}
                    >
                      {isDivVisible === false ? (
                        ""
                      ) : (
                        <i className="fas fa-minus"></i>
                      )}
                    </button>
                  </div>
                </div>
                <form
                  className="form-horizontal"
                  id="kamcreate"
                  onSubmit={onSubmitHandler}
                  action="javascritp:void(0)"
                >
                  <div className="card-body">
                    <div className="form-group row">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 col-form-label"
                      >
                        Name *{" "}
                        
                      </label>
                      <div className="col-sm-8">
                        <Select
                          name="agent_id"
                          options={kamlist}
                          value={kamlist.find((obj) => obj.value == formData.agent_id)}
                          onChange={(val) => {
                            setFormData({ ...formData, agent_id: val.value });
                          }}
                        />
                        <span style={{ color: "red" }}>
                          {formErrors.agent_id}
                        </span>
                      </div>
                    </div>

                    <div className="col-12 offset-md-3">
                      <input
                        type="submit"
                        defaultValue="Save Changes"
                        className="btn btn-primary col-md-4"
                      />
                    </div>
                  </div>
                </form>
                {/* /.card-body */}
              </div>
            </div>
          </div>
        </section>
      )}{" "}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card" style={{ padding: "10px" }}>
                <div className="card-header">
                  <h3 className="card-title">Partner KAM List</h3>
                  <div className="card-tools">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={toggleDivVisibility}
                    >
                      {isDivVisible === false ? "Add New KAM" : ""}
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={`getpartnerkamlist?partner_id=${partner_id}`}
                        setReload={setReload}
                        exportName={"partnerkamlist"}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpdateKam;
