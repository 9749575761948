import React, { useState } from "react";
import { Link } from "react-router-dom";
import { openSignUrl } from "../../../utils/HelperFunction";
import MainBody from "../../../component/MainBody";
import MyDataTable from "../../../component/MyDataTable";

const CustomerOOWInvoice = () => {
  const [reloadList, setReload] = useState(true);
  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Booking ID",
      selector: (row) => row.booking_detail.booking_id,
    },
    {
        name: <div className="word-wrap">Service Center Name</div>,
        selector: (row) => row.service_centre.display_name,
    },
    {
      name: "Customer Name",
      selector: (row) => row.user_detail.customer_name,
    },
    {
        name: "Customer Mobile",
        selector: (row) => row.user_detail.mobile_number,
    },
    {
      name: "Invoice Number",
      selector: (row) => row.invoice_number,
      cell: (row) => (
        <Link to={"#"} onClick={() => {
            openSignUrl(row.main_file)
        }}>{row.invoice_number}</Link>
      ),
    },
    {
        name: "Quantity",
        selector: (row) => row.quantity,
    },
    {
        name: "Invoice Date",
        selector: (row) => new Date(row.invoice_date).toDateString(),
    },
  ];

  return (
    <div>
      <div>
        <MainBody
          breadcrumbTitle="Customer Invoice"
          breadcrumbSubTitle={"List"}
          navButton={""}
          title={""}
          component={
            <>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="card" style={{ padding: "10px" }}>
                        <div className="card-header">
                          <h3 className="card-title">Customer Invoice</h3>
                        </div>
                        <div className="card-body">
                          <MyDataTable
                            reloadList={reloadList}
                            columns={columns}
                            getDataListURL={
                              "getcustomeroowinvoice?isactive=all"
                            }
                            setReload={setReload}
                            exportName={"customerinvoice"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          }
        />
      </div>
    </div>
  );
};

export default CustomerOOWInvoice;
