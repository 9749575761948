import React, { useEffect, useState } from "react";
import Select from "react-select";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { PARTNER_TYPE, SF_TYPE } from "../../../constant/status_constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { UploadFileToS3 } from "../../../utils/S3File";

const CreatemanualInvoice = () => {
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [appaliceDeskGstNumberList, setApplianceDeskGstNumberList] = useState(
    []
  );
  const [companyGstList, setCompanyGstList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [formData, setFormData] = useState({});
  const currentYear = new Date().getFullYear();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryItemList, setCategoryItemList] = useState([]);
  const [rows, setRows] = useState([
    {
      description: "",
      type: null,
      hsnCode: "",
      quantity: "",
      rate: "",
      taxable: "",
      gstRate: "",
      gstAmount: "",
      total: "",
    },
  ]);

  // Calculate total amount from rows
  const calculateFinalTotal = () => {
    return rows
      .reduce((sum, row) => sum + parseFloat(row.total || 0), 0)
      .toFixed(2);
  };

  // Handle adding a new row
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        description: "",
        type: null,
        hsnCode: "",
        quantity: "",
        rate: "",
        taxable: "",
        gstRate: "",
        gstAmount: "",
        total: "",
      },
    ]);
  };

  // Handle removing a row
  const handleRemoveRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    if (newRows.length === 0) {
      return; // Prevent removing all rows
    }
    setRows(newRows);
  };

  // Calculate dependent fields
  const calculateDependentFields = (index, newRows) => {
    const quantity = parseFloat(newRows[index].quantity || 0);
    const rate = parseFloat(newRows[index].rate || 0);
    const gstRate = parseFloat(newRows[index].gstRate || 0);

    // Calculate taxable amount
    const taxable = (quantity * rate).toFixed(2);

    // Calculate GST amount
    const gstAmount = ((taxable * gstRate) / 100).toFixed(2);

    // Calculate total amount
    const total = (parseFloat(taxable) + parseFloat(gstAmount)).toFixed(2);

    newRows[index].taxable = taxable;
    newRows[index].gstAmount = gstAmount;
    newRows[index].total = total;
  };

  // Handle input changes
  const handleInputChange = (index, field, value) => {
    // Ensure only digits are entered
    const digitOnlyValue =
      field !== "type" && field !== "description"
        ? value.replace(/\D/g, "")
        : value;
    const newRows = [...rows];
    newRows[index][field] = digitOnlyValue;

    if (field === "quantity" || field === "rate" || field === "gstRate") {
      calculateDependentFields(index, newRows);
    }

    setRows(newRows);
  };
  const entityTypeList = [
    {
      label: "Partner",
      value: PARTNER_TYPE,
    },
    {
      label: "Service Center",
      value: SF_TYPE,
    },
  ];

  const invoiceTypelist = [
    {
      label: "Purchase Invoice",
      value: "Purchase",
    },
    {
      label: "Sale invoice",
      value: "Sale",
    },
  ];

  useEffect(() => {
    (async () => {
      if (Number(formData.entity_type) === PARTNER_TYPE) {
        const partners = await httpRequest("getpartnerlist", {}, "GET");
        if (partners.status === true && partners.data) {
          setEntityList(partners.data["rows"]);
        }
      } else if (Number(formData.entity_type) === SF_TYPE) {
        const service_center = await httpRequest(
          "getsfdropdownlist",
          {},
          "GET"
        );
        if (service_center.status === true && service_center.data) {
          setEntityList(service_center.data);
        }
      }
    })();
  }, [formData.entity_type]);

  useEffect(() => {
    (async () => {
      const partners = await httpRequest(
        "getdefaultpartnergstdropdown",
        {},
        "GET"
      );
      if (partners.status === true && partners.data) {
        setApplianceDeskGstNumberList(partners.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (formData.entity_id && formData.entity_type) {
      (async () => {
        const partners = await httpRequest(
          `getgstnumberdropdownlist?entity_id=${formData.entity_id}&entity_type=${formData.entity_type}`,
          {},
          "GET"
        );
        if (partners.status === true && partners.data) {
          setCompanyGstList(partners.data);
        }
      })();
    }
  }, [formData.entity_id]);

  useEffect(() => {
    //getinvoicecategory
    (async () => {
      const partners = await httpRequest(`getinvoicecategory`, {}, "GET");
      if (partners.status === true && partners.data) {
        setCategoryList(partners.data);
      }

      const categoryItemList = await httpRequest(
        `getinvoiceitemcategory`,
        {},
        "GET"
      );
      if (categoryItemList.status === true && categoryItemList.data) {
        setCategoryItemList(categoryItemList.data);
      }
    })();
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log('hi')
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setSubmit(false);
      (async () => {
        let formData1 = formData;
        if(formData.main_file){
            formData1.main_file = await UploadFileToS3("docFile", formData.main_file);
        }

        if(formData.annx_file){
            formData1.annx_file = await UploadFileToS3("docFile", formData.annx_file);
        }

        const data = await httpRequest(
          "createmanualinvoice",
          formData1,
          "POST"
        );

        if (data.status === true && data.message) {
          toast.success(data.message);
          setFormData({});
        } else {
          toast.error(data.message);
        }
      })();
    }
    //eslint-disable-next-line
}, [formErrors, isSubmit]);

useEffect(() => {
    setFormData({...formData, rows: rows});
}, [rows])


  const validateForm = (values) => {
    const errors = {};

    // Validate form fields
    if (!values.entity_type) {
      errors.entity_type = "Entity Type is Required";
    }
    if (!values.entity_id) {
      errors.entity_id = "Select Company Name";
    }
    if (!values.invoice_type) {
      errors.invoice_type = "Invoice Type is Required";
    } else if(values.invoice_type === "Purchase" && !values.invoice_number){
        errors.invoice_number = "Invoice Number is Required";
    }
    if (!values.month) {
      errors.month = "Month is Required";
    }
    if (!values.appliance_desk_gst_id) {
      errors.appliance_desk_gst_id = "Appliance Desk GST Number is Required";
    }
    if (!values.invoice_date) {
      errors.invoice_date = "Invoice Date is Required";
    }
    if (!values.category_id) {
      errors.category_id = "Category List is Required";
    }
    if (!values.main_file) {
      errors.main_file = "Invoice Main File is Required";
    }

    if(companyGstList.length >0 && !values.company_gst_id){
      errors.company_gst_id = "Company GST Number is Required";
    }

    // Validate each row
    const rowErrors = values.rows.map((row, index) => {
      const rowError = {};
      if (!row.description) {
        rowError.description = "Description is required";
        toast.error("Description is required at row " + (index + 1));
      }
      if (!row.type) {
        rowError.type = "Product/Service is required";
        toast.error("Product/Service is required at row " + (index + 1));
      }
      if (!row.hsnCode) {
        rowError.hsnCode = "HSN Code is required";
        toast.error("HSN Code is required at row " + (index + 1));
      }
      if (!row.quantity) {
        rowError.quantity = "Quantity is required";
        toast.error("Quantity is required at row " + (index + 1));
      } else if(Number(row.quantity) < 1){
        rowError.quantity = "Quantity should be greater than 0";
        toast.error("Quantity is required at row " + (index + 1));
      }
      if (!row.rate) {
        rowError.rate = "Rate is required";
        toast.error("Rate is required at row " + (index + 1));
      } else if(Number(row.rate) < 1){
        rowError.rate = "Rate should be greater than 0";
        toast.error("Rate is required at row " + (index + 1));
      }
      if (!row.gstRate) {
        rowError.gstRate = "GST Rate is required";
        toast.error("Gst Rate is required at row " + (index + 1));
      }
      return rowError;
    });

    // Add row errors to the form errors if any exist
    if (rowErrors.some((rowError) => Object.keys(rowError).length > 0)) {
      errors.rows = rowErrors;
    }
    console.log(errors)
    return errors;
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle="Invoice"
        breadcrumbSubTitle="Generate Invoice"
        component={
          <>
           <form onSubmit={onSubmitHandler}>
            <section className="content">
              <div className="container-fluid">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Create Manual Invoice</h3>
                    </div>
                    <div className="card-body">
                     
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <label>
                              Appliance Desk *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_type}
                              </span>
                            </label>
                            <Select
                              name="invoice_type"
                              value={
                                invoiceTypelist.find(
                                  (option) =>
                                    option.value === formData.invoice_type
                                ) || null
                              }
                              options={invoiceTypelist}
                              placeholder="Select Purchase/Sale Invoice"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  invoice_type: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Appliance Desk Gst Number *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.appliance_desk_gst_id}
                              </span>
                            </label>
                            <Select
                              name="appliance_desk_gst_id"
                              value={
                                appaliceDeskGstNumberList.find(
                                  (option) =>
                                    option.value ===
                                    formData.appliance_desk_gst_id
                                ) || null
                              }
                              options={appaliceDeskGstNumberList}
                              placeholder="Select Appliance Desk Gst Number"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  appliance_desk_gst_id: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Month *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_type}
                              </span>
                            </label>
                            <MonthPickerInput
                              year={currentYear}
                              onChange={(maskedValue) => {
                                setFormData({
                                  ...formData,
                                  month: maskedValue,
                                });
                              }}
                              inputProps={{
                                placeholder: "Select a month",
                                className: "form-control",
                              }}
                            />
                          </div>

                          <div className="form-group col-md-3">
                            <label>
                              Entity Type *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_type}
                              </span>
                            </label>
                            <Select
                              name="entity_type"
                              value={
                                entityTypeList.find(
                                  (option) =>
                                    option.value === formData.entity_type
                                ) || null
                              }
                              options={entityTypeList}
                              placeholder="Select Entity Type"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  entity_type: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Company Name *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_id}
                              </span>
                            </label>
                            <Select
                              name="entity_id"
                              value={
                                entityList.find(
                                  (option) =>
                                    option.value === formData.entity_id
                                ) || null
                              }
                              options={entityList}
                              placeholder="Select Company"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  entity_id: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Company Gst Number{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.company_gst_id}
                              </span>
                            </label>
                            <Select
                              name="company_gst_id"
                              value={
                                companyGstList.find(
                                  (option) =>
                                    option.value === formData.company_gst_id
                                ) || null
                              }
                              options={companyGstList}
                              placeholder="Select Company Gst Number"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  company_gst_id: val.value,
                                });
                              }}
                            />
                          </div>

                          <div className="form-group col-md-3">
                            <label>
                              Reference Invoice Number{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.company_gst_id}
                              </span>
                            </label>
                            <input
                              type="text"
                              name="reference_invoice_number"
                              className="form-control"
                              placeholder="Enter Reference Invoice Number"
                              value={formData.reference_invoice_number}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  reference_invoice_number: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Invoice Number{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.invoice_number}
                              </span>
                            </label>
                            <input
                              type="text"
                              name="invoice_number"
                              className="form-control"
                              placeholder="Enter Invoice Number"
                              readOnly={
                                formData.invoice_type === "Sale" ? true : false
                              }
                              value={formData.invoice_number}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  invoice_number: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Invoice Date *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.invoice_date}
                              </span>
                            </label>
                            <DatePicker
                              className="form-control text-capitalize"
                              minDate={
                                new Date(
                                  new Date().setDate(new Date().getDate() - 3)
                                )
                              }
                              maxDate={
                                new Date(
                                  new Date().setDate(new Date().getDate())
                                )
                              }
                              dateFormat="dd/MM/yyyy"
                              selected={formData.invoice_date}
                              placeholderText="Select Invoice Date"
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  invoice_date: date,
                                });
                              }}
                            />
                          </div>

                          <div className="form-group col-md-3">
                            <label>
                              Category List *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.category_id}
                              </span>
                            </label>
                            <Select
                              name="company_gst_id"
                              value={
                                categoryList.find(
                                  (option) =>
                                    option.value === formData.category_id
                                ) || null
                              }
                              options={categoryList}
                              placeholder="Select Category"
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  category_id: val.value,
                                });
                              }}
                            />
                          </div>
                          <div className="form-group col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Invoice Main File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.main_file}
                              </span>
                            </label>
                            <input
                              type="file"
                              name="main_file"
                              className="form-control"
                              onChange={(e)=>{
                                setFormData({
                                  ...formData,
                                  main_file: e.target.files[0],
                                })
                              }}
                            />
                          </div>
                          <div className="form-group col-md-1"></div>
                          <div className="form-group col-md-3">
                            <label>
                              Invoice Annx File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.annx_file}
                              </span>
                            </label>
                            <input
                              type="file"
                              name="annx_file"
                              className="form-control"
                              onChange={(e)=>{
                                setFormData({
                                  ...formData,
                                  annx_file: e.target.files[0],
                                })
                              }}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label>
                              Remarks{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.remarks}
                              </span>
                            </label>
                            <input
                              type="text"
                              name="remarks"
                              placeholder="Enter Remarks"
                              className="form-control"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  remarks: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Add Line Items</h3>
                    </div>
                    <div className="card-body">
                      <table className="table priceList table-striped table-bordered">
                        <thead>
                          <tr>
                            <th className="text-center">SNo</th>
                            <th className="text-center">Description</th>
                            <th className="text-center">Product/Service</th>
                            <th className="text-center">HSN Code</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-center">Rate</th>
                            <th className="text-center">Taxable</th>
                            <th className="text-center" colSpan="2">
                              GST
                            </th>
                            <th className="text-center">Total</th>
                            <th className="text-center"></th>
                          </tr>
                          <tr>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center">Rate</th>
                            <th className="text-center">Amount</th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td
                                className="table-list-item-custom"
                                style={{ width: "20%" }}
                              >
                                <textarea
                                  type="text"
                                  value={row.description}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td
                                className="table-list-item-custom"
                                style={{ width: "20%" }}
                              >
                                <Select
                                  value={row.type}
                                  onChange={(selectedOption) =>
                                    handleInputChange(
                                      index,
                                      "type",
                                      selectedOption
                                    )
                                  }
                                  options={categoryItemList}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      minWidth: "100%",
                                    }),
                                  }}
                                />
                              </td>
                              <td className="table-list-item-custom">
                                <textarea
                                  type="text"
                                  value={row.hsnCode}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "hsnCode",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td className="table-list-item-custom">
                                <textarea
                                  type="text"
                                  value={row.quantity}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td className="table-list-item-custom">
                                <textarea
                                  type="text"
                                  value={row.rate}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "rate",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td className="table-list-item-custom">
                                <textarea
                                  type="text"
                                  value={row.taxable}
                                  readOnly
                                  className="form-control"
                                />
                              </td>
                              <td className="table-list-item-custom">
                                <textarea
                                  type="text"
                                  value={row.gstRate}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "gstRate",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                />
                              </td>
                              <td className="table-list-item-custom">
                                <textarea
                                  type="text"
                                  value={row.gstAmount}
                                  readOnly
                                  className="form-control"
                                />
                              </td>
                              <td className="table-list-item-custom">
                                <textarea
                                  type="text"
                                  value={row.total}
                                  readOnly
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <button
                                  onClick={() => handleRemoveRow(index)}
                                  className="btn btn-danger"
                                  disabled={rows.length === 1} // Disable if only one row is left
                                >
                                  -
                                </button>
                                <br /> <br />
                                {index === rows.length - 1 && (
                                  <button
                                    onClick={handleAddRow}
                                    className="btn btn-success"
                                  >
                                    +
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="9" className="text-right">
                              <strong>Total Amount:</strong>
                            </td>
                            <td colSpan={"2"}>
                              <textarea
                                type="text"
                                value={calculateFinalTotal()}
                                readOnly
                                className="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="form-group row">
                        <div className="offset-md-3 col-sm-6">
                        <button className="btn btn-primary col-sm-12 ">
                          Create Invoice
                        </button>
                        </div>
                        
                      </div>
                  </div>
                </div>
              </div>
            </section>
            </form>
          </>
        }
      />
    </div>
  );
};

export default CreatemanualInvoice;
