import React, { useState } from "react";
import { PARTNER_TYPE, SF_TYPE } from "../../../constant/status_constant";
import MainBody from "../../../component/MainBody";
import MyDataTable from "../../../component/MyDataTable";
import Select from "react-select";

const BalanceSummaryforAll = () => {
  const [reloadList, setReload] = useState(false);
  const [formData, setFormData] = useState({});
  const entityTypeList = [
    {
      label: "Partner",
      value: PARTNER_TYPE,
    },
    {
      label: "Service Center",
      value: SF_TYPE,
    },
  ];

  const columns = [
    {
        name: "SNo.",
        selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
        cell: (row, index) => index + 1, // Use cell to render the incrementing number
      },
    {
      name: <div className="wrap">Company Name</div>,
      selector: (row) => row.entity_name,
      wrap: true,
    },
    {
      name: <div className="wrap">Balance</div>,
      selector: (row) => row.balanceDifference,
      wrap: true,
    },
  ];
  
  

  return (
    <>
      {formData.entity_type !== null && (
        <MainBody
          breadcrumbTitle="Balance Summary"
          breadcrumbSubTitle={"List"}
          navButton={""}
          title={
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Entity Type * </label>
                  <Select
                    name="entity_type"
                    value={
                      entityTypeList.find(
                        (option) => option.value === formData.entity_type
                      ) || null
                    }
                    options={entityTypeList}
                    onChange={(val) => {
                      setFormData({
                        ...formData,
                        entity_type: val.value,
                      });
                      setReload(true);
                    }}
                  />
                </div>
              </div>
            </div>
          }
          component={
            <>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="card" style={{ padding: "10px" }}>
                        <div className="card-header">
                          <h3 className="card-title">Balance Summary</h3>
                        </div>
                        <div className="card-body">
                          {formData.entity_type ? (
                            <MyDataTable
                              reloadList={reloadList}
                              columns={columns}
                              getDataListURL={`getbalancesummaryforallentities?entity_type=${formData.entity_type}`}
                              setReload={setReload}
                              exportName={"balance"}
                            />
                          ) : (
                            "No Record Found"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          }
        />
      )}
    </>
  );
};

export default BalanceSummaryforAll;
