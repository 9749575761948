import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation, Link } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import {
  decryptData,
  encryptData,
  isValidEmail,
} from "../utils/HelperFunction";
import httpRequest from "../utils/Http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from "../reducers/auth";
import { useDispatch } from "react-redux";

const Login = () => {
//   const [sessionId, setSessionId] = useState("");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
//   const navigate = useNavigate();
   const location = useLocation();
  let dispatch = useDispatch();


  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log(formData);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (values) => {
    const errors = {};

    if (!values.emailId) {
      if (!isValidEmail(values.emailId)) {
        errors.emailId = "Enter email id";
      }
    } else if (!values.password) {
      errors.password = "Enter password";
    }

    if (values.emailId) {
      if (!isValidEmail(values.emailId)) {
        errors.emailId = "Enter valid email id";
      }
    }

    return errors;
  };

  useEffect(() => {
    console.log(formData);
    if (Object.keys(formErrors).length === 0 && isSubmit) {

      (async () => {
        const data = await httpRequest(
          "login",
          {
            ...formData,
          },
          "POST"
        );
        console.log(data);
        if (data.status === true && data.message) {
          setSubmit(false);
          
          dispatch(
            login({
              token: data.message,
            })
          );
          toast.success("Authentication Successful");
          window.location.reload();

          
        } else {
          toast.error(data.message);
          setFormErrors({ inputType: data.message });
          console.log(data.message);
        }
      })();
    }
    //eslint-disable-next-line
  }, [formErrors, isSubmit]);

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="login-logo">
          <Link to={"/"}>
            Welcome to <b>{process.env.REACT_APP_TITLE}</b>
          </Link>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Login to your {process.env.REACT_APP_TITLE} account.</p>
            <form onSubmit={onSubmitHandler} method="post">
              <div className="input-group mb-3">
                <input
                  type={"email"}
                  className={
                    !formErrors.emailId
                      ? "form-control form-icon-input"
                      : "is-invalid form-icon-input form-control"
                  }
                  placeholder={"Email"}
                  name={"emailId"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className={"fas fa-envelope"} />
                  </div>
                </div>
                <div className={!formErrors.emailId ? "" : "invalid-feedback"}>
                  {formErrors.emailId}
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className={
                    !formErrors.password
                      ? "form-control form-icon-input"
                      : "is-invalid form-icon-input form-control"
                  }
                  placeholder="Password"
                  name="password"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
                <div className={!formErrors.password ? "" : "invalid-feedback"}>
                  {formErrors.password}
                </div>
                
              </div>
              <div style={{color: '#dc3545'}}>
                  {formErrors.inputType}
                </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block" style={{marginLeft: 0}}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
