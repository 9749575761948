import {
    LABEL_JOB_DETAILS,
    LABEL_JOB_STATUS, LABEL_JOB_DATE, LABEL_JOB
} from "../../../constant/label_constant";

import moment from 'moment';
import { openSignUrl } from "../../../utils/HelperFunction";


const BasicDetailsTab = ({ bookingData }) => {
    console.log(bookingData)

    return (
        <>
            
                <section className="content">
                    {/* Default box */}
                    <div className="card card-solid">
                        
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                    <div className="card bg-light d-flex flex-fill">
                                        <div className="card-header text-muted border-bottom-0">
                                            Customer Details
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2 className="lead"><b>{bookingData.user_detail['customer_name']}</b></h2>

                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-building" /></span> <b>Address:</b> {bookingData.booking_address + ", " + bookingData.booking_city + ", " + bookingData.booking_state + ", Pincode - " + bookingData.booking_pincode}</li>

                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-phone" /></span> <b>Phone:</b> {bookingData.booking_primary_mobile_number} {bookingData.booking_alternate_mobile_number && (<>
                                                            / {bookingData.booking_alternate_mobile_number}
                                                        </>)}</li>

                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-envelope" /></span> <b>Email ID:</b> {bookingData.user_detail['user_email']}</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                    <div className="card bg-light d-flex flex-fill">
                                        <div className="card-header text-muted border-bottom-0">
                                            {LABEL_JOB_DETAILS}
                                            <div className="card-tools" style={{cursor: "pointer"}}><i onClick={() => { 
                                                    openSignUrl(bookingData.jobcard_file)
                                                }} className="fas fa-download" /> </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2 className="lead"><b>{bookingData.booking_id}</b></h2>
                                                    <p className="text-muted text-sm"><b>Order ID: </b> {bookingData.order_id}</p>
                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fa fa-lg fa-a" /></span> <b>Appliance:</b> {bookingData.appliance['appliance_name']}</li>
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fa fa-lg fa-b" /></span> <b>Type:</b> {bookingData.booking_type}</li>
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-store" /></span> <b>Seller Channel:</b> {bookingData.source_channel['source']}</li>
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-rupee-sign" /></span> <b>Amount Due/ Paid:</b> {Math.round(bookingData.amount_due) + " / " + Math.round(bookingData.amount_paid)}</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                    <div className="card bg-light d-flex flex-fill">
                                        <div className="card-header text-muted border-bottom-0">
                                            Assigned Service Center
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2 className="lead"><b>{bookingData.service_centre !== null && (
                                                        bookingData.service_centre['display_name']
                                                    )}</b></h2>
                                                    <p className="text-muted text-sm"><b>POC Name: </b> {bookingData.service_centre !== null && (
                                                        bookingData.service_centre?.poc_details?.name
                                                    )} </p>
                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-building" /></span> <b>Address:</b> {bookingData.service_centre !== null && (
                                                            bookingData.service_centre['address'] + ", " + bookingData.service_centre['city'] + ", " + "Pincode: " + bookingData.service_centre['pincode']
                                                        )}</li>
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-phone" /></span> <b>Phone:</b> {bookingData.service_centre !== null && (
                                                            bookingData.service_centre?.poc_details?.mobile_number
                                                        )}</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                        {/* <div className="card-footer">
                                            <div className="text-right">

                                                <a href="#" className="btn btn-sm btn-primary">
                                                    <i className="fas fa-user" /> View Profile
                                                </a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                    <div className="card bg-light d-flex flex-fill">
                                        <div className="card-header text-muted border-bottom-0">
                                            {LABEL_JOB_DATE + "s"}
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2 className="lead"><b>{moment(bookingData.booking_date).format("DD MMM YYYY")}</b></h2>
                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-calendar-day" /></span> <b>Registration Date:</b> {moment(bookingData.createdAt).format("DD MMM YYYY")}</li>

                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-lock" /></span> <b>First {LABEL_JOB_DATE}:</b> {moment(bookingData.booking_initial_date).format("DD MMM YYYY")}</li>
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-calendar" /></span> <b>Appointment Date:</b> {moment(bookingData.booking_booking_date).format("DD MMM YYYY")}</li>
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-calendar-check" /></span> <b>SC Closed Date:</b> {bookingData.sc_closed_date !== null && (moment(bookingData.sc_closed_date).format("DD MMM YYYY"))}</li>
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-calendar-check" /></span> <b>Closed Date:</b> {bookingData.closed_date !== null && (moment(bookingData.closed_date).format("DD MMM YYYY"))}</li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                    <div className="card bg-light d-flex flex-fill">
                                        <div className="card-header text-muted border-bottom-0">
                                            {LABEL_JOB_STATUS}
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2 className="lead"><b>{bookingData.current_status}</b></h2>

                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-s" /><i className="fas fa-lg fa-1" /></span> <b>Status 1:</b> {bookingData.partner_current_status}</li>
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-s" /><i className="fas fa-lg fa-2" /></span> <b>Status 2:</b> {bookingData.partner_internal_status}</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                    <div className="card bg-light d-flex flex-fill">
                                        <div className="card-header text-muted border-bottom-0">
                                            All Remarks
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2 className="lead"><b>{LABEL_JOB + " Remarks"}</b></h2>
                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-comment" /></span> <b>Creation Remarks:</b> {bookingData.remarks}</li>
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-calendar" /></span> <b>Reschedule Reason:</b> {bookingData.reschedule_reason?.reason}</li>
                                                        <li  style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-xmark" /></span> <b>Admin / SC Closing Remarks:</b>  {bookingData.admin_closing_remarks}/ {bookingData.sc_closing_remarks}</li>
                                                        <li style={{ paddingBottom: "10px" }}><span className="fa-li"><i className="fas fa-lg fa-check" /></span> <b>Cancellation Reason:</b> {bookingData.cancellation_reason?.reason}</li>



                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* /.card-body */}

                        {/* /.card-footer */}
                    
                    {/* /.card */}
                </section>
        </>
    )
}

export default BasicDetailsTab;