import React, { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";

const PrintAddresses = React.forwardRef(({ addresses }, ref) => (
  <div ref={ref} style={{ padding: "10px" }}>
    {addresses.map((row, index) => (
      <div
        key={index}
        style={{
          display: "inline-block",
          height: "95%",
          float: "left",
          border: "1px solid #ccc",
          marginLeft: "10px",
          marginTop: "10px",
          width: "320px",
          padding: "8px",
        }}
      >
        <div
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            width: "300px",
          }}
        >
          <div
            style={{
              verticalAlign: "middle",
              display: "inline",
              fontWeight: "bold",
              fontSize: "20px",
              marginLeft: "10px",
            }}
          >
            Service Center
          </div>
        </div>
        <div style={{ marginLeft: "8px" }}>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "5px" }}>
            {row.sf_company_name}
          </p>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "1px" }}>
            C/o - {row.poc_name}
          </p>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "2px" }}>
            Address - {row.sf_address},  {row.sf_city}, Pincode: {row.sf_pincode}, {row.sf_state}
          </p>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "1px" }}>
            Phone - {row.poc_mobile_number}
          </p>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "1px" }}>
            Gst Number - {row.sf_gst_number}
          </p>
          <br/>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "1px" }}>
            Booking Id - {row.booking_id}
          </p>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "1px" }}>
            Model Number - {row.model_number}
          </p>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "1px" }}>
            Part Name - {row.part_name}
          </p>
          <p style={{ maxWidth: "280px", wordWrap: "break-word", marginBottom: "1px", marginTop: "1px" }}>
            Part Number - {row.part_number}
          </p>
        </div>
      </div>
    ))}
  </div>
));

const PrintAddressesComponent = ({ selectedAddresses, setSelectedAddresses, setVisiblePrint }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setSelectedAddresses([]);
      setVisiblePrint(false)
    },
    onBeforeGetContent: () => {
      return componentRef.current;
    }
  });

  useEffect(() => {
    handlePrint();
  }, []); // Empty dependency array ensures this effect runs only once, on mount

  return (
    <div>
      <PrintAddresses ref={componentRef} addresses={selectedAddresses} />
    </div>
  );
};

export default PrintAddressesComponent;
