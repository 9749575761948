import React, { useState } from 'react'
import EstimatePending from './partlistTab/EstimatePending';
import MainBody from '../../../component/MainBody';
import PartOrderList from './partlistTab/PartOrderList';
import PartInTransit from './partlistTab/PartInTransit';
import PartDelivered from './partlistTab/PartDelivered';
import DefectivePartPendingOnSF from './partlistTab/DefectivePartPendingOnSF';
import PartsOnApproval from './partlistTab/PartsOnApproval';
import DefectiveDispatchedBySF from './partlistTab/DefectiveDispatchedBySF';
import DefectivePartToBeSendToPartner from './partlistTab/DefectivePartToBeSendToPartner';
import ReverseMslTobeDispatch from './partlistTab/ReverseMslTobeDispatch';

const PartOrderedList = () => {
    const [reloadList, setReload] = useState(true);
    const [breadcrumbSubTitle, setBreadcrumbSubTitle] = useState("Parts On Approval");
  return (
    <div>
        <MainBody
        breadcrumbTitle="Spare Parts"
        breadcrumbSubTitle={breadcrumbSubTitle}
        navButton=""
        title={""}
        component={
            <>
            <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">
                       <li className="nav-item">
                          <a
                            className=" active nav-link"
                            href="#partsonapproval"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Parts On Approval");
                            }}
                          >
                            Parts On Approval
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#estimate"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Estimate Given");
                            }}
                          >
                            Estimate On Approval
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#partordered"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Part Ordered");
                            }}
                          >
                            Part Ordered
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#partintransit"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Part In Transit");
                            }}
                          >
                            Part In Transit
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#partdelivered"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Part Delivered");
                            }}
                          >
                            Part Delivered
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#reverse_challan"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Defective/ Ok To Be Dispatched By ASC");
                            }}
                          >
                            Defective/ Ok To Be Dispatched By ASC
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#dispatchedbyasc"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Defective/ Ok Dispatched By ASC");
                            }}
                          >
                            Defective/ Ok Dispatched By ASC
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#tobesendtopartner"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Reverse Part To Be Send To Partner");
                            }}
                          >
                            Reverse Part To Be Send To Partner (Challan Not Generated)
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#tobesendtopartnerchallangented"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Reverse Part To Be Send To Partner");
                            }}
                          >
                            Reverse Part To Be Send To Partner (Challan Generated)
                          </a>
                        </li>
                        
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">

                      <div className="active tab-pane" id="partsonapproval">
                        <PartsOnApproval 
                         reloadList={reloadList}
                         setReload={setReload} />
                        </div>

                        <div className=" tab-pane" id="estimate">
                        <EstimatePending 
                         reloadList={reloadList}
                         setReload={setReload} />
                        </div>
                        <div className="tab-pane" id="partordered">
                        <PartOrderList 
                         reloadList={reloadList}
                         setReload={setReload}/>
                        </div>
                        <div className=" tab-pane" id="partintransit">
                        <PartInTransit 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        <div className=" tab-pane" id="partdelivered">
                        <PartDelivered 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        <div className=" tab-pane" id="reverse_challan">
                        <DefectivePartPendingOnSF 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        <div className=" tab-pane" id="dispatchedbyasc">
                        <DefectiveDispatchedBySF 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        <div className=" tab-pane" id="tobesendtopartner">
                        <DefectivePartToBeSendToPartner 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        <div className=" tab-pane" id="tobesendtopartnerchallangented">
                        <ReverseMslTobeDispatch 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
            </>
        }
        />
    </div>
  )
}

export default PartOrderedList