import React from "react";
import { Link } from "react-router-dom";
import MyDataTable from "../../../../component/MyDataTable";
import { openSignUrl } from "../../../../utils/HelperFunction";

const ReverseMslTobeDispatch = ({
  reloadList,
  setReload,
}) => {


  // Columns for the data table
  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Partner",
      selector: (row) => row.receiver_patner.display_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Partner Gst Number</div>,
      selector: (row) => row.msl_to_gst_details.gst_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">From Gst Number</div>,
      selector: (row) => row.msl_from_gst_details.gst_number,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Msl</div>,
      selector: (row) => row.msl_id,
      cell: (row) => (
        <Link
          to={"javascript:void(0)"}
          onClick={() => openSignUrl(row.main_file)}
        >
          {row.msl_id}
        </Link>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Msl Date</div>,
      selector: (row) => new Date(row.msl_date).toDateString(),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Quantity</div>,
      selector: (row) => row.quantity,
      wrap: true,
    },
    {
      name: "Basic Amount",
      selector: (row) => row.taxable_value,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Gst Amount</div>,
      selector: (row) => row.gst_amount,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Challan/Invoice</div>,
      selector: (row) => Number(row.is_invoice)  === 1?  "Invoice" : "Challan",
      wrap: true,
    },
    {
      name: <div className="word-wrap">Rejection Remarks</div>,
      selector: (row) =>
        row.rejection_remarks_for_wh,
      wrap: true,
  },
  {
    name: <div className="word-wrap">Rejection Date</div>,
    selector: (row) =>
      row.rejection_date_for_wh ? new Date(row.rejection_date_for_wh).toDateString(): '',
    wrap: true,
    
  },

  ].filter(Boolean);

  const conditionalRowStyles = [{
    when: row => row.rejection_date_for_sf,
    style: {
      color: 'red',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }]

  return (
    <div>
        <>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <MyDataTable
                    reloadList={reloadList}
                    columns={columns}
                    getDataListURL={`getreverchallantobedispatchfromwhTopartner?getall=true`}
                    setReload={setReload}
                    exportName={"sparelist"}
                    conditionalRowStyles={conditionalRowStyles}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
    </div>
  );
};

export default ReverseMslTobeDispatch;
