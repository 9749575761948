import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MainBody from "../../../../component/MainBody";
import MyDataTable from "../../../../component/MyDataTable";
import Switch from "../../../../component/Switch";
import Select from "react-select";
import httpRequest from "../../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Model = () => {
  const [reloadList, setReload] = useState(true);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isOpenForm, setOpenForm] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [partnerlist, setPartnerlist] = useState([]);
  const [appliancelist, setApplianceList] = useState([]);

  useEffect(() => {
    (async () => {
      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );
      console.log(partners);
      if (partners.status === true && partners.data) {
        setPartnerlist(partners.data["rows"]);
      }

      const listdata = await httpRequest(
        "getappliancelist?isactive=1",
        {},
        "GET"
      );
      if (listdata.status === true && listdata.data) {
        setApplianceList(listdata.data["rows"]);
      }
    })();
  }, []);

  const updateHandler = (row) => {
    setFormData({
        ...row, partner_id: row.partner_id
    })

    setOpenForm(true);
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Partner",
      selector: (row) => row.partner.display_name,
      cell: (row) => <div className="word-wrap">{row.partner.display_name}</div>,
    },
    {
        name: "Appliance",
        selector: (row) => row.appliance.appliance_name,
    },
    {
      name: "Model Number",
      selector: (row) => row.model_number,
      cell: (row) => <div className="word-wrap">{row.model_number}</div>,
    },
    {
      name: "Description",
      selector: (row) => row.model_description,
      cell: (row) => <div className="word-wrap">{row.model_description}</div>,
    },
    {
      name: "Is Replacement",
      selector: (row) => (Number(row.is_replacement) === 1 ? "Yes" : "No"),
    },
    {
      name: "Status",
      selector: (row) =>
        Number(row.is_active) === 1 ? "Activated" : "DeActivated",
        cell: (row) => (
        <Switch
          isOn={Number(row.is_active) === 1 ? true : false}
          id={row.id}
          handleToggle={(e) => {
            updateToggleHandler(row.id, e.target.checked);
          }}
          onColor="#EF476F"
        />
      ),
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
    {
      name: "Update",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            updateHandler(row);
          }}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  const updateToggleHandler = async (id, value) => {
    try {
      setReload(false);
      let postData = {
        id,
        is_active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updatemodelstatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating the status.");
    }
  };

  const closeForm = () => {
    setOpenForm(false);
  };

  const validateForm = (value) => {
    const errors = {};
    if (!value.partner_id) {
        errors.partner_id = "Partner is Required";
    }
    if (!value.appliance_id) {
      errors.appliance_id = "Appliance is Required";
    }

    if (!value.model_number) {
      errors.model_number = "Model Number is Required";
    }

    if (!value.model_description) {
      errors.model_description = "Model Description is Required";
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setSubmit(false);
      (async () => {
        const data = await httpRequest("addupdatemodel", formData, "POST");

        if (data.status === true && data.message) {
          setReload(true);

          toast.success(data.message);
          setFormData({});
          closeForm();
        } else {
          toast.success(data.message);
        }
      })();
    }
    //eslint-disable-next-line
  }, [formErrors, isSubmit]);

  const formAddUpdate = () => {
    return (
      <div className="form-overlay">
        <div className="form-container">
          <div className="form-content">
            <form id="assignPartner" onSubmit={onSubmitHandler}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card" style={{ marginBottom: "0px" }}>
                    <div className="card-header">
                      <h3 className="card-title">
                        {formData.id ? "Update  Model" : "Add Model"}
                      </h3>
                      <button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={closeForm}
                        style={{ float: "right" }}
                      >
                        X
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Partner *
                              <span style={{ color: "red" }}>
                                {formErrors.partner_id}
                              </span>
                            </label>
                            <Select
                              defaultValue={formData?.partner?.display_name ? { label: formData.partner.display_name, value: formData.patner_id } : undefined}
                              name="partner_id"
                              options={partnerlist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  partner_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Appliance Name *
                              <span style={{ color: "red" }}>
                                {formErrors.appliance_id}
                              </span>
                            </label>
                            <Select
                              defaultValue={
                                formData?.appliance?.appliance_name
                                  ? {
                                      label: formData.appliance.appliance_name,
                                      value: formData.appliance_id,
                                    }
                                  : undefined
                              }
                              name="appliance_id"
                              options={appliancelist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  appliance_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Model Number *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.model_number}
                              </span>
                            </label>
                            <input
                              type="text"
                              defaultValue={formData.model_number}
                              name="model_number"
                              className={
                                !formErrors.model_number
                                  ? "form-control"
                                  : "is-invalid form-control"
                              }
                              placeholder="Enter Model Number"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value
                                    .replace(/['"]+/g, "")
                                    .toUpperCase(),
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Model Description *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.model_description}
                              </span>
                            </label>
                            <input
                              type="text"
                              defaultValue={formData.model_description}
                              name="model_description"
                              placeholder="Enter Model Description"
                              className={
                                !formErrors.model_description
                                  ? "form-control"
                                  : "is-invalid form-control"
                              }
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value
                                    .replace(/['"]+/g, "")
                                    .toUpperCase(),
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>Full Replacement</label>
                            <input
                              type="checkbox"
                              checked={formData.is_replacement}
                              className={
                                !formErrors.model_description
                                  ? "form-control col-md-2"
                                  : "is-invalid form-control col-md-2"
                              }
                              id="checkboxPrimary1"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                });
                              }}
                              name="is_replacement"
                            />
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-center"
                      style={{
                        marginBottom: "60px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary col-md-12"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle="Model"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Model List</h3>
                        <Link
                          className="btn btn-sm btn-primary float-right"
                          to={"#"}
                          onClick={() => {
                            setOpenForm(true);
                            setFormData({});
                          }}
                        >
                          Add New Model
                        </Link>
                      </div>
                      <div className="card-body">
                        <MyDataTable
                          reloadList={reloadList}
                          columns={columns}
                          getDataListURL={"getmodellist?isactive=all"}
                          setReload={setReload}
                          exportName={"partnerlist"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isOpenForm && formAddUpdate()}
            </section>
          </>
        }
      />
    </div>
  );
};

export default Model;
