import React, { useState, useEffect } from "react";
import { isValidEmail, isValidMobile } from "../../../utils/HelperFunction";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ONLY_RM_ASM_ACTION_PERMISSION } from "../../../constant/action_permission";

export default function SFContactPerson({ sfdetails, SetSfDetails, roleId }) {
  const [formData, setFormData] = useState({ sf_id: sfdetails.id });
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [pocFormData, setPOCFormData] = useState({ sf_id: sfdetails.id });
  const [pocFormErros, setPocErrors] = useState({});
  const [ispSubmit, setpSubmit] = useState(false);

  const submitOwnerDetails = (e) => {
    e.preventDefault();

    setSubmit(false);
    setFormErrors(validateOwnerFrom(formData));
    setSubmit(true);
  };

  const submitPocDetails = (e) => {
    e.preventDefault();
    setpSubmit(false);
    setPocErrors(validatePocFrom(formData));
    setpSubmit(true);
  };

  const validateOwnerFrom = (value) => {
    let errors = {};
    if (!value.name) {
      errors.name = "Name is Required";
    }
    if (!value.mobile_number) {
      errors.mobile_number = "Mobile Number is Required";
    } else if (!isValidMobile(value.mobile_number)) {
      errors.mobile_number = "Mobile Number is not valid";
    }

    if (!value.email) {
      errors.email = "Email ID is Required";
    } else if (!isValidEmail(value.email)) {
      errors.email = "Enter Valid Email ID";
    }
    return errors;
  };

  const validatePocFrom = (value) => {
    let errors = {};
    if (!value.name) {
      errors.name = "Name is Required";
    }
    if (!value.mobile_number) {
      errors.mobile_number = "Mobile Number is Required";
    } else if (!isValidMobile(value.mobile_number)) {
      errors.mobile_number = "Mobile Number is not valid";
    }

    if (!value.email) {
      errors.email = "Email ID is Required";
    } else if (!isValidEmail(value.email)) {
      errors.email = "Enter Valid Email ID";
    }
    return errors;
  };

  useEffect(() => {
    (async () => {
      if (sfdetails.id) {
        setPOCFormData({
          ...pocFormData,
          sf_id: sfdetails.id,
          name: sfdetails.poc_details?.name,
          mobile_number: sfdetails.poc_details?.mobile_number,
          email: sfdetails.poc_details?.email,
          poc_id: sfdetails.poc_id,
        });

        setFormData({
          ...formData,
          sf_id: sfdetails.id,
          name: sfdetails.owner_details?.name,
          mobile_number: sfdetails.owner_details?.mobile_number,
          email: sfdetails.owner_details?.email,
          owner_id: sfdetails.owner_id,
        });
      }
    })();
  }, [sfdetails]);

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest(
          "updatesfownerdetails",
          formData,
          "POST"
        );

        if (data.status === true && data.message) {
          toast.success(data.message);
          SetSfDetails(data.data);
        } else {
          toast.error(data.message);
        }
        setpSubmit(false);
      }
    })();
  }, [isSubmit, formErrors]);

  useEffect(() => {
    (async () => {
      if (Object.keys(pocFormErros).length === 0 && ispSubmit) {
        const data = await httpRequest(
          "updatesfpocdetails",
          pocFormData,
          "POST"
        );
        if (data.status === true && data.message) {
          toast.success(data.message);
          SetSfDetails(data.data);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [ispSubmit, pocFormErros]);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <form id="updateContactPerson" onSubmit={submitOwnerDetails}>
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Owner Details</h3>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Name *{" "}
                        <span style={{ color: "red" }}>{formErrors.name}</span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        id="owner_name"
                        placeholder="Enter Name"
                        defaultValue={formData.name}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Mobile Number *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.mobile_number}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile_number"
                        placeholder="Enter Mobile Number"
                        defaultValue={formData.mobile_number}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            mobile_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Email ID *{" "}
                        <span style={{ color: "red" }}>{formErrors.email}</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email_id"
                        placeholder="Enter Email ID"
                        defaultValue={formData.email}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && (
                <div className="card-footer">
                  <div className="row">
                    <span style={{ color: "red" }}>{formErrors.mandatory}</span>
                    <br></br>
                    <input
                      type="submit"
                      className="form-control btn btn-primary"
                      value={"Submit"}
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <form id="updatePOC" onSubmit={submitPocDetails}>
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">POC Details</h3>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Name *{" "}
                        <span style={{ color: "red" }}>
                          {pocFormErros.name}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        id="owner_name"
                        placeholder="Enter Name"
                        defaultValue={pocFormData.name}
                        onChange={(e) => {
                          setPOCFormData({
                            ...pocFormData,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Mobile Number *{" "}
                        <span style={{ color: "red" }}>
                          {pocFormErros.mobile_number}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile_number"
                        placeholder="Enter Mobile Number"
                        defaultValue={pocFormData.mobile_number}
                        onChange={(e) => {
                          setPOCFormData({
                            ...pocFormData,
                            mobile_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Email ID *{" "}
                        <span style={{ color: "red" }}>
                          {pocFormErros.email}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email_id"
                        placeholder="Enter Email ID"
                        defaultValue={pocFormData.email}
                        onChange={(e) => {
                          setPOCFormData({
                            ...pocFormData,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && (
                <>
                  <div className="card-footer">
                    <div className="row">
                      <span style={{ color: "red" }}>
                        {pocFormErros.mandatory}
                      </span>
                      <br></br>
                      <input
                        type="submit"
                        className="form-control btn btn-primary"
                        value={"Submit"}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
