import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import MyDataTable from "../../../component/MyDataTable";
import { LABEL_APPLIACE_NAME } from "../../../constant/label_constant";
import MainBody from "../../../component/MainBody";

const MSLPartList = () => {
  const { msl_pid } = useParams();
  const [msl_id, setMsl_id] = useState({});
  const [reloadList, setReload] = useState(true);

  const columns = [
    {
      name: LABEL_APPLIACE_NAME,
      selector: (row) => row.partmaster_list.appliance["appliance_name"],
    },
    {
      name: "Part Number",
      selector: (row) => row.partmaster_list.part_number,
      cell: (row) => (
        <NavLink
          to={
            "/getpartitem/" + row.id + "/" + row.partmaster_list.part_number
          }
          className="nav-link"
        >
          {row.partmaster_list.part_number}
        </NavLink>
      ),
    },
    {
      name: "Part Name",
      selector: (row) => row.partmaster_list.part_name,
      cell: (row) => row.partmaster_list.part_name,
    },
    {
      name: "HSN Code",
      selector: (row) => row.partmaster_list.hsn_code,
    },
    {
      name: "Baisc Rate",
      selector: (row) => row.rate,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          {setMsl_id(row.msl_detail.msl_id)}
          {row.quantity}
        </>
      ),
    },
    {
      name: "Taxable Value",
      selector: (row) => row.taxable_value,
    },
    {
      name: "GST Rate",
      selector: (row) =>
        Number(row.cgst_tax_rate) +
        Number(row.sgst_tax_rate) +
        Number(row.igst_tax_rate),
    },
    {
      name: "Total Amount",
      selector: (row) => row.total_amount,
    },
  ];

  return (
    <>
      <MainBody
        breadcrumbTitle="Msl Part Details"
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">
                        <b>{"MSL ID -" + msl_id}</b>
                      </h3>
                    </div>

                    <MyDataTable
                      reloadList={reloadList}
                      columns={columns}
                      setReload={setReload}
                      getDataListURL={`getmslpartdetails?msl_pid=${msl_pid}`}
                    />
                  </div>
                </div>

                <div className="col-md-6"></div>
              </div>
            </div>
          </section>
        }
      />
    </>
  );
};

export default MSLPartList;
