import React, { useState, useEffect } from "react";
import MainBody from "../../../component/MainBody";
import { getdropdownList } from "../../../utils/HelperFunction";
import httpRequest from "../../../utils/Http";
import Select from "react-select";
import MyDataTable from "../../../component/MyDataTable";
import { useSelector } from "react-redux";
import { PARTNER_TYPE } from "../../../constant/status_constant";

const WarehouseStock = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [warehouselist, setWarehouselist] = useState([]);
  const [applianceList, setApplianceList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({warehouse_id: '', appliance_id: ''});
  const [isSubmit, setSubmit] = useState(false);
  const [reloadList, setReload] = useState(false);
  

  useEffect(() => {
    (async () => {
      const warehouse = await httpRequest("getcwhdropdownlist", {}, "GET");
      if (warehouse.status === true && warehouse.data) {
        setWarehouselist(warehouse.data);
        if(warehouse.data.length == 1){
           setFormData({...formData, warehouse_id: warehouse.data[0]['value']})
           setReload(true)
        }
      }

      if(Number(loggedInUser.entity_type) === PARTNER_TYPE){
        getdropdownList(
          setApplianceList,
          `appliancelistmapping?is_active=1&partner_id=${loggedInUser.entity_id}`
        );
      } else {
        getdropdownList(
          setApplianceList,
          `getappliancelist?is_active=1`
        );
      }

    })();
    //eslint-disable-next-line
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    if (!value.warehouse_id) {
      errors.warehouse_id = "Central Warehouse is Required";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        setReload(true);
        setSubmit(false);
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    }, 
    {
      name: <div className="word-wrap">Display Name</div>,
      selector:(row) => row.display_name,
      wrap: true
  },
    {
        name: "Appliance",
        selector:(row) => row.appliance_name,
        wrap: true
    },
    {
        name: "Part Type",
        selector: (row) => row.part_type,
        wrap: true

    },
    {
        name: "Part Name",
        selector: (row) => row.part_name,
        wrap: true

    },
    {
        name: <div className="word-wrap">Part Number</div>,
        selector: (row) => row.part_number,
        wrap: true

    },
    {
        name: "HSN Code",
        selector: (row) => row.hsn_code,
        wrap: true
    },
    {
        name: <div className="word-wrap">GST Rate (%)</div>,
        selector: (row) => row.gst_rate,
        wrap: true
    },
    {
        name: <div className="word-wrap">Total Buying Price</div>,
        selector: (row) => (row.basic_rate * (1 + row.gst_rate / 100)).toFixed(2),
        wrap: true

    },
    Number(loggedInUser.entity_type) == 1 && {
        name: <div className="word-wrap">SF Buying Price</div>,
        selector: (row) => ((row.basic_rate * (1 + row.gst_rate / 100)) * (1 + (row.partner_oow_margin) / 100)).toFixed(2),
        wrap: true

    },
    Number(loggedInUser.entity_type) == 1 && {
        name: <div className="word-wrap">Customer Price</div>,
        selector: (row) => ((row.basic_rate * (1 + row.gst_rate / 100)) * (1 + (row.sf_oow_margin + row.partner_oow_margin) / 100)).toFixed(2),
        wrap: true

    },
    {
        name: <div className="word-wrap">Stock Quantity</div>,
        selector: (row) => row.stock,
    }
].filter(Boolean);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Warehouse Stock"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
            <>
             <section className="content">
              <div className="container-fluid">
                <form
                  id="mslintranist"
                  onSubmit={onSubmitHandler}
                  encType="multipart/form-data"
                >
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Filter</h3>

                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Central Warehouse *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.warehouse_id}
                              </span>
                            </label>
                            <Select
                              name="warehouse_id"
                              value={
                                warehouselist.find(
                                  (option) =>
                                    option.value === formData.warehouse_id
                                ) || null
                              }
                              options={warehouselist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  warehouse_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Appliance {" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_id}
                              </span>
                            </label>
                            <Select
                              name="appliance_id"
                              value={
                                applianceList.find(
                                  (option) =>
                                    option.value === formData.appliance_id
                                ) || null
                              }
                              options={applianceList}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  appliance_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>
                              .{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_file}
                              </span>
                            </label>
                            <input
                              type="submit"
                              className="form-control btn btn-primary"
                              value={"Submit"}
                            />
                          </div>

                          {/* /.form-group */}
                        </div>
                        {/* /.col */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Warehouse Stock</h3>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={
                          `getwarehousestock?entity_id=${formData.warehouse_id}&appliance_id=${formData.appliance_id}&entity_type=4`
                        }
                        setReload={setReload}
                        exportName={"whstocklist"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            </>
        }
      />
    </div>
  );
};

export default WarehouseStock;
