import React, { useEffect, useRef, useState } from "react";
import MainBody from "../../../component/MainBody";
import { getdropdownList, openSignUrl } from "../../../utils/HelperFunction";
import httpRequest from "../../../utils/Http";
import { useSelector } from "react-redux";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import MyDataTable from "../../../component/MyDataTable";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  PARTNER_TYPE,
  PENDING_STATUS,
} from "../../../constant/status_constant";
import moment from "moment";

const BookingSummary = () => {
  const { filter } = useParams();
  const buttonRef = useRef(null);
  const loggedInUser = useSelector((state) => state.authReducer.currentUser);

  const [stateList, setStateList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [applianceList, setApplianceList] = useState([]);
  const [callTypeList, setCallTypeList] = useState([]);
  const [formData, setFormData] = useState({});
  const [reloadList, setReload] = useState(false);
  const [fileHistory, setFileHistory] = useState(true);
  const [closedDateRange, setClosedDateRange] = useState([]);
  const [bookingDateRange, setBookingDateRange] = useState([]);
  const [registrationdDate, setRegistrationDateRange] = useState([]);
  const formErrors = {}

  useEffect(() => {
    

    if(filter){
      // Get the current date
      const currentDate = new Date();
      // Get the first date of the current month
      const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

      switch(filter){
        case 1:
        case '1':
              // Set the date range from the 1st of the current month to the current date
            setRegistrationDateRange([firstDateOfMonth, currentDate]);
            if (buttonRef.current) {
              // Trigger the button click using the ref
              buttonRef.current.click();
            }
            break;
        case 2:
        case '2':
          setFormData((prev) => ({ ...prev, current_status: PENDING_STATUS }));
          if (buttonRef.current) {
            // Trigger the button click using the ref
            buttonRef.current.click();
          }
            break
        case '3':
        case 3:
          setFormData((prev) => ({ ...prev, current_status: COMPLETED_STATUS }));
          setClosedDateRange([firstDateOfMonth, currentDate])
          if (buttonRef.current) {
            // Trigger the button click using the ref
            buttonRef.current.click();
          }
            break
        case '4':
        case 4:
            setFormData((prev) => ({ ...prev, current_status: CANCELLED_STATUS }));
            setClosedDateRange([firstDateOfMonth, currentDate])
            if (buttonRef.current) {
              // Trigger the button click using the ref
              buttonRef.current.click();
            }
              break
      }
    }
  
  }, [filter]);
  

  const closedStatusList = [
    { label: COMPLETED_STATUS, value: COMPLETED_STATUS },
    { label: CANCELLED_STATUS, value: CANCELLED_STATUS },
    { label: PENDING_STATUS, value: PENDING_STATUS },
  ];

  const isUpcountryList = [
    { label: "Local", value: 0 },
    { label: "Upcountry", value: 1 },
  ];

  useEffect(() => {
    if (Number(loggedInUser.entity_type) === PARTNER_TYPE) {
      setFormData((prev) => ({
        ...prev,
        partner_id: loggedInUser.entity_id,
      }));
    }
  }, [loggedInUser]);

  useEffect(() => {
 console.log(formData)
  }, [formData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stateRes, partnerRes, applianceRes] = await Promise.all([
          httpRequest("getallstatelist", {}, "GET"),
          httpRequest("getpartnerlist?is_active=1", {}, "GET"),
          httpRequest("getappliancelist?isactive=1", {}, "GET"),
        ]);

        if (stateRes.status) setStateList(stateRes.message || []);
        if (partnerRes.status) setPartnerList(partnerRes.data?.rows || []);
        if (applianceRes.status) setApplianceList(applianceRes.data?.rows || []);

        getdropdownList(setCallTypeList, "getcalltypegrouplist?isactive=1");
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, closedDateRange: closedDateRange, bookingDateRange: bookingDateRange, registrationdDate: registrationdDate }));
  }, [closedDateRange, bookingDateRange, registrationdDate]);

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "File Name",
      selector: (row) => row.file_name,
      cell: (row) => (
        row.file_path ? (
        <Link
          to="#"
          className="btn-link text-primary"
          onClick={() => openSignUrl(row.file_path)}
        >
          {row.file_name}
        </Link>
        ) :(
            <>
            {row.file_name}
            </>
        )
        
      ),
    },
    {
      name: "Agent Name",
      selector: (row) => row.agent_detail.name
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Create Date",
      selector: (row) => moment(row.createdAt).format('ddd MMM DD YYYY HH:mm:ss'),
    },
  ];

  useEffect(() => {
    if (reloadList) {
      const fetchData = async () => {
        try {
          const response = await httpRequest("getbookingsummaryreports", formData, "POST");
          if (response.status) {
            setFileHistory(true);
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          console.error("Error fetching completed TAT report:", error);
          toast.error(error.message);
        } finally {
          setReload(false);
        }
      };

      fetchData();
    }
  }, [reloadList, formData]);

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value ? value.value : null }));
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle="Report"
        breadcrumbSubTitle="Booking Summary"
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <section className="col-lg-12 connectedSortable ui-sortable">
                    <div className="card">
                      <div className="card-header ui-sortable-handle" style={{ cursor: "move" }}>
                        <h3 className="card-title">
                          <i className="fa-solid fa-file-export mr-1"></i>
                          Booking Summary Reports
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="tab-content p-0">
                          <div className="card-header ui-sortable-handle" style={{ cursor: "move" }}>
                            <div className="row">
                              {Number(loggedInUser.entity_type) !== PARTNER_TYPE && (
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Partner{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.partner_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="partner_id"
                                      value={partnerList.find(option => option.value === formData.partner_id) || null}
                                      options={partnerList}
                                      onChange={(val) => handleChange("partner_id", val)}
                                      isClearable
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    Appliance{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.appliance_id}
                                    </span>
                                  </label>
                                  <Select
                                    name="appliance_id"
                                    value={applianceList.find(option => option.value === formData.appliance_id) || null}
                                    options={applianceList}
                                    onChange={(val) => handleChange("appliance_id", val)}
                                    isClearable
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>
                                    Call Type{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.calltype_groupid}
                                    </span>
                                  </label>
                                  <Select
                                    name="calltype"
                                    value={callTypeList.find(option => option.value === formData.calltype_groupid) || null}
                                    options={callTypeList}
                                    onChange={(val) => handleChange("calltype_groupid", val)}
                                    isClearable
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>
                                    Upcountry{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.is_upcountry}
                                    </span>
                                  </label>
                                  <Select
                                    name="is_upcountry"
                                    value={isUpcountryList.find(option => option.value === formData.is_upcountry) || null}
                                    options={isUpcountryList}
                                    onChange={(val) => handleChange("is_upcountry", val)}
                                    isClearable
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label>
                                    Status{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.current_status}
                                    </span>
                                  </label>
                                  <Select
                                    name="current_status"
                                    value={closedStatusList.find(option => option.value === formData.current_status) || null}
                                    options={closedStatusList}
                                    onChange={(val) => handleChange("current_status", val)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    State{" "}

                                  </label>
                                  <Select
                                    name="state_id"
                                    value={
                                      stateList.find(
                                        (option) =>
                                          option.value === formData.state_id
                                      ) || null
                                    }
                                    options={stateList}
                                    onChange={(val) => {
                                      setFormData({
                                        ...formData,
                                        state_id: val ? val.value : null, // Handle case when value is cleared
                                      });
                                    }}
                                    isClearable // This allows users to clear the selection
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    Closed Date{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.closedDateRange}
                                    </span>
                                  </label>
                                  <Flatpickr
                                    placeholder="Select Closed Date"
                                    value={closedDateRange}
                                    style={{ backgroundColor: "#fff" }}
                                    onChange={(dates) => setClosedDateRange(dates)}
                                    className="form-control"
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    First Booking Date{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.bookingDateRange}
                                    </span>
                                  </label>
                                  <Flatpickr
                                    placeholder="Select First Booking Date"
                                    value={bookingDateRange}
                                    style={{ backgroundColor: "#fff" }}
                                    onChange={(dates) => setBookingDateRange(dates)}
                                    className="form-control"
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    Registration Date{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.registrationdDate}
                                    </span>
                                  </label>
                                  <Flatpickr
                                    placeholder="Select Registration Date"
                                    value={registrationdDate}
                                    style={{ backgroundColor: "#fff" }}
                                    onChange={(dates) => setRegistrationDateRange(dates)}
                                    className="form-control"
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    .{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.submit}
                                    </span>
                                  </label>
                                  <input
                                    name="submit"
                                    id="submit"
                                    type="submit"
                                    className="form-control btn btn-primary"
                                    ref={buttonRef}
                                    onClick={() => setReload(true)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">File Requested</h3>
                      </div>
                      <MyDataTable
                        reloadList={fileHistory}
                        columns={columns}
                        getDataListURL="getfileuploadhistory?type=BOOKING-SUMMARY"
                        setReload={setFileHistory}
                        exportName="summaryreport"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default BookingSummary;
