export const LABEL_JOB = "Booking";
export const LABEL_JOB_DETAILS = LABEL_JOB + " Details";
export const LABEL_JOB_NUMBER = LABEL_JOB + " No";
export const LABEL_JOB_DATE = LABEL_JOB + " Date";
export const LABEL_APPLIANCE = "Appliance";
export const LABEL_APPLIANCE_DETAILS = LABEL_APPLIANCE + " Details";
export const LABEL_JOB_CARD = LABEL_JOB + " Card";
export const LABEL_JOB_STATUS = LABEL_JOB + " Status";
export const LABEL_APPLIACE_NAME = LABEL_APPLIANCE + " Name";
export const LABEL_SERVICE_CATEGORY = "Service Category";
export const LABEL_CALL_CHARGES = "Call Charges";
export const LABEL_BRAND = "Brand"
