import React, { useEffect, useState } from "react";
import MainBody from "../../../component/MainBody";
import Select from "react-select";
import { LABEL_JOB } from "../../../constant/label_constant";
import { toast } from "react-toastify";
import { UploadFileToS3 } from "../../../utils/S3File";
import httpRequest from "../../../utils/Http";

const AddMiscCharge = () => {
  const [charges, setCharges] = useState([
    { service: "Service", description: "", sc_charge: 0, partner_charge: 0 },
  ]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChargeChange = (index, field, value) => {
    const updatedCharges = charges.map((charge, idx) =>
      idx === index ? { ...charge, [field]: value } : charge
    );
    setCharges(updatedCharges);
  };

  const handleAddChargeRow = () => {
    setCharges([
      ...charges,
      { service: "Service", description: "", sc_charge: 0, partner_charge: 0 },
    ]);
  };

  const handleRemoveChargeRow = (index) => {
    const updatedCharges = charges.filter((_, idx) => idx !== index);
    if (updatedCharges.length === 0) {
      toast.error("At least one charge is required");
    } else {
      setCharges(updatedCharges);
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    const errors = validateFrom(formData);
    setFormErrors(errors);
    setSubmit(true);

  };

  const validateFrom = (data) => {
    const errors = {};

    if (!data.booking_id) {
      errors.booking_id = "Booking ID is required";
    }
    if (!data.approval_file) {
      errors.approval_file = "Approval file is required";
    }
    charges.forEach((charge, index) => {
      if (!charge.service) {
        errors[`service_${index}`] = "Service/Product is required";
      }
      if (!charge.description) {
        errors[`description_${index}`] = "Description is required";
      }
      if (Number(charge.sc_charge) <= 0 && Number(charge.partner_charge) <= 0 ) {
        errors[`sc_charge_${index}`] = "ASC / Partner Charge must be greater than zero";
      }
    });

    return errors;
  };

  useEffect(() => {
    setFormData({
      ...formData,
      charges: JSON.stringify(charges),
    });
  }, [charges]);


  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let postData = formData;
        if (formData.approval_file) {
          const approval_file = await UploadFileToS3(
            "docFile",
            formData.approval_file
          );
          postData.approval_file = approval_file;
        }

        if (formData.purchase_file) {
          const purchase_file = await UploadFileToS3(
            "docFile",
            formData.purchase_file
          );
          postData.purchase_file = purchase_file;
        }

        const data = await httpRequest(
          "processaddmisccharge",
          postData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          setCharges([
            { service: "Service", description: "", sc_charge: 0, partner_charge: 0 },
          ])
          setFormData({});
          toast.success(data.message);
          document.getElementById("addmiscform").reset();
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);



  return (
    <div>
      <MainBody
        breadcrumbTitle="Add New Miscellaneous Charge"
        breadcrumbSubTitle=""
        navButton=""
        title=""
        component={
          <>
            <form id="addmiscform" onSubmit={onSubmitHandler}>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">
                            Add Miscellaneous Charge
                          </h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="charges-section">
                              {charges.map((charge, index) => (
                                <div key={index} className="charge-row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Service/Product *</label>
                                      <Select
                                        options={[
                                          { value: "Product", label: "Product" },
                                          { value: "Service", label: "Service" },
                                        ]}
                                        value={{
                                          value: charge.service,
                                          label:
                                            charge.service === "Product"
                                              ? "Product"
                                              : "Service",
                                        }}
                                        onChange={(val) =>
                                          handleChargeChange(
                                            index,
                                            "service",
                                            val.value
                                          )
                                        }
                                        className="charge-select"
                                      />
                                      <span style={{ color: "red" }}>
                                        {formErrors[`service_${index}`]}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Description *</label>
                                      <input
                                        type="text"
                                        className="form-control charge-input"
                                        placeholder="Description"
                                        value={charge.description}
                                        onChange={(e) =>
                                          handleChargeChange(
                                            index,
                                            "description",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        {formErrors[`description_${index}`]}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>ASC Payout (With TAX)</label>
                                      <input
                                        type="number"
                                        step={0.01}
                                        className="form-control charge-input"
                                        value={charge.sc_charge}
                                        onChange={(e) =>
                                          handleChargeChange(
                                            index,
                                            "sc_charge",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        {formErrors[`sc_charge_${index}`]}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Partner Payout (With TAX)</label>
                                      <input
                                        type="number"
                                        step={0.01}
                                        className="form-control charge-input"
                                        value={charge.partner_charge}
                                        onChange={(e) =>
                                          handleChargeChange(
                                            index,
                                            "partner_charge",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        {formErrors[`partner_charge_${index}`]}
                                      </span>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveChargeRow(index)}
                                  >
                                    -
                                  </button>
                                  {index === charges.length - 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleAddChargeRow}
                                    >
                                      +
                                    </button>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  {LABEL_JOB} ID *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.booking_id}
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={`${LABEL_JOB} ID`}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      booking_id: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Remarks{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.remarks}
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={"Remarks"}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      remarks: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Approval File *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.approval_file}
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      approval_file: e.target.files[0],
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Purchase File</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      purchase_file: e.target.files[0],
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <button className="btn btn-primary btn-block col-md-12">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </form>
          </>
        }
      />
    </div>
  );
};

export default AddMiscCharge;
