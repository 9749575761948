import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MyDataTable from "../../../../component/MyDataTable";
import httpRequest from "../../../../utils/Http";
import { toast } from "react-toastify";
import Select from "react-select";
import { getPartWarranty } from "../../../../utils/HelperFunction";

const GenerateChallanSendToSFTab = ({
  warehouse_id,
  reloadList,
  setFormErrors,
  formErrors,
  setReload,
}) => {
  const [formData, setFormData] = useState({ warehouse_id });
  const [isSubmit, setSubmit] = useState(false);
  const [rejectForm, setRejectForm] = useState(false);
  const [rejectData, setRejectData] = useState({});
  const [rejectErrors, setRejectErrors] = useState({});
  const [cancellist, setCancelList] = useState([]);

  const handleChargesCheckbox = (event, row) => {
    const {
      customer_name,
      requested_id,
      mobile_number,
      warranty_status,
      booking_id,
      model_number,
      model_id,
      part_id,
      part_number,
      part_name,
      requested_quantity,
      sc_id,
      partner_id,
    } = row;

    setFormData({
      warehouse_id,
      sc_id,
      partner_id,
      warranty_status,
      customer_name,
      requested_id,
      model_id,
      mobile_number,
      booking_id,
      model_number,
      part_id,
      part_number,
      part_name,
      requested_quantity,
    });

    generateChallan(event);
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="wrap-word">Spare Requested Id</div>,
      selector: (row) => row.requested_id,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Booking ID</div>,
      selector: (row) => row.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.bid)}`}>
          {row.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Partner</div>,
      selector: (row) => row.partner_name,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Service Center</div>,
      selector: (row) => row.sc_name,
      wrap: true,
    },
    {
      name: "Part Name",
      selector: (row) => `${row.part_name} / ${row.part_number}` ,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Model Number</div>,
      selector: (row) => row.model_number,
      wrap: true,
    },
    {
      name: "Warranty",
      selector: (row) => getPartWarranty(row.warranty_status),
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.requested_quantity,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Current Stock</div>,
      selector: (row) => row.stock,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Age of Request</div>,
      selector: (row) => `${row.age_of_requested} days`,
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <NavLink
            to={`/updatebooking/${btoa(row.bid)}/${btoa(1)}/${btoa(row.requested_id)}`}
            className="btn btn-sm btn-primary"
          >
            <i className="fas fa-edit" />
          </NavLink>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: "Reject",
      cell: (row) => (
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            setRejectData({ id: row.requested_id });
            setRejectForm(true);
          }}
        >
          Reject
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: "Create",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          id={"charges_checkbox" + row.requested_id}
          onClick={(e) => {
            handleChargesCheckbox(e, row);
          }}
        >
          Create
        </button>
        // <input
        //   type="checkbox"
        //   className="form-control charges_checkbox text-capitalize"
        //   name="charges_checkbox"
        //   id={"charges_checkbox" + row.requested_id}
        //   value={row.requested_id}
        //   checked={serviceChecked.includes(row.requested_id)}
        //   onChange={(e) => handleChargesCheckbox(e, row)}
        // />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);

  const generateChallan = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  const validateForm = (value) => {
    const errors = {};
    if (!value.warehouse_id) {
      errors.warehouse_id = "Central Warehouse is Required";
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!rejectData.remarks) {
      setRejectErrors({ ...rejectErrors, remarks: "Remarks is Mandatory" });
      return;
    }
    if (!rejectData.cancellation_id) {
      setRejectErrors({
        ...rejectErrors,
        cancellation_id: "Cancellation Reason is Mandatory",
      });
      return;
    }

    (async () => {
      const data = await httpRequest("rejectspareparts", rejectData, "POST");

      if (data.status === true && data.message) {
        toast.success(data.message);
        setReload(true);
        setRejectData({});
        setRejectForm(false);
      } else {
        toast.error(data.message);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      const cancel = await httpRequest(
        "getcancellationreasonlist?is_active=1&type=spareparts",
        {},
        "GET"
      );
      if (cancel.status === true && cancel.data) {
        setCancelList(cancel.data["rows"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest(
          "generatechallansendtosf",
          formData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          setReload(true);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const formReject = () => {
    return (
      <>
        <div className="form-overlay">
          <div className="form-container" style={{ maxWidth: "500px" }}>
            <div className="form-content">
              <form id="cancelSpare" onSubmit={onSubmitHandler}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card" style={{ marginBottom: "0px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Reject Spare</h3>
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={() => {
                            setRejectData({});
                            setRejectForm(false);
                          }}
                          style={{ float: "right" }}
                        >
                          X
                        </button>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Cancellation Reason *{" "}
                                <span style={{ color: "red" }}>
                                  {rejectErrors.cancellation_id}
                                </span>
                              </label>
                              <Select
                                name="cancellation_id"
                                value={
                                  cancellist.find(
                                    (option) =>
                                      option.value ===
                                      rejectData.cancellation_id
                                  ) || null
                                }
                                options={cancellist}
                                onChange={(val) => {
                                  setRejectData({
                                    ...rejectData,
                                    cancellation_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Remarks *{" "}
                                <span style={{ color: "red" }}>
                                  {rejectErrors.remarks}
                                </span>
                              </label>
                              <textarea
                                type="text"
                                name="remarks"
                                className="form-control"
                                value={rejectData.remarks}
                                placeholder="Enter Remarks"
                                onChange={(e) => {
                                  setRejectData({
                                    ...rejectData,
                                    remarks: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer text-center"
                        style={{
                          marginBottom: "60px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-primary col-md-12"
                          style={{ marginLeft: "0px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {warehouse_id && (
        <>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <MyDataTable
                    reloadList={reloadList}
                    columns={columns}
                    getDataListURL={`getPartTobeDispatchFromWarehouse?warehouse_id=${warehouse_id}`}
                    setReload={setReload}
                    exportName={"sparelist"}
                  />
                </div>
              </div>
            </div>
            {rejectForm && formReject()}
          </section>
        </>
      )}
    </div>
  );
};

export default GenerateChallanSendToSFTab;
