import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getPartWarranty, openSignUrl } from "../../../utils/HelperFunction";
import { PARTNER_TYPE } from "../../../constant/status_constant";

const SparePartsTab = ({ spareParts }) => {
  const [isDispatch, setIsDispatch] = useState(false);
  const [isDefectiveDispatch, setIsDefectiveDispatch] = useState(false);
  const [isDispatchWh, setIsDispatchWh] = useState(false);

  return (
    <div>
      {spareParts.length > 0 ? (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 d-flex align-items-stretch flex-column">
            <div className="card bg-light d-flex flex-fill">
              <div className="card-header text-muted border-bottom-0">
                Estimate Requested
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-12">
                    <table className="table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>SNo.</th>
                          <th>Requested Id</th>
                          <th>Part Type</th>
                          <th>Part Name</th>
                          <th>Part Number</th>
                          <th>Requested Quantity</th>
                          <th>Warranty Status</th>
                          <th>Partner Price</th>
                          <th>Sc Price</th>
                          <th>Customer Price</th>
                          <th>Customer Paid</th>
                          <th>Estimate Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {spareParts.map(
                          (item, index) =>
                            item.warranty_status === 2 && (
                              <tr key={index} className="text-center">
                                <td>{index + 1}</td>
                                <td>{item.id}</td>
                                <td>{item.requested_part.part_type}</td>
                                <td>{item.requested_part.part_name}</td>
                                <td>{item.requested_part.part_number}</td>
                                <td>{item.requested_quantity}</td>
                                <td>{getPartWarranty(item.warranty_status)}</td>
                                <td>{item.partner_price}</td>
                                <td>{item.sc_price}</td>
                                <td>{item.estimate_price}</td>
                                <td>{item.customer_paid}</td>
                                <td>
                                  {new Date(item.estimate_date).toDateString()}
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="card bg-light d-flex flex-fill">
              <div className="card-header text-muted border-bottom-0">
                Part Requested
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-12">
                    <table className="table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>SNo.</th>
                          <th>Requested Id</th>
                          <th>Part Type</th>
                          <th>Part Name</th>
                          <th>Part Number</th>
                          <th>Requested Quantity</th>
                          <th>Warranty Status</th>
                          <th>Status</th>
                          <th>Problem Description</th>
                          <th>Images</th>
                          <th>Part Order Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {spareParts.map((item, index) => (
                          <tr key={index} className="text-center">
                            <td>{index + 1}</td>
                            <td>{item.id}</td>
                            <td>{item.requested_part.part_type}</td>
                            <td>{item.requested_part.part_name}</td>
                            <td>{item.requested_part.part_number}</td>
                            <td>{item.requested_quantity}</td>
                            <td>{getPartWarranty(item.warranty_status)}</td>
                            <td>{item.status}</td>
                            <td>{item.problem_description}</td>
                            <td>
                              {item.spare_images.map((img, imgIndex) => (
                                <div
                                  key={`${index}-${imgIndex}`}
                                  style={{ marginBottom: "5px" }}
                                >
                                  {imgIndex + 1}.
                                  <Link
                                    to="#"
                                    onClick={() => openSignUrl(img.file_url)}
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {img.file_name}
                                  </Link>
                                </div>
                              ))}
                            </td>
                            <td>{new Date(item.createdAt).toDateString()}</td>
                            {item.shipped_part_id &&
                              isDispatch === false &&
                              setIsDispatch(true)}
                            {item.defective_challan_id &&
                              isDefectiveDispatch === false &&
                              setIsDefectiveDispatch(true)}

                            {item.defective_wh_challan_id &&
                              isDispatchWh === false &&
                              setIsDispatchWh(true)}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {isDispatch && (
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0">
                  Part Shipped to SC
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>SNo.</th>
                            <th>Requested Id</th>
                            <th>Dispatch By</th>
                            <th>Shipped Part Type</th>
                            <th>Shipped Part Name</th>
                            <th>Shipped Part Number</th>
                            <th>Shipped Quantity</th>
                            <th>Challan Number</th>
                            <th>AWB Number</th>
                            <th>Courier Name</th>
                            <th>Courier Date</th>
                            <th>Received Date By SC</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spareParts.map(
                            (item, index) =>
                              item.shipped_part_id && (
                                <tr key={index} className="text-center">
                                  <td>{index + 1}</td>
                                  <td>{item.id}</td>
                                  <td>
                                    {Number(item.entity_type) === PARTNER_TYPE
                                      ? "PARTNER"
                                      : "WAREHOUSE"}
                                  </td>
                                  <td>{item.shipped_part.part_type}</td>
                                  <td>{item.shipped_part.part_name}</td>
                                  <td>{item.shipped_part.part_number}</td>
                                  <td>{item.shipped_quantity}</td>
                                  <td>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        openSignUrl(
                                          item.forward_challan.main_file
                                        )
                                      }
                                    >
                                      {item.forward_challan.msl_id}
                                    </Link>
                                  </td>
                                  <td>
                                    {item.forward_challan
                                      ?.courier_shippment && (
                                      <>
                                        <Link
                                          to={"#"}
                                          onClick={() => {
                                            openSignUrl(
                                              item.forward_challan
                                                ?.courier_shippment
                                                ?.courier_file
                                            );
                                          }}
                                        >
                                          {
                                            item.forward_challan
                                              ?.courier_shippment?.awb_number
                                          }
                                        </Link>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {
                                      item.forward_challan?.courier_shippment
                                        ?.courier_name
                                    }
                                  </td>
                                  <td>
                                    {item.forward_challan?.courier_shippment
                                      ?.shippment_date
                                      ? new Date(
                                          item.forward_challan.courier_shippment.shippment_date
                                        ).toDateString()
                                      : ""}
                                  </td>
                                  <td>
                                    {item.forward_part_ack_date
                                      ? new Date(
                                          item.forward_part_ack_date
                                        ).toDateString()
                                      : ""}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isDefectiveDispatch && (
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0">
                  Reverse Part from SC
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>SNo.</th>
                            <th>Requested Id</th>
                            <th>Challan Number</th>
                            <th>Challan Date</th>
                            <th>Quantity</th>
                            <th>AWB Number</th>
                            <th>Courier Name</th>
                            <th>Courier Date</th>
                            <th>Rejection Remarks</th>
                            <th>Rejection Date</th>
                            <th>Received Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spareParts.map(
                            (item, index) =>
                              item.defective_challan_id && (
                                <tr key={index} className="text-center">
                                  <td>{index + 1}</td>
                                  <td>{item.id}</td>
                                  <td>
                                    <Link
                                      to={"#"}
                                      onClick={() => {
                                        openSignUrl(
                                          item.defective_challan.main_file
                                        );
                                      }}
                                    >
                                      {item.defective_challan.msl_id}
                                    </Link>
                                  </td>
                                  <td>
                                    {new Date(
                                      item.defective_challan.msl_date
                                    ).toDateString()}
                                  </td>
                                  <td>{item.defective_challan.quantity}</td>

                                  <td>
                                    {item.defective_challan
                                      ?.courier_shippment && (
                                      <>
                                        <Link
                                          to={"#"}
                                          onClick={() => {
                                            openSignUrl(
                                              item.defective_challan
                                                ?.courier_shippment
                                                ?.courier_file
                                            );
                                          }}
                                        >
                                          {
                                            item.defective_challan
                                              ?.courier_shippment?.awb_number
                                          }
                                        </Link>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {
                                      item.defective_challan?.courier_shippment
                                        ?.courier_name
                                    }
                                  </td>
                                  <td>
                                    {item.defective_challan?.courier_shippment
                                      ?.shippment_date
                                      ? new Date(
                                          item.defective_challan.courier_shippment.shippment_date
                                        ).toDateString()
                                      : ""}
                                  </td>
                                  <td>{item.rejection_remarks_for_sf}</td>
                                  <td>
                                    {item.rejection_date_for_sf
                                      ? new Date(
                                          item.rejection_date_for_sf
                                        ).toDateString()
                                      : ""}
                                  </td>
                                  <td>
                                    {item.defective_received_ack_date_warehouse
                                      ? new Date(
                                          item.defective_received_ack_date_warehouse
                                        ).toDateString()
                                      : item.defective_received_ack_date_partner
                                      ? new Date(
                                          item.defective_received_ack_date_partner
                                        ).toDateString()
                                      : ""}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isDispatchWh && (
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0">
                  Dispatch Part From Warehouse to Partner
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>SNo.</th>
                            <th>Requested Id</th>
                            <th>Challan Number</th>
                            <th>Challan Date</th>
                            <th>Quantity</th>
                            <th>AWB Number</th>
                            <th>Courier Name</th>
                            <th>Courier Date</th>
                            <th>Rejection Remarks</th>
                            <th>Rejection Date</th>
                            <th>Received Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spareParts.map(
                            (item, index) =>
                              item.defective_wh_challan_id && (
                                <tr key={index} className="text-center">
                                  <td>{index + 1}</td>
                                  <td>{item.id}</td>
                                  <td>
                                    <Link
                                      to={"#"}
                                      onClick={() => {
                                        openSignUrl(
                                          item.defective_wh_challan.main_file
                                        );
                                      }}
                                    >
                                      {item.defective_wh_challan.msl_id}
                                    </Link>
                                  </td>
                                  <td>
                                    {new Date(
                                      item.defective_wh_challan.msl_date
                                    ).toDateString()}
                                  </td>
                                  <td>{item.defective_wh_challan.quantity}</td>

                                  <td>
                                    {item.defective_wh_challan
                                      ?.courier_shippment && (
                                      <>
                                        <Link
                                          to={"#"}
                                          onClick={() => {
                                            openSignUrl(
                                              item.defective_wh_challan
                                                ?.courier_shippment
                                                ?.courier_file
                                            );
                                          }}
                                        >
                                          {
                                            item.defective_wh_challan
                                              ?.courier_shippment?.awb_number
                                          }
                                        </Link>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {
                                      item.defective_wh_challan
                                        ?.courier_shippment?.courier_name
                                    }
                                  </td>
                                  <td>
                                    {item.defective_wh_challan
                                      ?.courier_shippment?.shippment_date
                                      ? new Date(
                                          item.defective_wh_challan.courier_shippment.shippment_date
                                        ).toDateString()
                                      : ""}
                                  </td>
                                  <td>{item.rejection_remarks_for_wh}</td>
                                  <td>
                                    {item.rejection_date_for_wh
                                      ? new Date(
                                          item.rejection_date_for_wh
                                        ).toDateString()
                                      : ""}
                                  </td>
                                  <td>
                                    {item.defective_received_ack_date_partner
                                      ? new Date(
                                          item.defective_received_ack_date_partner
                                        ).toDateString()
                                      : ""}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>Part Not Ordered</>
      )}
    </div>
  );
};

export default SparePartsTab;
