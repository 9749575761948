import React, { useState } from "react";
import MainBody from "../../component/MainBody";
import EstimatePending from "./EstimatePending";
import PartInTransit from "./PartInTransit";
import PartOrderedList from "./PartOrderedList";
import DefectiveChallanGenerate from "./DefectiveChallanGenerate";
import ReverseChallanTab from "./ReverseChallanTab";
import PartDelivered from "./PartDelivered";

const SpareActions = () => {
  const [reloadList, setReload] = useState(true);
  const [breadcrumbSubTitle, setBreadcrumbSubTitle] = useState(
    "Estimate Given"
  );
  
  return (
    <div>
      <MainBody
        breadcrumbTitle="Warehouse Task"
        breadcrumbSubTitle={breadcrumbSubTitle}
        navButton=""
        title={""}
        component={
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">

                        <li className="nav-item">
                          <a
                            className=" active nav-link"
                            href="#estimate"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Estimate Given");
                            }}
                          >
                            Estimate Given
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#partordered"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Part Ordered");
                            }}
                          >
                            Part Ordered
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#partintransit"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Part In Transit");
                            }}
                          >
                            Part In Transit
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#partdelivered"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Part Delivered");
                            }}
                          >
                            Part Delivered
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#defective_challan"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Reverse Challan To Be Generated");
                            }}
                          >
                            Reverse Challan To Be Generated
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#reverse_challan"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Dispatch Reverse Challan");
                            }}
                          >
                            Dispatch Reverse Challan
                          </a>
                        </li>
                        
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">

                        <div className="active tab-pane" id="estimate">
                        <EstimatePending 
                         reloadList={reloadList}
                         setReload={setReload} />
                        </div>
                        <div className="tab-pane" id="partordered">
                        <PartOrderedList 
                         reloadList={reloadList}
                         setReload={setReload}/>
                        </div>
                        <div className=" tab-pane" id="partintransit">
                        <PartInTransit 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        <div className=" tab-pane" id="partdelivered">
                        <PartDelivered 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        <div className=" tab-pane" id="defective_challan">
                        <DefectiveChallanGenerate
                          setReverseReload={setReload}
                         />
                        </div>
                        <div className=" tab-pane" id="reverse_challan">
                        <ReverseChallanTab 
                          reloadList={reloadList}
                          setReload={setReload}
                        />
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      />
    </div>
  );
};

export default SpareActions;
