import React from 'react'; 
const ExcelJS = require('exceljs');

// Utility function to get text from JSX or string
const getHeaderText = (header) => {
  if (typeof header === 'string') {
    return header;
  }
  if (React.isValidElement(header)) {
    // Extract text from JSX elements
    return header.props.children;
  }
  return ''; // Fallback for non-string or non-element headers
};

export function downloadXLSX(array, columns, file_name) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Sheet1');

  // Add headers to the sheet
  columns.forEach((column, index) => {
    if (column.hasOwnProperty("exportXlsx") && column.exportXlsx === false)
    {
      // Skip columns with exportXlsx set to false
      return;
    }
    sheet.getCell(1, index + 1).value = getHeaderText(column.name);
  });

  // Add data to the sheet
  array.forEach((item, rowIndex) => {
    columns.forEach((column, colIndex) => {
      if (column.hasOwnProperty("exportXlsx") && column.exportXlsx === false)
      {
        // Skip columns with exportXlsx set to false
        return;
      }
      const val = column.selector(item);
      sheet.getCell(rowIndex + 2, colIndex + 1).value = val;
    });
  });

  // Convert the workbook to a buffer
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", file_name + ".xlsx");
    link.click();
  });
}

export function downloadCSV(array, columns) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array, columns);
  if (csv == null) return;

  const filename = "data.csv";

  if (!csv.match(/^data:text\/csv/i))
  {
    csv = `data:text/csv;charset=utf-8,${ csv }`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

function convertArrayOfObjectsToCSV(array, columns) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = [];
  columns.forEach((i) => {
    if (i.hasOwnProperty("exportCSV"))
    {
      if (i.exportCSV === true)
      {
        keys.push(i.name);
      }
    } else
    {
      keys.push(i.name);
    }
  });

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    columns.forEach((key) => {
      let e = false;
      if (ctr > 0) result += columnDelimiter;
      if (key.hasOwnProperty("exportCSV"))
      {
        if (key.exportCSV === true)
        {
          e = true;
        }
      } else
      {
        e = true;
      }

      if (e === true)
      {
        const val = key.selector(item);
        result += val;
        ctr++;
      }
    });
    result += lineDelimiter;
  });

  return result;
}