import React from 'react'

const UpcountryTab = ({ bookingUpcountry }) => {
  console.log(bookingUpcountry);
  return (
    <div>
      {bookingUpcountry.length > 0 ? (
        <>
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>SNo.</th>
                <th>Is Upcountry</th>
                <th>Oneway Distance</th>
                <th>Upcountry Distance</th>
                <th>Upcountry Pincode</th>
                <th>Municipal Limit</th>
                <th>Upcountry To be Pay by Customer</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {bookingUpcountry.map((item, index) => (
                <tr key={index} className="text-center">
                  <td>{index + 1}</td>
                  <td>{item.is_upcountry === 1 ? "Yes" : "No"}</td>
                  <td>{`${item.oneway_distance} KM`} </td>
                  <td>{`${item.upcountry_distance} KM`}</td>
                  <td>{item.upcountry_pincode}</td>
                  <td>{`${item.municipal_limit} KM`}</td>
                  <td>
                    {item.upcountry_paid_by === 0
                      ? 'Rs.0'
                      : item.upcountry_paid_by === 1
                      ? 'Rs.0'
                      : `Rs. ${item.upcountry_sf_rate * item.upcountry_distance}`}
                  </td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <>Not Upcountry</>
      )}
    </div>
  );
}

export default UpcountryTab;
