import { GET_SIGNED_DOC_URL } from "./ApiUrls";
import httpRequest from "./Http";
import CryptoJS from 'crypto-js';

export const isValidPanNumber = (panNumber) => {
    // Check if the PAN number is in the correct format
    const regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    return regex.test(panNumber);
}

export const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
};

export const isValidMobile = (mobile) => {
    return /^[6-9]{1}[0-9]{9}$/.test(mobile);
}

export const isValidUrl = (url) => {
  // Regular expression for a basic URL pattern
  const urlRegex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\\-]))?/;

  return urlRegex.test(url);
}

export const isValidGstNumber = (gstNumber) => {
  // Check if the GST number is in the correct format
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  return gstRegex.test(gstNumber);
}

export const isValidAadhaarNumber = (aadhaarNumber) =>{
  const aadhaarRegex = /^\d{12}$/;
  return aadhaarRegex.test(aadhaarNumber);
}


export const textUpperCase = (text) => {
  return text.replace(
    /\b\w/g,
    (match) => match.toUpperCase()
  )
}

export const getSignUrl = async (docFileurl) => {
  const res = await httpRequest(GET_SIGNED_DOC_URL, { docFileurl }, "POST");
  if (res.status === true && res.url) {
    return res.url;
  } else {
     return false;
  }
};

export const openSignUrl = async (docFileurl) => {
  const url = await getSignUrl(docFileurl);
  if (url) {
    window.open(url, "_blank");
  }
};

export const updateClock = (date) => {
    const now = new Date(date);
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Format hours to 12-hour format with AM/PM
    const formattedHours = hours % 12 || 12;

    // Format minutes with leading zero if less than 10
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Determine if it's AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Check if the date is today
    const isToday =
      now.toISOString().slice(0, 10) ===
      new Date().toISOString().slice(0, 10);

    // Set the current time string
    if (isToday) {
      return (`${formattedHours}:${formattedMinutes} ${period} Today`);
    } else {
      // Get the day of the week
      const dayOfWeek = now.toLocaleDateString("en-US", { weekday: "long" });

      // Get the date
      const date = now.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });

      return (
        `${formattedHours}:${formattedMinutes} ${period} ${dayOfWeek}, ${date}`
      );
    }
  };

  export const decryptData = (encryptedData, key) => {
    if(encryptedData){
      try {
        // Decrypt the data
        const decrypted = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);

        // Parse the decrypted data as JSON
        const decryptedData = JSON.parse(decrypted);
        
        // Check if the data has expired
        if (decryptedData.expiration && new Date(decryptedData.expiration) < new Date()) {
          return { valid: false, data: null }; // Data has expired
        }
        
        // Return the original data
        return { valid: true, data: decryptedData.data };  
      } catch (error) {
        console.error("Error decrypting data:", error);
        return { valid: false, data: null }; // Or handle the error appropriately
      }
    } else {
      return { valid: false, data: null };
    }
};
  
  export const encryptData = (data, key) => {
    if(data){
      try {
        // Add expiration time (2 minutes from now) to the data
        const expiration = new Date();
        expiration.setMinutes(expiration.getMinutes() + 2); // Expire after 2 minutes
        const dataWithExpiration = { data, expiration: expiration.getTime() };
        
        // Encrypt the data
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataWithExpiration), key).toString();
        
        return encrypted;
      } catch (error) {
        console.error("Error encrypting data:", error);
        return null; // Or handle the error appropriately
      }
    } else {
      return null;
    }
};
  

  export const maskMobileNumber = (mobileNumber) => {
    return mobileNumber.replace(/^(\d)(.*)(\d{3})$/, (match, first, middle, last) => {
      return `${first}${'*'.repeat(middle.length)}${last}`;
    });
  };

  // Function to mask email
  export const maskEmail = (email) => {
    const [username, domain] = email.split('@');
    return `${username.charAt(0)}${'*'.repeat(username.length - 1)}@${domain}`;
  };

  export const getdropdownList = async (setOption, url) => {
    const listdata = await httpRequest(
         url,
        {},
        "GET"
    );

    if (listdata.status === true && listdata.data)
    {
        if (listdata.data["rows"] === undefined)
        {
            setOption(listdata.data);
        } else
        {
            setOption(listdata.data["rows"]);
        }
    }
}

export const getPartWarranty = (warranty) => {
  switch (warranty) {
    case 1:
      return "In-Warranty";
    case 2:
      return "Out Of Warranty";
    case 3:
      return "Extended Warranty";
    case 4:
      return "Presale";
  }
};

export const isHsnCode = (hsn_code) => {
  return /^\d{8}$/.test(hsn_code);
}

export const getDateDiff = (shippment_date) => {
  const shipmentDate = new Date(shippment_date);
  const currentDate = new Date();

  // Calculate the difference in time
  const timeDifference = currentDate - shipmentDate;

  // Convert the time difference to days
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};