import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import MyDataTable from "../../../component/MyDataTable";
import { Link } from "react-router-dom";
import { openSignUrl } from "../../../utils/HelperFunction";
import httpRequest from "../../../utils/Http";
import { UploadFileToS3 } from "../../../utils/S3File";
import { toast } from "react-toastify";

export const UploadAggrementCopy = ({ entity_id, entity_type }) => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({ entity_id, entity_type });
  const [reloadList, setReload] = useState(true);

  const handleStartDateChange = (date) => {
    setFormData({
      ...formData,
      start_date: date,
    });
  };

  const handleEndDateChange = (date) => {
    setFormData({
      ...formData,
      end_date: date,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const columns = [
    {
        name: "SNo.",
        selector: (_, index) => index + 1,
    },
    {
        name: "Start Date",
        selector: (row) => row.start_date,
    },
    {
        name: "End Date",
        selector: (row) => row.start_date,
    },
    {
        name: "Download",
        selector: (row) => <><Link href={"#"} onClick={() => openSignUrl(row.aggrement_file)} rel="noopener noreferrer"><i className="fa fa-download"></i></Link></>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        exportXlsx: false,
    },
    {
        name: 'Uploaded By',
        selector: (row) => row.agent_detail.name
    },
    {
        name: 'Uploaded Date',
        selector: (row) => new Date(row.createdAt).toDateString()
    }
  ]

  const validateFrom = (values) => {
    const errors = {};
    if(!values.start_date){
        errors.start_date = "Start Date is required";
    }
    if(!values.end_date){
        errors.end_date = "End Date is required";
    }
    if(!values.aggrement_tmp_file){
        errors.aggrement_file = "Aggrement File is required";
    }

    return errors;
  }

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let formData1 = {};
        formData1 = formData;
        if (formData.aggrement_tmp_file) {
          const aggrement_file = await UploadFileToS3("docFile", formData.aggrement_tmp_file);
          formData1.aggrement_file = aggrement_file;
        } 


        
        const data = await httpRequest("uploadaggrementcopy", formData1, "POST");

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          setReload(true)
          document.getElementById("aggrementform").reset();
          setFormData({ entity_id, entity_type })
          // navigate("/sfdetails/" + data.data["id"] + "?tab=documents");
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <form id="aggrementform" onSubmit={onSubmitHandler}>
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Upload Aggrement Copy</h3>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label>
                        Start Date *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.start_date}
                        </span>
                      </label>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select Start Date"
                        selected={formData.start_date}
                        onChange={handleStartDateChange}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthDropdown
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label>
                        End Date *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.end_date}
                        </span>
                      </label>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select End Date"
                        selected={formData.end_date}
                        onChange={handleEndDateChange}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthDropdown
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label>
                        Aggrement File *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.aggrement_file}
                        </span>
                      </label>
                      <div className="input-group">
                        <input
                          type="file"
                          className="form-control rounded-0"
                          id="cheque"
                          name="cheque"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              aggrement_tmp_file: e.target.files[0],
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <div className="row">
                  <span style={{ color: "red" }}>{formErrors.mandatory}</span>
                  <br></br>
                  <input
                    type="submit"
                    className="form-control btn btn-primary"
                    value={"Submit"}
                  />
                </div>
              </div>

            </div>
          </form>
        </div>
      </section>

      <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Aggrement List</h3>
                      </div>
                      <div className="card-body">
                        <MyDataTable
                          reloadList={reloadList}
                          columns={columns}
                          getDataListURL={`getaggrementcopy?entity_id=${formData.entity_id}&entity_type=${formData.entity_type}`}
                          setReload={setReload}
                          exportName={"aggrementlist"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
    </>
  );
};
