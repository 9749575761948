import { BACKEND_API_BASE_URL } from "./BackendBaseUrl";
import { encryptData } from "./HelperFunction";

const httpRequest = async (
  apiURL,
  data,
  method,
  contentType = "application/json"
) => {

  let config = {
    method,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: encryptData(
        localStorage.getItem("token"),
        process.env.REACT_APP_SECRET_KEY
      ),
      project: window.location.hostname,
    },
    mode: "cors",
  };

  if (method === "POST") {
    if (contentType === "application/json") {
      config.headers["Content-Type"] = "application/json";
      config.body = JSON.stringify(data);
    } else if (contentType === "multipart/form-data") {
      console.log('hi');
      config.headers["mimetype"] =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const formData = new FormData();
      for (const key in data) {
        // console.log(key, data);
        formData.append(key, data[key]);
      }

      config.body = formData;
      console.log(formData)
    }
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  //console.log(config);
  try {
    window["showloadingmodel"]();
    let res = await fetch(BACKEND_API_BASE_URL + apiURL, config);
    window["distroyloadingmodel"]();

    // Check HTTP status code
    // if (res.status === 401) {
    //   // Handle 401 Unauthorized
    //   localStorage.removeItem("token");
    //   window.location.href = "/login";

    //   return { status: false, message: "Unauthorized! Please log in again." };
    // }

    const response = await res.json();

    return response;
  } catch (err) {
    // console.log(err);
    return { status: false, message: "Server Error" };
  }
};

export default httpRequest;
