import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BookingRecentActivityTab = ({
  bookingHistory,
  setBookingHistory,
  bid,
}) => {
  let currentUser = useSelector((state) => state.authReducer.currentUser);
  const [formData, setFormData] = useState({ bid });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [commentText, setCommentText] = useState("");

  const activityDates = Object.keys(bookingHistory);
  const bookingElements = activityDates.map((date) => {
    const bookingActivity = bookingHistory[date];
    const historyList = bookingActivity.map((item, index) => {
      return (
        <>
          <div>
            <i className="fas fa-user bg-info" />
            <div className="timeline-item">
              <span className="time">
                <i className="far fa-clock" />{" "}
                {moment(item.createdAt).format("HH:mm")}
              </span>
              <h3 className="timeline-header">
                <a href="#">
                  {item.agent_detail["name"]} (
                  {item.agent_detail["service_centre"] != null &&
                    item.agent_detail["service_centre"].display_name}
                  {item.agent_detail["partner"] != null &&
                    item.agent_detail["partner"].display_name}
                  )
                </a>{" "}
                {item.action}
              </h3>
              {item.remarks && (
                <div className="timeline-body">{item.remarks}</div>
              )}
            </div>
          </div>
        </>
      );
    });
    return (
      <>
        <div className="time-label">
          <span className="bg-success">
            {moment(date).format("DD MMM YYYY")}
          </span>
        </div>
        {historyList}
      </>
    );
  });

  const validateFrom = (value) => {
    const errors = {};

    if (!value.comment) {
      errors.comment = "Please enter comment";
    }

    return errors;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const listdata = await httpRequest("addcomment", formData, "POST");

        if (listdata.status === true) {
          setBookingHistory(listdata.bookingHistory);
          toast.success("New Comment Added");
          setCommentText("");
          setSubmit(false);
        } else {
          toast.error(listdata.message);
          setSubmit(false);
        }
      }
    })();
  }, [formErrors, isSubmit]);

  return (
    <>
      <div className="timeline timeline-inverse">
        {bookingElements}
        {/* END timeline item */}
        <div className="time-label">
          <span className="bg-danger">{moment().format("DD MMM YYYY")}</span>
        </div>
        <div>
          <i className="fas fa-comments bg-warning" />
          <div className="timeline-item">
            <h3 className="timeline-header">
              <a href="#">{currentUser.name}</a> can add comment here{" "}
              <span style={{ color: "red" }}>{formErrors.comment}</span>
            </h3>
            <div className="timeline-body">
              <form className="form-horizontal" onSubmit={onSubmitHandler}>
                <div className="input-group input-group-sm mb-0">
                  <textarea
                    className="form-control form-control-sm"
                    name="comment"
                    placeholder="Comment"
                    onChange={(e) => {
                      setFormData({ ...formData, comment: e.target.value });
                      setCommentText(e.target.value);
                    }}
                    value={commentText}
                  ></textarea>
                  <div className="input-group-append">
                    <button type="submit" className="btn btn-danger">
                      <i className="far fa-comments mr-1" /> Comment
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="timeline-footer"></div>
          </div>
        </div>

        <div>
          <i className="far fa-clock bg-gray" />
        </div>
      </div>
    </>
  );
};

export default BookingRecentActivityTab;
