import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import httpRequest from "../../../utils/Http";

const PartnerUpcountry = ({ partnerDetails, setPartnerDetails, role_id }) => {
  const [formData, setFormData] = useState({
    is_upcountry: 0,
    upcountry_rate: 0,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  useEffect(() => {
    if (partnerDetails) {
      setFormData({
        ...formData,
        partner_id: partnerDetails.partner_id,
        is_upcountry: partnerDetails.is_upcountry,
        upcountry_rate: partnerDetails.upcountry_rate,
      });
    }
  }, [partnerDetails]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    if (value.is_upcountry) {
      if (!value.upcountry_rate) {
        errors.upcountry_rate = "Upcountry Rate is mandatory";
      }
      if (value.upcountry_rate && value.upcountry_rate < 1) {
        errors.upcountry_rate = "Upcountry Rate should be greater than one";
      }
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest(
          "updatepartnerupcountry",
          formData,
          "POST"
        );
        if (data.status === true && data.message) {
          toast.success(data.message);

          setPartnerDetails(data.data);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
  }, [isSubmit, formErrors]);

  return (
    <div>
      <form
        className="form-horizontal"
        id="upcountryform"
        onSubmit={onSubmitHandler}
      >
        <div className="form-group row">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            Upcountry *{" "}
            <span style={{ color: "red" }}>{formErrors.upcountry_rate}</span>
          </label>
          <div className="col-sm-1">
            <input
              type="checkbox"
              className="form-control"
              id="is_upcountry"
              name="is_upcountry"
              checked={formData.is_upcountry}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  is_upcountry: e.target.checked,
                });
              }}
            />
          </div>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              step={".2"}
              min={1}
              id="upcountry_rate"
              placeholder="Enter Rate Per KM"
              value={formData.is_upcountry ? formData.upcountry_rate : 0}
              name="upcountry_rate"
              disabled={!formData.is_upcountry}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  upcountry_rate: e.target.value,
                });
              }}
            />
          </div>
          {role_id == 1 && (
            <div className="col-sm-4">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default PartnerUpcountry;
