import React from 'react'
import MyDataTable from '../../../../component/MyDataTable';
import { Link, NavLink } from 'react-router-dom';
import { getPartWarranty, openSignUrl } from '../../../../utils/HelperFunction';
import { PARTNER_TYPE } from '../../../../constant/status_constant';

const DefectiveDispatchedBySF = ({reloadList, setReload}) => {
    const columns = [
        {
          name: "SNo.",
          selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
          cell: (row, index) => index + 1, // Use cell to render the incrementing number
        },
        {
          name: "Part To be Received",
          selector: (row) => row.defective_return_type === PARTNER_TYPE ? 'PARTNER' : 'WAREHOUSE',
          wrap: true,
        },
        {
          name: <div className="word-wrap">Part Requested Id</div>,
          selector: (row) => row.id,
          wrap: true,
        },
        {
          name: <div className="word-wrap">Booking ID</div>,
          selector: (row) => row.booking_detail.booking_id,
          cell: (row) => (
            <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
              {row.booking_detail.booking_id}
            </NavLink>
          ),
          wrap: true,
        },
        {
          name: <div className="word-wrap">Appliance</div>,
          selector: (row) => `${row.booking_detail.appliance.appliance_name}`,
          wrap: true,
        },
        {
          name: <div className="word-wrap">Part Name / Number</div>,
          selector: (row) => `${row.requested_part.part_name} / ${row.requested_part.part_number}`,
          wrap: true,
        },
        {
          name: <div className="word-wrap">Model Number / Serial Number</div>,
          selector: (row) => `${row.requested_model.model_number} / ${row.booking_detail.user_appliance_detail.sf_serial_number}`,
          wrap: true,
        },
        {
          name: <div className="word-wrap">Warranty Status / Consumption</div>,
          selector: (row) => `${getPartWarranty(row.warranty_status)} / ${Number(row.consumption_id) === 1 ? "Part Consumed" : "Part Not Consumed"}`,
          wrap: true,
        },
        {
          name: <div className='word-wrap'>Challan Number</div>,
          selector: (row) => row.defective_challan.msl_id,
          cell: (row) => (
            <Link
              to={"javascript:void(0)"}
              onClick={() => openSignUrl(row.defective_challan.main_file)}
            >
              {row.defective_challan.msl_id}
            </Link>
          ),
          wrap: true,
        },
        {
          name: <div className='word-wrap'>Quantity</div>,
          selector: (row) => row.defective_challan.quantity,
          wrap: true,
        },
        {
          name: <div className='word-wrap'>Challan Date</div>,
          selector: (row) => row.defective_challan.msl_date,
          wrap: true,
        },
        {
          name: <div className='word-wrap'>Courier Name / Awb Number</div>,
          selector: (row) => `${row.defective_challan.courier_shippment.courier_name} / ${row.defective_challan.courier_shippment.awb_number}`,
          wrap: true,
        },
      ];
    
  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              
                <MyDataTable
                  reloadList={reloadList}
                  columns={columns}
                  getDataListURL={`getreverseparttobereceivebywarehouse?get=all`}
                  setReload={setReload}
                  exportName={"partlist"}
                />
              
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default DefectiveDispatchedBySF