import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    headerProps: {},
};

const counterSlice = createSlice({
    name: "headerProps",
    initialState,
    reducers: {
      updateHeaderProps(state = initialState, action) {

        return (state = {
          ...state,
          headerProps: action.payload,
        });
      },
      getHeaderProps(state){
        return {
          ...state
        }
      }
    },
  });
  
  export const { updateHeaderProps, getHeaderProps } = counterSlice.actions;
  export default counterSlice.reducer;