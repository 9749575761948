import { useEffect, useState } from "react";
import httpRequest from "../utils/Http";
import DataTable from "react-data-table-component";
import { Search } from 'react-feather';
import { downloadXLSX } from "../utils/DataExport";

const MyDataTable = ({ reloadList, setReload, columns, getDataListURL, 
    expandableRows = false, ExpandedComponent, exportName, conditionalRowStyles ={} }) => {
    const [list, setList] = useState({});
    const [page, setPage] = useState(1);
    const [countPerPage, setcountPerPage] = useState(25);
    const [searchText, setSearchText] = useState("");

    const changePageHandler = (cp) => {
        setcountPerPage(cp);
        setPage(1);
        setReload(true);
    };

    const getDatalist = async () => {
        try {
            let URL = getDataListURL + `&page=${page}&size=${countPerPage}&search=${searchText}`;
            const listdata = await httpRequest(URL, {}, "GET");

            if (listdata.status === true && listdata.data) {
                setList(listdata.data);
            } else {
                setList({});
            }

            setReload(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (reloadList) {
            getDatalist();
        }
        //eslint-disable-next-line
    }, [reloadList]);
    

    return (
        <>
            <div>
                <DataTable
                    columns={columns}
                    conditionalRowStyles={conditionalRowStyles}
                    data={list.rows}
                    expandableRows={expandableRows}
                    expandableRowsComponent={ExpandedComponent}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    progressPending={reloadList}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={list.totalItems}
                    paginationPerPage={countPerPage}
                    paginationRowsPerPageOptions={[5, 15, 25, 50]}
                    paginationComponentOptions={{
                        rowsPerPageText: "Records per page:",
                        rangeSeparatorText: "",
                        selectAllRowsItem: true,
                        selectAllRowsItemText: "ALL",
                    }}
                    onChangePage={(page) => { setPage(page); setReload(true); }}
                    onChangeRowsPerPage={(countPerPage) =>
                        changePageHandler(countPerPage)
                    }
                    subHeader
                    className="table table-striped table-hover"
                    subHeaderComponent={
                        <>
                            <div className="row">
                                <div className="col-md-5">
                                    <button
                                        onClick={() => { downloadXLSX(list.rows, columns, exportName) }}
                                        className="btn btn-default"
                                    >
                                        <span className="fa-solid fa-file-export fs--1 me-2"></span>
                                        Export
                                    </button>
                                </div>
                                <div className="col-md-7">
                                    <div className="search-bar" style={{ position: "relative" }}>
                                        <input type="text" placeholder="Search..." className="form-control" style={{ paddingRight: "30px" }} onChange={(e) => { setSearchText(e.target.value); setReload(true); }} />
                                        <div className="search-icon" style={{ position: "absolute", top: "48%", right: "10px", transform: "translateY(-50%)", cursor: "pointer" }}>
                                            <Search size={16} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default MyDataTable;
