import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../reducers/auth";
import { getUserDetails } from "../../reducers/auth";
import { getHeaderProps } from "../../reducers/headerProps";
import './customSearchbar.css';

const Navbar = ({setDisplay, isDisplay}) => {
  let dispatch = useDispatch();
  let user = useSelector((state) => state.authReducer.currentUser);
  let headerProps = useSelector((state) => state.headerProps.headerProps);

  const handlelogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    // Dispatch the getUserDetails action when the component mounts
    dispatch(getUserDetails());
    dispatch(getHeaderProps());
  }, [dispatch]);

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item" onClick={() => {
            setDisplay(!isDisplay)
          }}>
            <Link
              className="nav-link"
              data-widget="pushmenu"
              to={"#"}
              role="button"
            >
              <i className="fas fa-bars" />
            </Link>
          </li>
          <li className="nav-item  d-none d-sm-inline-block">
            <Link to={"#"} className="nav-link">
              {headerProps && (
                <>
                  {headerProps.breadcrumbTitle}{" "}
                  {headerProps.breadcrumbSubTitle && (
                    <>/ {headerProps.breadcrumbSubTitle}</>
                  )}
                </>
              )}
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
        <li className="nav-item d-none d-sm-inline-block" style={{float:"right"}}>
            
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <span className="input-group-text" style={{backgroundColor: "#fff" , borderRight: "0px", borderTopLeftRadius: "10px",  borderBottomLeftRadius: "10px"}}>
                <svg focusable="false" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M20.49,19l-5.73-5.73C15.53,12.2,16,10.91,16,9.5C16,5.91,13.09,3,9.5,3S3,5.91,3,9.5C3,13.09,5.91,16,9.5,16 c1.41,0,2.7-0.47,3.77-1.24L19,20.49L20.49,19z M5,9.5C5,7.01,7.01,5,9.5,5S14,7.01,14,9.5S11.99,14,9.5,14S5,11.99,5,9.5z"></path><path d="M0,0h24v24H0V0z" fill="none"></path></svg>
                </span>
              </div>
              <form action="/search" method="get">
              <input
                type="text"
                className="form-control form-control-navbar customSearch"
                placeholder="Search"
                aria-label="Search"
                name="keyword"
                
              />
              </form>
              
              <div className="input-group-prepend">
                <span className="input-group-text" style={{backgroundColor: "#fff", borderLeft: "0px",  borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path></svg>
                </span>
              </div>
            </div>
           
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
        <li className="nav-item ">
          <Link to={"/createbooking"} className="nav-link">Add New Booking</Link>
        </li>
          {headerProps.navButton && (
            <>
              <li className="nav-item ">{headerProps.navButton}</li>
            </>
          )}

          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              to={"#"}
              aria-expanded="true"
              aria-haspopup="true"
            >
              {user && user.picture ? (
                <img
                  src={user.picture}
                  alt="Profile"
                  className="rounded-circle"
                  style={{ width: "25px", height: "25px" }}
                />
              ) : (
                <i className="far fa-user" />
              )}
              &nbsp;&nbsp;&nbsp;{user && <>{user.name}</>}
            </Link>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              {/* <span className="dropdown-item dropdown-header">15 Notifications</span> */}
              <Link
                to={"#"}
                onClick={() => {
                  handlelogout();
                }}
                className="dropdown-item"
              >
                <i className="fas fa-sign-out-alt" /> Signout
              </Link>
              <div className="dropdown-divider"></div>
              <Link
                to={"/resetpassword"}
               
                className="dropdown-item"
              >
                <i className="fas fa-lock" /> Reset Password
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
