import React, { useState, useEffect } from "react";
import MyDataTable from "../../../component/MyDataTable";
import MainBody from "../../../component/MainBody";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import httpRequest from "../../../utils/Http";

const MslAckPage = () => {
  const { msl_pid } = useParams();
  const [formData, setFormData] = useState({ msl_pid });
  const [enableCheckbox, setEnableCheckbox] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [reloadList, setReload] = useState(true);
  const [msl_id, setMsl_id] = useState(null);
  const navigate = useNavigate();

  const handleCheckbox = (row, event) => {
    const updatedEnableCheckbox = { ...enableCheckbox };
    const updatedFormData = { ...formData };

    if (event.target.checked) {
      updatedEnableCheckbox[row.id] = true;
      updatedFormData[row.id + "-" + row.purchase_item_id] = {
        id: row.id,
        purchase_item_id: row.purchase_item_id,
        msl_id: row.msl_id,
        msl_pid: msl_pid,
        serial_number: row.serial_number,
        total_quantity: row.quantity,
        okReceived: 0,
        damagedReceived: 0,
        notReceived: 0,
      };
    } else {
      delete updatedEnableCheckbox[row.id];
      delete updatedFormData[row.id + "-" + row.purchase_item_id];
    }

    setEnableCheckbox(updatedEnableCheckbox);
    setFormData(updatedFormData);
  };

  const columns = [
    {
        name: "SNo.",
        selector: (row, index) => index + 1,
    },
    {
      name: "Part Name",
      selector: (row) => row.part_name,
    },
    {
      name: "Part Number",
      selector: (row) => row.part_number,
    },
    {
      name: "Serial Number",
      selector: (row) => row.serial_number,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      cell: (row) => {
        if (!msl_id) setMsl_id(row.msl_id);
        return row.quantity;
      },
    },
    {
      name: "Ok Received",
      cell: (row) => (
        <input
          type="number"
          className="form-control"
          style={formErrors[row.id + "-" + row.purchase_item_id] ? { border: "1px solid red" } : {}}
          placeholder="Enter the Ok Received Quantity"
          value={(formData[row.id + "-" + row.purchase_item_id]?.okReceived) || 0}
          onChange={(event) => {
            const updatedFormData = { ...formData };
            const newValue = parseInt(event.target.value) || 0;
            const totalQuantity = formData[row.id + "-" + row.purchase_item_id]?.total_quantity || 0;
            const damagedReceived = formData[row.id + "-" + row.purchase_item_id]?.damagedReceived || 0;
            const notReceived = formData[row.id + "-" + row.purchase_item_id]?.notReceived || 0;
            if (newValue + damagedReceived + notReceived <= totalQuantity) {
              updatedFormData[row.id + "-" + row.purchase_item_id].okReceived = newValue;
              setFormData(updatedFormData);
            } else {
              toast.error("Some of quantity should not be greater than shipped quantity");
            }
          }}
          readOnly={!enableCheckbox[row.id]}
        />
      ),
      exportXlsx: false,
    },
    {
      name: "Damaged Received",
      cell: (row) => (
        <input
          type="number"
          className="form-control"
          style={formErrors[row.id + "-" + row.purchase_item_id] ? { border: "1px solid red" } : {}}
          placeholder="Enter the Damaged Received Quantity"
          value={(formData[row.id + "-" + row.purchase_item_id]?.damagedReceived) || 0}
          onChange={(event) => {
            const updatedFormData = { ...formData };
            const newValue = parseInt(event.target.value) || 0;
            const totalQuantity = formData[row.id + "-" + row.purchase_item_id]?.total_quantity || 0;
            const okReceived = formData[row.id + "-" + row.purchase_item_id]?.okReceived || 0;
            const notReceived = formData[row.id + "-" + row.purchase_item_id]?.notReceived || 0;
            if (newValue + okReceived + notReceived <= totalQuantity) {
              updatedFormData[row.id + "-" + row.purchase_item_id].damagedReceived = newValue;
              setFormData(updatedFormData);
            } else {
              toast.error("Some of quantity should not be greater than shipped quantity");
            }
          }}
          readOnly={!enableCheckbox[row.id]}
        />
      ),
      exportXlsx: false,
    },
    {
      name: "Not Received",
      cell: (row) => (
        <input
          type="number"
          className="form-control"
          style={formErrors[row.id + "-" + row.purchase_item_id] ? { border: "1px solid red" } : {}}
          placeholder="Enter the Not Received Quantity"
          value={(formData[row.id + "-" + row.purchase_item_id]?.notReceived) || 0}
          onChange={(event) => {
            const updatedFormData = { ...formData };
            const newValue = parseInt(event.target.value) || 0;
            const totalQuantity = formData[row.id + "-" + row.purchase_item_id]?.total_quantity || 0;
            const damagedReceived = formData[row.id + "-" + row.purchase_item_id]?.damagedReceived || 0;
            const okReceived = formData[row.id + "-" + row.purchase_item_id]?.okReceived || 0;
            if (newValue + damagedReceived + okReceived <= totalQuantity) {
              updatedFormData[row.id + "-" + row.purchase_item_id].notReceived = newValue;
              setFormData(updatedFormData);
            } else {
              toast.error("Some of quantity should not be greater than shipped quantity");
            }
          }}
          readOnly={!enableCheckbox[row.id]}
        />
      ),
      exportXlsx: false,
    },
    {
      name: "Action",
      cell: (row) => (
        <input
          type="checkbox"
          className="form-control"
          onChange={(event) => handleCheckbox(row, event)}
        />
      ),
      exportXlsx: false,
    },
  ];

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setSubmit(false);
    setFormErrors(validateForm(formData));
    setSubmit(true);
  };

  const validateForm = (values) => {
    const errors = {};
    delete values.msl_pid;

    if (Object.keys(values).length === 0) {
      toast.error("Please enable at least one checkbox and enter the quantity");
      errors.submit = "Please enable at least one checkbox and enter the quantity";
    } else {
      Object.keys(values).forEach((key) => {
        const item = values[key];
        if ((Number(item.okReceived) + Number(item.damagedReceived) + Number(item.notReceived)) !== Number(item.total_quantity)) {
          errors[key] = "Some of the quantity is not matching with shipped quantity";
        }
      });
    }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      (async () => {
        const formDataCopy = { ...formData };
        delete formDataCopy.msl_pid;
        const data = await httpRequest("acknowledgewhmsl", { data: formDataCopy }, "POST");
        setSubmit(false);
        if (data.status && data.message) {
          toast.success(data.message);
          navigate('/whintranistmsl');
        } else {
          toast.error(data.message);
        }
      })();
    }
    // eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Msl Action"
        breadcrumbSubTitle={msl_id}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Msl Part</h3>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={`getwhmsltobeackpartlist?msl_pid=${msl_pid}`}
                        setReload={setReload}
                        exportName={"partlist"}
                      />
                    </div>
                    <div className="card-footer text-center">
                      <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary col-md-6"
                        onClick={onSubmitHandler}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default MslAckPage;
