import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import httpRequest from "../../../../utils/Http";
import { getdropdownList } from "../../../../utils/HelperFunction";
import MainBody from "../../../../component/MainBody";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const AddCharges = () => {
  const [opAppiance, setOpAppliance] = useState([]);
  // const [reloadList, setReload] = useState(true);
  const [appliaceValue, setApplianceValue] = useState(null);
  const [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    free_for_customer: 1,
    gstrate: 18,
    serial_number_pod: false,
    invoice_pod: false,
    upcountry: 0,
  });

  const [opBrand, setOpBrand] = useState([]);
  const [brandValue, setBrandValue] = useState(null);
  const [opCategory, setOpCategory] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [opCapacity, setOpCapacity] = useState([]);
  const [capacityValue, setCapacityValue] = useState(null);
  const [opCalltype, setOpCallType] = useState([]);
  const [calltypeValue, setCallTypeValue] = useState(null);
  const [optaxtrate, setOpTaxRate] = useState([{ value: 18, label: 18 }]);
  const [taxrateValue, setTaxRateValue] = useState({ value: 18, label: 18 });
  const [tuploadedData, setUploadedData] = useState([]);
  const [partnerlist, setPartnerlist] = useState([]);
  const [partnerId, setPartnerId] = useState([]);
  const [charges, setCharges] = useState([
    { location: 0, is_spare: 0, amount: "", days: 0 },
  ]);

  const handleAddChargeRow = () => {
    setCharges([...charges, { location: 0, is_spare: 0, amount: "", days: 0 }]);
  };

  const handleRemoveChargeRow = (index) => {
    const updatedCharges = charges.filter((_, idx) => idx !== index);
    setCharges(updatedCharges);
  };

  const handleChargeChange = (index, field, value) => {
    const updatedCharges = charges.map((charge, idx) =>
      idx === index ? { ...charge, [field]: value } : charge
    );
    setCharges(updatedCharges);
  };

  useEffect(() => {
    if (charges.length > 0) {
      const updatedCharges = charges.map((charge) => {
        const similarCharges = charges.filter(c =>
          c.location === charge.location && c.is_spare === charge.is_spare
        );
        const days = similarCharges.indexOf(charge); // Start from 0
        return { ...charge, days };
      });
      setCharges(updatedCharges);
    }
  }, [charges.length, charges.map((charge) => charge.location).join(), charges.map((charge) => charge.id).join(), charges.map((charge) => charge.is_spare).join()]);


  useEffect(() => {
    setFormData({ ...formData, tatCondition: charges });
    console.log(charges);
  }, [charges]);

  const violationRef = useRef(null);

  const goToViolation = () => {
    violationRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    (async () => {
      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );

      if (partners.status === true && partners.data) {
        setPartnerlist(partners.data["rows"]);
      }
    })();
    //eslint-disable-next-line
  }, []);

  const getApplianceList = (partnerId) => {
    setApplianceValue(null);
    setBrandValue(null);
    setCategoryValue(null);
    setCapacityValue(null);
    setCallTypeValue(null);

    getdropdownList(
      setOpAppliance,
      `appliancelistmapping?is_active=1&partner_id=${partnerId}`
    );
  };

  const getBrandlList = async (applianceID) => {
    setBrandValue(null);
    setCategoryValue(null);
    setCapacityValue(null);
    setCallTypeValue(null);

    getdropdownList(
      setOpBrand,
      `getbrandlistmapping?partner_id=${partnerId}&appliance_id=${applianceID}`
    );

    getdropdownList(
      setOpCallType,
      "getcalltypelist" + "?isactive=true&appliance_id=" + applianceID
    );
  };

  const getCategoryList = async (brand) => {
    if (brand) {
      const listdata1 = await httpRequest(
        "getcategorylistmapping",
        {
          appliance_id: formData.appliance_id,
          partner_id: formData.partner_id,
          brand,
        },
        "POST"
      );
      if (listdata1.status === true && listdata1.data) {
        setCategoryValue(null);
        setCapacityValue(null);
        setOpCategory(listdata1.data);
      }
    }
  };

  const getCapacityList = async (category) => {
    if (category) {
      const listdata1 = await httpRequest(
        "getcapacitylistmapping",
        {
          appliance_id: formData.appliance_id,
          partner_id: formData.partner_id,
          brand: formData.brand,
          category,
        },
        "POST"
      );
      if (listdata1.status === true && listdata1.data) {
        setCapacityValue(null);
        setOpCapacity(listdata1.data);
      }
    }
  };

  const validateFrom = (value) => {
    const errors = {};
    if (!value.appliance_id) {
      errors.appliance_id = "Appliance is required";
    }
    if (!value.brand) {
      errors.brand = "Brand is required";
    }
    if (!value.category) {
      errors.category = "Category is required";
    }
    if (!value.capacity) {
      errors.capacity = "Capacity is required";
    }
    if (!value.calltype) {
      errors.calltype = "CallType is required";
    }
    if (!value.call_charge) {
      errors.call_charge = "Call Charge is required";
    } else if (value.call_charge < 1) {
      errors.call_charge = "Call Charge should be greater than zero";
    }
    if (!value.sf_total_payout) {
      errors.sf_total_payout = "Sf Payout is required";
    } else {
      if (value.sf_total_payout > value.call_charge) {
        errors.sf_total_payout =
          "Sf Payout should not be greater than Call Charge";
      }
    }
    if (!value.gstrate) {
      errors.gstrate = "GST Rate is required";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest("addcallcharges", formData, "POST");
        setSubmit(false);
        if (data.status === true && data.message) {
          document.getElementById("addcallcharge").reset();

          // setReload(true);
          setFormData({
            free_for_customer: 1,
            gstrate: 18,
            serial_number_pod: false,
            invoice_pod: false,
          });
          setApplianceValue(null);
          setBrandValue(null);
          setCategoryValue(null);
          setCapacityValue(null);
          setCallTypeValue(null);
          setCharges([
            { location: 0, amount: "", days: 0 },
          ]);
          if (data.data.length > 0) {
            if (data.data.length > 0) {
              setUploadedData(data.data);
            }

            setTimeout(function () {
              setSubmit(false);
              goToViolation();
            }, 500);
          }

          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  return (
    <>
      <MainBody
        breadcrumbTitle="Add New Call Charges"
        breadcrumbSubTitle={""}
        navButton={""}
        title={""}
        component={
          <>
            <form onSubmit={onSubmitHandler} id="addcallcharge">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    {/* left column */}
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Add Charges</h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Partner *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.partner_id}
                                  </span>
                                </label>
                                <Select
                                  name="partner_id"
                                  options={partnerlist}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      partner_id: val.value,
                                    });
                                    setPartnerId(val.value);
                                    getApplianceList(val.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Appliance *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.appliance_id}
                                  </span>
                                </label>
                                <Select
                                  name="appliance_id"
                                  options={opAppiance}
                                  value={appliaceValue}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      appliance_id: val.value,
                                    });
                                    setApplianceValue(val);
                                    getBrandlList(val.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Brand *
                                  <span style={{ color: "red" }}>
                                    {formErrors.brand}
                                  </span>
                                </label>
                                <Select
                                  isMulti={true}
                                  name="brand"
                                  value={brandValue}
                                  options={opBrand}
                                  onChange={(val) => {
                                    setFormData({ ...formData, brand: val });
                                    setBrandValue(val);
                                    getCategoryList(val);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Category *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.category}
                                  </span>
                                </label>
                                <Select
                                  isMulti={true}
                                  name="category"
                                  value={categoryValue}
                                  options={opCategory}
                                  onChange={(val) => {
                                    setFormData({ ...formData, category: val });
                                    setCategoryValue(val);
                                    getCapacityList(val);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Capacity *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.capacity}
                                  </span>
                                </label>
                                <Select
                                  isMulti={true}
                                  name="capacity"
                                  value={capacityValue}
                                  options={opCapacity}
                                  onChange={(val) => {
                                    setFormData({ ...formData, capacity: val });
                                    setCapacityValue(val);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Call Type *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.calltype}
                                  </span>
                                </label>
                                <Select
                                  name="calltype"
                                  value={calltypeValue}
                                  options={opCalltype}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      calltype: val.value,
                                    });
                                    setCallTypeValue(val);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Call Charge *
                                  <span style={{ color: "red" }}>
                                    {formErrors.call_charge}
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  step=".01"
                                  min={0}
                                  defaultValue={"0"}
                                  name="call_charge"
                                  className={
                                    !formErrors.call_charge
                                      ? "form-control"
                                      : "is-invalid form-control"
                                  }
                                  placeholder="Enter Call Charge"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [e.target.name]: e.target.value
                                        .replace(/['"]+/g, "")
                                        .toUpperCase(),
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Sf Payout(With tax) *
                                  <span style={{ color: "red" }}>
                                    {formErrors.sf_total_payout}
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  step=".01"
                                  min={0}
                                  name="sf_total_payout"
                                  className={
                                    !formErrors.sf_total_payout
                                      ? "form-control"
                                      : "is-invalid form-control"
                                  }
                                  placeholder="Enter Call Charge"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [e.target.name]: e.target.value
                                        .replace(/['"]+/g, "")
                                        .toUpperCase(),
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  SF GST Rate *{" "}
                                  <span style={{ color: "red" }}>
                                    {formErrors.gstrate}
                                  </span>
                                </label>
                                <Select
                                  name="gstrate"
                                  defaultValue={taxrateValue}
                                  options={optaxtrate}
                                  onChange={(val) => {
                                    setFormData({
                                      ...formData,
                                      gstrate: val.value,
                                    });
                                    setTaxRateValue(val);
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <div className="form-group">
                                <label>
                                  Free For Customer
                                  <span style={{ color: "red" }}>
                                    {formErrors.free_for_customer}
                                  </span>
                                </label>
                                <input
                                  type="checkbox"
                                  defaultChecked={true}
                                  className={
                                    !formErrors.free_for_customer
                                      ? "form-control "
                                      : "is-invalid form-control "
                                  }
                                  id="checkboxPrimary1"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      free_for_customer: e.target.checked,
                                    });
                                  }}
                                  name="free_for_customer"
                                />
                              </div>
                            </div>

                            <div
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <div className="form-group">
                                <label>Serial Number POD</label>
                                <input
                                  type="checkbox"
                                  defaultChecked={false}
                                  className={
                                    !formErrors.serial_number_pod
                                      ? "form-control"
                                      : "is-invalid form-control "
                                  }
                                  id="checkboxPrimary2"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [e.target.name]: e.target.checked,
                                    });
                                  }}
                                  name="serial_number_pod"
                                />
                              </div>
                            </div>

                            <div
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <div className="form-group">
                                <label>Invoice POD</label>
                                <input
                                  type="checkbox"
                                  defaultChecked={false}
                                  className={
                                    !formErrors.invoice_pod
                                      ? "form-control"
                                      : "is-invalid form-control "
                                  }
                                  id="checkboxPrimary3"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [e.target.name]: e.target.checked,
                                    });
                                  }}
                                  name="invoice_pod"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Upcountry</label>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="upcountry"
                                    id="non-upcountry"
                                    value="0"
                                    checked={formData.upcountry === 0}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        upcountry: parseInt(e.target.value, 10),
                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="non-upcountry"
                                  >
                                    Non Upcountry (Local)
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="upcountry"
                                    id="upcountry-paid-partner"
                                    value="1"
                                    checked={formData.upcountry === 1}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        upcountry: parseInt(e.target.value, 10),
                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="upcountry-paid-partner"
                                  >
                                    Upcountry paid by Partner
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="upcountry"
                                    id="upcountry-paid-customer"
                                    value="2"
                                    checked={formData.upcountry === 2}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        upcountry: parseInt(e.target.value, 10),
                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="upcountry-paid-customer"
                                  >
                                    Upcountry Paid by Customer
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Vendor TAT Condition</h3>
                          <div className="card-tools">
                            It is not applicable for OW Call
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="charges-section">
                                {charges.length > 0 && charges.map((charge, index) => (
                                  <div key={index} className="charge-row">
                                    <Select
                                      options={[
                                        { value: 0, label: "Local" },
                                        { value: 1, label: "Upcountry" },
                                      ]}
                                      value={{
                                        value: charge.location,
                                        label:
                                          charge.location === 0
                                            ? "Local"
                                            : "Upcountry",
                                      }}
                                      onChange={(val) =>
                                        handleChargeChange(
                                          index,
                                          "location",
                                          val.value
                                        )
                                      }
                                      className="charge-select"
                                    />
                                    <Select
                                      options={[
                                        { value: 0, label: "Without Spare" },
                                        { value: 1, label: "With Spare" },
                                      ]}
                                      value={{
                                        value: charge.is_spare,
                                        label:
                                          charge.is_spare === 0
                                            ? "Without Spare"
                                            : "With Spare",
                                      }}
                                      onChange={(val) =>
                                        handleChargeChange(
                                          index,
                                          "is_spare",
                                          val.value
                                        )
                                      }
                                      className="charge-select"
                                    />
                                    <input
                                      type="number"
                                      step=".01"
                                      min={0}
                                      className="form-control charge-input"
                                      placeholder="Payout"
                                      value={charge.amount}
                                      onChange={(e) =>
                                        handleChargeChange(
                                          index,
                                          "amount",
                                          parseFloat(e.target.value)
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="form-control charge-input"
                                      value={`${charge.days} Day`}
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() =>
                                        handleRemoveChargeRow(index)
                                      }
                                    >
                                      -
                                    </button>
                                    {index === charges.length - 1 && (
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleAddChargeRow}
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-center">
                            <button
                              type="submit"
                              id="submit"
                              className="btn btn-primary col-md-6"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </form>
            <section
              className={`content ${
                tuploadedData.length > 0 ? "" : "hide_display"
              }`}
              id="duplicate_order"
              ref={violationRef}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Charges Already Uploaded</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <table className="table table-bordered  table-hover table-striped data dataTable no-footer ">
                            <thead>
                              <tr>
                                <th>SNo.</th>
                                <th>Appliance</th>
                                <th>Brand</th>
                                <th>Category</th>
                                <th>Capacity</th>
                                <th>Call Type</th>
                                <th>Call Charge</th>
                                <th>Customer Payable</th>
                                <th>Sf Total Payout(With Tax)</th>
                                <th>Sf GST Rate (%)</th>
                                <th>Invoice POD</th>
                                <th>Serial Number POD</th>
                                <th>Created By</th>
                                <th>Create Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tuploadedData.map((element, index) => {
                                return (
                                  <>
                                    {element[0].is_created == 0 && (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{element[0].appliance_name}</td>
                                        <td>{element[0].brand}</td>
                                        <td>{element[0].category}</td>
                                        <td>{element[0].capacity}</td>
                                        <td>{element[0].call_type}</td>
                                        <td>{element[0].call_charges}</td>
                                        <td>{element[0].customer_payable}</td>
                                        <td>{element[0].sf_total_payout}</td>
                                        <td>{element[0].sf_gst_rate}</td>
                                        <td>
                                          {element[0].invoice_pod == 1
                                            ? "YES"
                                            : "NO"}
                                        </td>
                                        <td>
                                          {element[0].serial_number_pod == 1
                                            ? "YES"
                                            : "NO"}
                                        </td>
                                        <td>{element[0].agent_name}</td>
                                        <td>
                                          {new Date(
                                            element[0].createdAt
                                          ).toDateString()}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
};

export default AddCharges;
