import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateHeaderProps } from "../reducers/headerProps";

const MainBody = ({ breadcrumbTitle, breadcrumbSubTitle, navButton, title, component}) => {
  let dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(
      updateHeaderProps({
        breadcrumbTitle,
        breadcrumbSubTitle,
        navButton
      })
    );
  }, [breadcrumbTitle, breadcrumbSubTitle, navButton]);
  return (
    <div>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <div>{ title }</div>
              </div>
            </div>
          </div>
        </section>
        {/* Main content */}

        { component }
       
        {/* /.content */}
      </div>
    </div>
  );
};

export default MainBody;
