import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState = {
  isLoggedIn: !!localStorage.getItem("token"),
  token: localStorage.getItem("token"),
};

const counterSlice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    login(state, action) {

      localStorage.setItem("token", action.payload.token);
      return (state = {
        ...state,
        isLoggedIn: true,
        token: action.payload.token,
      });
    },
    logout(state) {
      localStorage.removeItem("token");
      return {
        ...state,
        isLoggedIn: false,
        token: null,
      };
    },
    getUserDetails(state){
      const user  = jwtDecode(localStorage.getItem("token"))
      return {
        ...state,
        currentUser: user.userDetails
      }
    }
  },
});

export const { login, logout, getUserDetails } = counterSlice.actions;
export default counterSlice.reducer;