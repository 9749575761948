import { S3 } from 'aws-sdk';
import httpRequest from './Http';
import { GET_S3_CREDENTIALS_URL } from './ApiUrls';

export const UploadFileToS3 = async (folderName, file) => {
    const s3Credentials =  await httpRequest(GET_S3_CREDENTIALS_URL, {}, "GET");
    if (s3Credentials.status === true) {
        window["showloadingmodel"]();
        const s3 = new S3(s3Credentials.data);
        const file_name = Math.random().toString(36).slice(2);
        const extension = file.name.split('.').pop();
        const mimetype = file.type; // Use file.type to get the MIME type
        console.log("Mime Type:", mimetype);
        const folderFilename = `${folderName}/${file_name}.${extension}`;

        const uploadUrl = await s3.getSignedUrlPromise('putObject', {
            Bucket: s3Credentials.Bucket,
            Key: folderFilename,
            ContentType: mimetype,
            Expires: 120,
        });

        console.log(uploadUrl);

        const response = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                "Content-Type": mimetype
            },
            body: file
        });

        if (response.ok) {
            console.log('Upload successful');
            window["distroyloadingmodel"]();
            return folderFilename;
        } else {
            console.error('Upload failed:', response);
            window["distroyloadingmodel"]();
            return false;
        }
    } else {
        return false;
    }
}
