import React, { useEffect, useState } from "react";
import Select from "react-select";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import MyDataTable from "../../../component/MyDataTable";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { PARTNER_TYPE } from "../../../constant/status_constant";

const MslDisputedList = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({sender_type: 2});
  const [senderlist, setSenderlist] = useState([]);
  const [warehouselist, setWarehouselist] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [reloadList, setReload] = useState(true);
  const entityTypeList = [
    { value: 2, label: "Partner" },
    // { value: 3, label: "Service Center" },
  ];

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
      const warehouse = await httpRequest("getcwhdropdownlist", {}, "GET");
      if (warehouse.status === true && warehouse.data) {
        setWarehouselist(warehouse.data);
      }
    })();
  }, []);

  useEffect(() => {

    if(Number(loggedInUser.entity_type) === PARTNER_TYPE){
      setFormData({
        ...formData, sender_type: PARTNER_TYPE, sender_id: loggedInUser.entity_id
      })
    }

  }, [loggedInUser])

  useEffect(() => {
    (async () => {
      if (formData.sender_type && formData.receiver_id) {
        const sender = await httpRequest(
          `getmsldisputedsenderdropdownlist?receiver_id=${formData.receiver_id}&sender_type=${formData.sender_type}`,
          {},
          "GET"
        );
        if (sender.status === true && sender.data) {
          setSenderlist(sender.data);
        }
      }
    })();
  }, [formData.sender_type, formData.receiver_id]);

  const validateFrom = (value) => {
    const errors = {};
    if (!value.receiver_id) {
      errors.receiver_id = "Central Warehouse is Required";
    }
    if (!value.sender_type) {
      errors.sender_type = "Sender Type is Required";
    }
    if (!value.sender_id) {
      errors.sender_id = "Sender is Required";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        setReload(true);
        setSubmit(false);
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const columns = [
    {
        name: "SNo",
        selector: (row, index) => index +1,
        wrap: true,
    },
    {
        name: "Status",
        wrap: true,
        selector: (row) => (
          row.cancellation_date ? 'Cancelled' :
          (
            Number(row.status) === 3 ? "Not Received" : // if 
                Number(row.status) === 2 ? "Damage Received" : // else if 
                    '' // else 
        )
        ),
        wrap: true,

    },
    {
        name: "MSL ID",
        selector: (row) => row.msl_id,
        cell: (row) => <div className="word-wrap"><NavLink to={"/getmslpartlist/" + row.msl_pid} className="nav-link">{row.msl_id}</NavLink></div>,

    },
    {
        name: "MSL Date",
        selector: (row) => moment(row.msl_date).format('DD-MM-YYYY'),
        wrap: true,

    },
    {
        name:  <div className="word-wrap">Part Number</div>,
        selector: (row) => row.part_number,
        wrap: true,
    },
    {
        name: "Part Name",
        selector: (row) =>row.part_name,
        wrap: true,
    },
    {
        name: <div className="word-wrap">Total Quantity</div>,
        selector: (row) => row.total_part_quantity,
        wrap: true,

    },
    {
        name: <div className="word-wrap">Disputed Quantity</div>,
        wrap: true,
        selector: (row) => row.disputed_quantity,
        wrap: true,

    },
    {
        name: <div className="word-wrap">Acknowlege Date</div>,
        selector: (row) => row.ack_date,
        wrap: true,

    },
    {
        name: <div className="word-wrap">Serial Number</div>,
        selector: (row) => row.serial_number,
        wrap: true,
    },
    {
        name: <div className="word-wrap">Courier Name</div>,
        selector: (row) => row.courier_name,
        wrap: true,

    },
    {
        name: <div className="word-wrap">Docket Number</div>,
        selector: (row) => row.awb_number,
        wrap: true,

    },
    {
        name: <div className="word-wrap">Courier Shippment Date</div>,
        selector: (row) => moment(row.courier_shippment_date).format('DD-MM-YYYY'),
        wrap: true,
    },

];

  return (
    <div>
      <MainBody
        breadcrumbTitle="Part Not Received"
        breadcrumbSubTitle={"Damage Part Received"}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <form
                  id="mslintranist"
                  onSubmit={onSubmitHandler}
                  encType="multipart/form-data"
                >
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Filter</h3>
                      
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Central Warehouse *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_id}
                              </span>
                            </label>
                            <Select
                              name="receiver_id"
                              value={
                                warehouselist.find(
                                  (option) =>
                                    option.value === formData.receiver_id
                                ) || null
                              }
                              options={warehouselist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  receiver_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        {Number(loggedInUser.entity_type) !== PARTNER_TYPE && (
                          <>
                           <div className="col-md-3">
                          <div className="form-group is-invalid ">
                            <label>
                              Sender Type *
                              <span style={{ color: "red" }}>
                                {formErrors.sender_type}
                              </span>
                            </label>
                            <Select
                              name="sender_type"
                              options={entityTypeList}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  sender_type: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Sender Name *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_id}
                              </span>
                            </label>
                            <Select
                              name="sender_id"
                              value={
                                senderlist.find(
                                  (option) =>
                                    option.value === formData.sender_id
                                ) || null
                              }
                              options={senderlist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  sender_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                          </>
                        )}
                       

                        <div className="col-md-3">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>
                              .{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_file}
                              </span>
                            </label>
                            <input
                              type="submit"
                              className="form-control btn btn-primary"
                              value={"Submit"}
                            />
                          </div>

                          {/* /.form-group */}
                        </div>
                        {/* /.col */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Msl Disputed List</h3>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={`getwhdisputedmsl?receiver_id=${formData.receiver_id}&sender_type=${formData.sender_type}&sender_id=${formData.sender_id}`}
                        setReload={setReload}
                        exportName={"intransitmsl"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default MslDisputedList;
