import React from "react";
const DATA_NOT_FOUND = () => { 
    
    return (
        <>

  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          {/* <h1>404 Error Page</h1> */}
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="error-page">
      <h2 className="headline text-warning"> 404</h2>
      <div className="error-content">
        <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Data not found.</h3>
        <p>
          We could not find the page you were looking for.
          Meanwhile, you may <a href="/dashboard">return to dashboard</a>.
        </p>
      </div>
      {/* /.error-content */}
    </div>
    {/* /.error-page */}
  </section>
  {/* /.content */}

        </>
    )
}

export default DATA_NOT_FOUND;