import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainBody from "../../../component/MainBody";
import MyDataTable from "../../../component/MyDataTable";
import Switch from "../../../component/Switch";
import httpRequest from "../../../utils/Http";
import Select from "react-select";

const CancellationReason = () => {
  const [reloadList, setReload] = useState(true);
  const [reason, setReason] = useState("");
  const [formData, setFormData] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isOpenForm, setOpenForm] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const updateHandler = (id, reason, type) => {
    setReason(reason);
    setFormData({ ...formData, reason: reason, id, type });
    setOpenForm(true);
  };

  const typeList = [
    { label: "BOOKING", value: "booking" },
    { label: "SPAREPARTS", value: "spareparts" },
    { label: "MSL", value: "msl" },
  ];

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
    },
    {
      name: "Type",
      selector: (row) => row.type.toUpperCase(),
    },
    {
      name: "Status",
      selector: (row) =>
        Number(row.is_active) === 1 ? "Activated" : "DeActivated",
      cell: (row) => (
        <Switch
          isOn={Number(row.is_active) === 1 ? true : false}
          id={row.id}
          handleToggle={(e) => {
            updateToggleHandler(row.id, e.target.checked);
          }}
          onColor="#EF476F"
        />
      ),
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
    {
      name: "Update",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            updateHandler(row.id, row.reason, row.type);
          }}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  const updateToggleHandler = async (id, value) => {
    try {
      setReload(false);
      let postData = {
        id,
        is_active: value === true ? 1 : 0,
      };
      const data = await httpRequest(
        "updatecancellationreasonstatus",
        postData,
        "POST"
      );

      if (data.status === true && data.message) {
        setReload(true);

        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {}
  };
  const closeForm = () => {
    setOpenForm(false);
  };

  const validateFrom = (value) => {
    const errors = {};
    if (!value.reason) {
      errors.reason = "Cancellation Reason is required";
    }

    if (!value.type) {
      errors.type = "Type is required";
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateFrom(formData));
    setSubmit(true);
    //console.log(formData);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setSubmit(false);
      (async () => {
        const data = await httpRequest(
          "addupdatecancellationreason",
          formData,
          "POST"
        );

        if (data.status === true && data.message) {
          setReload(true);

          toast.success(data.message);
          setReason("");
          setFormData("");
          closeForm();
        } else {
          toast.success(data.message);
        }
      })();
    }
    //eslint-disable-next-line
  }, [formErrors, isSubmit]);

  const formAddUpdate = () => {
    return (
      <>
        <div className="form-overlay">
          <div className="form-container" style={{ maxWidth: "500px" }}>
            <div className="form-content">
              <form id="assignPartner" onSubmit={onSubmitHandler}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card" style={{ marginBottom: "0px" }}>
                      <div className="card-header">
                        <h3 className="card-title">
                          {formData.id
                            ? "Update Cancellation Reason"
                            : "Add Cancellation Reason"}
                        </h3>
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={closeForm}
                          style={{ float: "right" }}
                        >
                          X
                        </button>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Cancellation Reason *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.reason}
                                </span>
                              </label>
                              <input
                                type="text"
                                className={
                                  !formErrors.reason
                                    ? "form-control form-icon-input text-capitalize"
                                    : "is-invalid form-icon-input form-control text-capitalize"
                                }
                                defaultValue={reason}
                                name="reason"
                                placeholder="Enter Cancellation Reason"
                                id="reason"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                Type *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.type}
                                </span>
                              </label>
                              <Select
                                name="type"
                                value={
                                  typeList.find(
                                    (option) => option.value === formData.type
                                  ) || null
                                }
                                options={typeList}
                                placeholder="Select Type"
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    type: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer text-center"
                        style={{
                          marginBottom: "60px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-primary col-md-12"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <MainBody
        breadcrumbTitle="Cancellation Reason"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Cancellation Reason List</h3>
                        <Link
                          className="btn btn-sm btn-primary float-right"
                          to={"#"}
                          onClick={() => {
                            setOpenForm(true);
                            setFormData({});
                            setReason("");
                          }}
                        >
                          Add
                        </Link>
                      </div>
                      <div className="card-body">
                        <MyDataTable
                          reloadList={reloadList}
                          columns={columns}
                          getDataListURL={
                            "getcancellationreasonlist?is_active=all"
                          }
                          setReload={setReload}
                          exportName={"cancellationreasonlist"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isOpenForm && formAddUpdate()}
            </section>
          </>
        }
      />
    </div>
  );
};

export default CancellationReason;
