import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainBody from "../../../component/MainBody";
import MyDataTable from "../../../component/MyDataTable";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "../../../component/Switch";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../reducers/auth";

const Partner = () => {
  const user = useSelector((state) => state.authReducer.currentUser);
  const [reloadList, setReload] = useState(true);
  let dispatch = useDispatch();
  const updateToggleHandler = async (partner_id, value) => {
    try {
      setReload(false);
      let postData = {
        partner_id,
        is_active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updatepartnerstatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);

        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {}
  };

  const partnerLogin = async (partner_id) => {
    try {
      const data = await httpRequest("partnerlogin", { partner_id }, "POST");
      if (data.status === true && data.message) {
        dispatch(
          login({
            token: data.message,
          })
        );
        toast.success("Authentication Successful");
        window.location.reload();
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {}
  };

  const columns = [
    {
      name: "Display Name",
      selector: (row) => row.display_name,
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      cell: (row) => <div className="word-wrap">{row.company_name}</div>,
    },
    {
      name: "Company Type",
      selector: (row) => row.company_type,
    },
    {
      name: "Address",
      selector: (row) =>
        row.company_address +
        ", " +
        row.city +
        ", Pincode: " +
        row.pincode +
        ", " +
        row.state,
      cell: (row) => (
        <div className="word-wrap">
          {row.company_address +
            ", " +
            row.city +
            ", Pincode: " +
            row.pincode +
            ", " +
            row.state['state']}
        </div>
      ),
    },
    user.role_id == 1 && {
      name: "Status",
      selector: (row) =>
        Number(row.is_active) === 1 ? "Activated" : "DeActivated",
      cell: (row) => (
        <Switch
          isOn={Number(row.is_active) === 1 ? true : false}
          id={row.partner_id}
          handleToggle={(e) => {
            updateToggleHandler(row.partner_id, e.target.checked);
          }}
          onColor="#EF476F"
        />
      ),
    },
    {
      name: "Edit",
      selector: (row) => (
        <Link
          to={`/updatepartner/${row.partner_id}`}
          className="btn btn-md btn-primary"
        >
          <i className="fas fa-pencil"></i>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    user.role_id == 1 && {
      name: "Login",
      selector: (row) => (
        <button
          onClick={() => {
            partnerLogin(row.partner_id);
          }}
          className="btn btn-md btn-success"
        >
          <i className="fas fa-sign-in"></i>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);

  
  return (
    <>
      <MainBody
        breadcrumbTitle="Partners"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Partner List</h3>

                        {user && Number(user.role_id) === 1 && (
                          <Link
                          className="btn btn-sm btn-primary float-right"
                          to={"/addpartner"}

                        >
                          Add New Partner
                        </Link>
                        )}
                      </div>
                      <div className="card-body">
                        <MyDataTable
                          reloadList={reloadList}
                          columns={columns}
                          getDataListURL={"getpartnerlist?is_active=all"}
                          setReload={setReload}
                          exportName={"partnerlist"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
};

export default Partner;
