import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import MyDataTable from '../../../../component/MyDataTable';
import { getPartWarranty, openSignUrl } from '../../../../utils/HelperFunction';
import httpRequest from '../../../../utils/Http';

const TobeReceiveReverseParts = ({warehouse_id, setReload, reloadList}) => {
 // const [formErrors, setFormErrors] = useState({});
  const [rejectData, setRejectData] = useState({})
  const [rejectErrors, setRejectErrors] = useState({})
  const [rejectForm, setRejectForm] = useState(false)

  const handleReceiveClick = async (row) => {
    const confirmReceive = window.confirm('Are you sure you want to receive parts?');
    if (confirmReceive) {
      // Handle the receive logic here
      console.log('Parts received:', row);
      const data = await httpRequest(
        "receivereversepartbypartner",
        {
          spare_id: row.id,
          bid: row.bid,
          challan_id: row.defective_challan.id,
          courier_id: row.defective_challan.courier_shippment.courier_id
        },
        "POST"
      );

      if (data.status === true && data.message) {
        toast.success(data.message);
        setReload(true)
      } else {
        toast.error(data.message);
      }
    }
  };

  const handleRejectClick = async(row) => {
    setRejectData({
      spare_id: row.id,
      bid: row.bid,
      challan_id: row.defective_challan.id,
    })
      
    setRejectForm(true)

  }

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="word-wrap">Part Requested Id</div>,
      selector: (row) => row.id,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Booking ID</div>,
      selector: (row) => row.booking_detail.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
          {row.booking_detail.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Appliance</div>,
      selector: (row) => `${row.booking_detail.appliance.appliance_name}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Part Name / Number</div>,
      selector: (row) => `${row.requested_part.part_name} / ${row.requested_part.part_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Model Number / Serial Number</div>,
      selector: (row) => `${row.requested_model.model_number} / ${row.booking_detail.user_appliance_detail.sf_serial_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Warranty Status / Consumption</div>,
      selector: (row) => `${getPartWarranty(row.warranty_status)} / ${Number(row.consumption_id) === 1 ? "Part Consumed" : "Part Not Consumed"}`,
      wrap: true,
    },
    {
      name: <div className='word-wrap'>Challan Number</div>,
      selector: (row) => row.defective_challan.msl_id,
      cell: (row) => (
        <Link
          to={"javascript:void(0)"}
          onClick={() => openSignUrl(row.defective_challan.main_file)}
        >
          {row.defective_challan.msl_id}
        </Link>
      ),
      wrap: true,
    },
    {
      name: <div className='word-wrap'>Quantity</div>,
      selector: (row) => row.defective_challan.quantity,
      wrap: true,
    },
    {
      name: <div className='word-wrap'>Challan Date</div>,
      selector: (row) => row.defective_challan.msl_date,
      wrap: true,
    },
    {
      name: <div className='word-wrap'>Courier Name / Awb Number</div>,
      selector: (row) => `${row.defective_challan.courier_shippment.courier_name} / ${row.defective_challan.courier_shippment.awb_number}`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Reject</div>,
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={(e) => {
            handleRejectClick(row)
          }}
        >
          Reject
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: <div className="word-wrap">Receive</div>,
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleReceiveClick(row)}
        >
          Receive
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!rejectData.spare_id) {
      setRejectErrors({ ...rejectErrors, remarks: "Spare is Mandatory" });
      return;
    }

    if (!rejectData.remarks) {
      setRejectErrors({ ...rejectErrors, remarks: "Remarks is Mandatory" });
      return;
    }

    (async () => {
      const data = await httpRequest("rejectreversespare", rejectData, "POST");

      if (data.status === true && data.message) {
        toast.success(data.message);
        setReload(true);
        setRejectData({});
        setRejectForm(false);
      } else {
        toast.error(data.message);
      }
    })();
  };

  const formReject = () => {
    return (
      <>
        <div className="form-overlay">
          <div className="form-container" style={{maxWidth: "500px"}}>
            <div className="form-content">
              <form id="courierupdate" onSubmit={onSubmitHandler}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card" style={{ marginBottom: "0px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Reject Spare</h3>
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={() => {
                            setRejectData({})
                            setRejectForm(false)
                          }}
                          style={{ float: "right" }}
                        >
                          X
                        </button>
                      </div>

                      <div className="card-body">
                        <div className="row">

                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Remarks *{" "}
                                <span style={{ color: "red" }}>
                                  {rejectErrors.remarks}
                                </span>
                              </label>
                              <textarea
                                type="text"
                                name="remarks"
                                className="form-control"
                                value={rejectData.remarks}
                                placeholder="Enter Remarks"
                                onChange={(e) => {
                                  setRejectData({
                                    ...rejectData,
                                    remarks: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div
                        className="card-footer text-center"
                        style={{
                          marginBottom: "60px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-primary col-md-12"
                          style={{ marginLeft: "0px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              
                <MyDataTable
                  reloadList={reloadList}
                  columns={columns}
                  getDataListURL={`getreverseparttobereceivebywarehouse?warehouse_id=${warehouse_id}`}
                  setReload={setReload}
                  exportName={"partlist"}
                />
              
            </div>
          </div>
        </div>
        {rejectForm && formReject()}
      </section>
    </div>
  );
};

export default TobeReceiveReverseParts;
