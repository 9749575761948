import React, { useState } from "react";
import MyDataTable from "../../../component/MyDataTable";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "../../../component/Switch";
import { Link, NavLink } from "react-router-dom";
import MainBody from "../../../component/MainBody";
import { ONLY_RM_ASM_ACTION_PERMISSION } from "../../../constant/action_permission";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../reducers/auth";

const ServiceCenterList = () => {
  const user = useSelector((state) => state.authReducer.currentUser);
  const [reloadList, setReload] = useState(true);
  let dispatch = useDispatch();

  const updateToggleHandler = async (id, value) => {
    try {
      setReload(false);
      let postData = {
        id,
        active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updatesfstatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);

        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {}
  };

  const partnerLogin = async (partner_id) => {
    try {
      const data = await httpRequest("partnerlogin", { partner_id }, "POST");
      if (data.status === true && data.message) {
        dispatch(
          login({
            token: data.message,
          })
        );
        toast.success("Authentication Successful");
        window.location.reload();
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {}
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Display Name",
      selector: (row) => row.display_name,
      cell: (row) => (
        <NavLink
          className="word-wrap"
          to={"/updateservicecenter/" + row.id}
        >
          {row.display_name}
        </NavLink>
      ),
    },
    {
      name: <div className="word-wrao">Owner Name/ Phone Number</div>,
      selector: (row) =>
        row.owner_details !== null
          ? row.owner_details.name + " / " + row.owner_details.mobile_number
          : "",
      cell: (row) => (
        <div className="word-wrap">
          {row.owner_details !== null
            ? row.owner_details.name + " / " + row.owner_details.mobile_number
            : ""}
        </div>
      ),
    },
    {
      name: "Owner Email ID",
      selector: (row) => row.owner_details?.email,
      cell: (row) => (
        <div className="word-wrap">{row.owner_details?.email}</div>
      ),
    },
    {
      name: <div className="word-wrao">Poc Name/ Phone Number</div>,
      selector: (row) =>
        row.poc_details !== null
          ? row.poc_details.name + " / " + row.poc_details.mobile_number
          : "",
      cell: (row) => (
        <div className="word-wrap">
          {row.poc_details !== null
            ? row.poc_details.name + " / " + row.poc_details.mobile_number
            : ""}
        </div>
      ),
    },
    {
      name: "Poc Email ID",
      selector: (row) => row.poc_details?.email,
      cell: (row) => <div className="word-wrap">{row.poc_details?.email}</div>,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    ONLY_RM_ASM_ACTION_PERMISSION.includes(user.role_id) && {
      name: "Status",
      selector: (row) =>
        Number(row.active) === 1 ? "Activated" : "DeActivated",
      cell: (row) => (
        <Switch
          isOn={Number(row.active) === 1 ? true : false}
          id={row.id}
          handleToggle={(e) => {
            updateToggleHandler(row.id, e.target.checked);
          }}
          onColor="#EF476F"
        />
      ),
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
      wrap: true
    },
    {
      name: "Update",
      cell: (row) => (
        <NavLink
          className="btn btn-sm btn-primary"
          to={"/updateservicecenter/" + row.id}
        >
          Edit
        </NavLink>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    user.role_id == 1 && {
      name: "Login",
      selector: (row) => (
        <button
          onClick={() => {
            partnerLogin(row.id);
          }}
          className="btn btn-md btn-success"
        >
          <i className="fas fa-sign-in"></i>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);

  return (
    <>
      <MainBody
        breadcrumbTitle="Service Center"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card" style={{ padding: "10px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Service Center List</h3>
                        {ONLY_RM_ASM_ACTION_PERMISSION.includes(
                          user.role_id
                        ) && (
                          <Link
                            className="btn btn-sm btn-primary float-right"
                            to={"/addservicecenter"}
                          >
                            Add Service Center
                          </Link>
                        )}
                      </div>
                      <div className="card-body">
                        <MyDataTable
                          reloadList={reloadList}
                          columns={columns}
                          getDataListURL={"getsfdetails?acitve=all"}
                          setReload={setReload}
                          exportName={"servicecenterlist"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
};

export default ServiceCenterList;
