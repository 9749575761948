import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import { LABEL_JOB } from "../../../constant/label_constant";
import { CANCELLED_STATUS } from "../../../constant/status_constant";

const BookingCancel = () => {
  const navigate = useNavigate();
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [cancelList, setCancelList] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    (async () => {
      if (id) {
        const bid = atob(id);
        const data = await httpRequest("getcalldetails?id=" + bid, {}, "GET");

        if (data.status == true) {
          if (
            !data.bookingData.sc_closed_date &&
            !data.bookingData.closed_date
          ) {
            let isSpare = false;
            const spare_response = await httpRequest(`getsparedetailslist?bid=${bid}`, {}, "GET");
            if(spare_response.status){
              const spare = spare_response.data["rows"];
              spare.forEach(element => {
                if(element.status !== CANCELLED_STATUS){
                  isSpare = true;
                }
              });
            }
            
            if(isSpare){
              toast.error(
                `Part already involved in this booking.Cannot allow to cancel`
              );
              navigate("/pendingbooking");
            } else {
              setFormData({
                ...formData,
                booking_id: data.bookingData.booking_id,
                id: data.bookingData.id
              });
            }
          
          } else {
            toast.error(
              `${LABEL_JOB} already closed. You can not allow to cancel booking`
            );
            navigate("/pendingbooking");
          }
        } else {
          toast.error(`${LABEL_JOB} Not Found`);
          navigate("/404");
        }
      } else {
        toast.error(`${LABEL_JOB} Not Found`);
        navigate("/404");
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const cancel = await httpRequest(
        "getcancellationreasonlist?is_active=1&type=booking",
        {},
        "GET"
      );
      if (cancel.status === true && cancel.data) {
        setCancelList(cancel.data["rows"]);
      }
    })();
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    //console.log(value);
    if (!value.cancellation_id) {
      errors.cancellation_id = "Cancellation Reason is Required";
    } 

    if (!value.remarks) {
      errors.remarks = "Remarks is Required";
    }
    return errors;
  };

  useEffect(() => {
    (async () => {

      if (Object.keys(formErrors).length === 0 && isSubmit) {
        

        const data = await httpRequest(
          "processcancelbooking",
          formData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          document.getElementById("cancel").reset();
          toast.success(data.message);
          navigate("/pendingbooking");
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Cancel Booking"
        breadcrumbSubTitle={formData.booking_id}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <form className="form-horizontal" id="cancel" onSubmit={onSubmitHandler}>
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Cancel {LABEL_JOB}</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label
                              htmlFor="bookingid"
                              className="col-md-2 col-form-label"
                            >
                              {LABEL_JOB} Id *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.bookingid}
                              </span>
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control"
                                id="bookingid"
                                placeholder="Enter Booking Id"
                                name="bookingid"
                                value={formData.booking_id}
                                readOnly={true}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="cancellation_id"
                              className="col-md-2 col-form-label"
                            >
                              Cancellation Reason *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.cancellation_id}
                              </span>
                            </label>
                            <div className="col-md-4">
                              <Select
                                name="cancellation_id"
                                placeholder="Select Cancel Reason"
                                value={
                                  cancelList.find(
                                    (option) =>
                                      option.value === formData.cancellation_id
                                  ) || null
                                }
                                options={cancelList}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    cancellation_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="remarks"
                              className="col-md-2 col-form-label"
                            >
                              Remarks *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.remarks}
                              </span>
                            </label>
                            <div className="col-md-4">
                              <textarea
                                type="text"
                                className="form-control"
                                id="remarks"
                                placeholder="Enter Remarks"
                                name="remarks"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="offset-sm-3 col-sm-10">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default BookingCancel;
