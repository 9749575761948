import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpRequest from "../utils/Http";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  const validateFrom = (value) => {
    const errors = {};
    if (!value.password) {
      errors.password = "Enter password";
    } else if (value.password.length < 8) {
      errors.password = "Password must be 8 characters long";
    }

    if (value.password !== value.confirmPassword) {
      errors.confirmPassword = "Password does not match";
    }
    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      (async () => {
        const data = await httpRequest(
          "resetpassword",
          {
            ...formData,
          },
          "POST"
        );
        if (data.status === true && data.message) {
          setSubmit(false);

          toast.success("Password Reset Successfully");
        } else {
          toast.error(data.message);
          console.log(data.message);
        }
      })();
    }
    //eslint-disable-next-line
  }, [formErrors, isSubmit]);

  return (
    <div>
      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <Link to={"/"}>
              <b>Reset Password</b>
            </Link>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <form onSubmit={onSubmitHandler} method="post">
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className={
                      !formErrors.password
                        ? "form-control form-icon-input"
                        : "is-invalid form-icon-input form-control"
                    }
                    placeholder="Password"
                    name="password"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                  <div
                    className={!formErrors.password ? "" : "invalid-feedback"}
                  >
                    {formErrors.password}
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className={
                      !formErrors.confirmPassword
                        ? "form-control form-icon-input"
                        : "is-invalid form-icon-input form-control"
                    }
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                  <div
                    className={
                      !formErrors.confirmpassword ? "" : "invalid-feedback"
                    }
                  >
                    {formErrors.confirmpassword}
                  </div>
                </div>
                <div style={{ color: "#dc3545" }}>{formErrors.inputType}</div>
                <div className="row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      style={{ marginLeft: 0 }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
