import React from "react";
import { openSignUrl } from "../../../utils/HelperFunction";
import { Link } from "react-router-dom";
import {
  EMPLOYEE_TYPE,
  PARTNER_TYPE,
  SF_TYPE,
} from "../../../constant/status_constant";
import { toast } from "react-toastify";
import httpRequest from "../../../utils/Http";

const MiscChargeTab = ({ miscellaneousCharge, loggedInUser, setReload }) => {
  const deleteMiscCharge = async (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      const data = await httpRequest("deletemisccharge/" + id, {}, "GET");
      if (data.status === true && data.message) {
        toast.success(data.message);
        setReload(true);
      } else {
        toast.error(data.message);
      }
    }
  };

  return (
    <div>
      {miscellaneousCharge.length > 0 ? (
        <>
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>SNo.</th>
                <th>Service/Product</th>
                <th>Description</th>
                {Number(loggedInUser.entity_type) !== SF_TYPE && (
                  <th>Partner Charge</th>
                )}
                {Number(loggedInUser.entity_type) !== PARTNER_TYPE && (
                  <th>ASC Payout</th>
                )}
                {Number(loggedInUser.entity_type) !== SF_TYPE && (
                  <th>Approval File</th>
                )}
                {Number(loggedInUser.entity_type) === EMPLOYEE_TYPE && (
                  <th>Purchase File</th>
                )}
                <th>Added By</th>
                {Number(loggedInUser.entity_type) === EMPLOYEE_TYPE && (
                  <th>Delete</th>
                )}
              </tr>
            </thead>
            <tbody>
              {miscellaneousCharge.map((item, index) => (
                <tr key={index} className="text-center">
                  <td>{index + 1}</td>
                  <td>{item.service}</td>
                  <td>{`${item.description}`} </td>
                  {Number(loggedInUser.entity_type) !== SF_TYPE && (
                    <td>{item.partner_charge}</td>
                  )}
                  {Number(loggedInUser.entity_type) !== PARTNER_TYPE && (
                    <td>{item.sc_charge}</td>
                  )}
                  {Number(loggedInUser.entity_type) !== SF_TYPE && (
                    <td>
                      <Link
                        to={`#`}
                        onClick={() => openSignUrl(item.approval_file)}
                      >
                        View
                      </Link>
                    </td>
                  )}
                  {Number(loggedInUser.entity_type) === EMPLOYEE_TYPE && (
                    <td>
                      {item.purchase_file && (
                        <Link
                          to={`#`}
                          onClick={() => openSignUrl(item.purchase_file)}
                        >
                          View
                        </Link>
                      )}
                    </td>
                  )}
                  <td>{item.agent_detail?.name}</td>
                  {Number(loggedInUser.entity_type) === EMPLOYEE_TYPE && (
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteMiscCharge(item.id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <>There is not data to display</>
      )}
    </div>
  );
};

export default MiscChargeTab;
