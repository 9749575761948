import React, { useEffect, useState } from "react";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import { BACKEND_API_BASE_URL } from "../../../utils/BackendBaseUrl";

const CheckServiceAvailablity = () => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [applianceList, setApplianceList] = useState([]);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const appliance = await httpRequest(
          "getappliancelist?isactive=1",
          {},
          "GET"
        );
        //console.log(partners);
        if (appliance.status === true && appliance.data) {
          setApplianceList(appliance.data["rows"]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };

    fetchData();
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formData));
    if (Object.keys(formErrors).length === 0) {
      const response = await httpRequest(
        "checkserviceavailability",
        formData,
        "POST"
      );
      if (response.status === true && response.data) {
        setDataList(response.data);
      } else {
        toast.error(response.message);
        setDataList([]);
      }
    }
  };

  const validate = (value) => {
    const errors = {};
    if (!value.pincode) {
      errors.pincode = "Pincode is required";
    }
    return errors;
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle="Service Availablity"
        breadcrumbSubTitle=""
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <section className="col-lg-12 connectedSortable ui-sortable">
                    <div className="card">
                      <div
                        className="card-header ui-sortable-handle"
                        style={{ cursor: "move" }}
                      >
                        <h3 className="card-title">
                          Check Service Availablity
                        </h3>
                        <div className="card-tools">
                            <Link to={`${BACKEND_API_BASE_URL}downloadserviceavailabiltyreport`} target="_blank" className="btn btn-primary" >Download Serviceable Area</Link>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="tab-content p-0">
                          <div
                            className="card-header ui-sortable-handle"
                            style={{ cursor: "move" }}
                          >
                            <form
                              id="checkServiceAvailablity"
                              onSubmit={onSubmitHandler}
                            >
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Pincode{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.pincode}
                                      </span>
                                    </label>
                                    <input
                                      name="pincode"
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Pincode"
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          pincode: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Appliance{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.appliance_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="appliance_id"
                                      value={
                                        applianceList.find(
                                          (option) =>
                                            option.value ===
                                            formData.appliance_id
                                        ) || null
                                      }
                                      options={applianceList}
                                      onChange={(val) => {
                                        setFormData({
                                          ...formData,
                                          appliance_id: val.value,
                                        });
                                      }}
                                      isClearable
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      .{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.submit}
                                      </span>
                                    </label>
                                    <input
                                      name="submit"
                                      id="submit"
                                      type="submit"
                                      className="form-control btn btn-primary"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        {dataList.length > 0 && (
                          <div className="tab-content p-0">
                            <div
                              className="card-header ui-sortable-handle"
                              style={{ cursor: "move" }}
                            >
                              <div className="col-12">
                                <table className="table table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>SNo.</th>
                                      <th>ASC Name</th>
                                      <th>Appliance Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataList.map((item, index) => (
                                      <tr key={index} className="text-center">
                                        <td>{index + 1}</td>
                                        <td>
                                          {item?.service_centre?.display_name}
                                        </td>
                                        <td>
                                          {item?.appliance?.appliance_name}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default CheckServiceAvailablity;
