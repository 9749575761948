import React from "react";
import { NavLink } from "react-router-dom";
import MyDataTable from "../../../../component/MyDataTable";
import { getPartWarranty } from "../../../../utils/HelperFunction";

const PartInTransit = ({reloadList, setReload}) => {

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Booking ID",
      selector: (row) => row.booking_detail.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.booking_detail.id)}`}>
          {row.booking_detail.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: "Part Name",
      selector: (row) => row.shipped_part.part_name,
      wrap: true,
    },
    {
      name: "Part Number",
      selector: (row) => row.shipped_part.part_number,
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.shipped_quantity,
      wrap: true,
    },
    {
      name: "Part Warranty",
      selector: (row) => getPartWarranty(row.warranty_status),
      wrap: true,
    },
    {
      name: "Courier Name",
      selector: (row) => row.forward_challan.courier_shippment.courier_name,
      wrap: true,
    },
    {
      name: "Awb Number",
      selector: (row) => row.forward_challan.courier_shippment.awb_number,
      wrap: true,
    },
    {
      name: "Shippment Date",
      selector: (row) =>
        new Date(
          row.forward_challan.courier_shippment.shippment_date
        ).toDateString(),
      wrap: true,
    },
  ];

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Part In-Transit</h3>
                </div>
                <MyDataTable
                  reloadList={reloadList}
                  columns={columns}
                  getDataListURL={`getforwadedpartintransit?all=1`}
                  setReload={setReload}
                  exportName={"partintransit"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PartInTransit;
