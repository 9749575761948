import React, { useState, useEffect } from "react";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import Select from "react-select";
import { getdropdownList, openSignUrl } from "../../../utils/HelperFunction";
import {
  LABEL_APPLIACE_NAME,
  LABEL_BRAND,
  LABEL_JOB,
} from "../../../constant/label_constant";
import { NavLink } from "react-router-dom";
import MyDataTable from "../../../component/MyDataTable";
import moment from "moment";
import { useSelector } from "react-redux";
import { CANCELLED_STATUS } from "../../../constant/status_constant";

const CancelledBookingList = () => {
  // const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    partner_id: "",
    appliance_id: "",
    state_id: "",
    calltype_id: "",
  });
  const [stateList, setStateList] = useState([]);
  const [partnerlist, setPartnerlist] = useState([]);
  const [appliancelist, setApplianceList] = useState([]);
  const [calltypelist, setCalltypeList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [reloadList, setReload] = useState(true);
  const [isFilterOpen, setisFilterOpen] = useState(false);
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);


  useEffect(() => {
    (async () => {
      const state = await httpRequest("getallstatelist", {}, "GET");
      //console.log(partners);
      if (state.status === true && state.message) {
        setStateList(state.message);
      } else {
        setStateList([]);
      }

      const partners = await httpRequest(
        "getpartnerlist?is_active=1",
        {},
        "GET"
      );
      //console.log(partners);
      if (partners.status === true && partners.data) {
        setPartnerlist(partners.data["rows"]);
      }
      const appliance = await httpRequest(
        "getappliancelist?isactive=1",
        {},
        "GET"
      );
      //console.log(partners);
      if (appliance.status === true && appliance.data) {
        setApplianceList(appliance.data["rows"]);
      }

      getdropdownList(setCalltypeList, "getcalltypegrouplist?isactive=1");
    })();
    //eslint-disable-next-line
  }, []);
  const FilterComponent = () => {
    return (
      <section className={`content`}>
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">Filter </h3>
              <div
                className="card-tools"
                onClick={() => {
                  setisFilterOpen(false);
                }}
              >
                <i className="fas fa-minus"></i>
              </div>
            </div>
            <form id="addsubform" onSubmit={""}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Partner{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.partner_id}
                        </span>
                      </label>
                      <Select
                        name="partner_id"
                        value={
                          partnerlist.find(
                            (option) => option.value === formData.partner_id
                          ) || null
                        }
                        options={partnerlist}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            partner_id: val.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Appliance{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.appliance_id}
                        </span>
                      </label>
                      <Select
                        name="appliance_id"
                        value={
                          appliancelist.find(
                            (option) => option.value === formData.appliance_id
                          ) || null
                        }
                        options={appliancelist}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            appliance_id: val.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        Call Type{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.appliance_id}
                        </span>
                      </label>
                      <Select
                        name="calltype"
                        value={
                          calltypelist.find(
                            (option) => option.value === formData.calltype
                          ) || null
                        }
                        options={calltypelist}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            calltype: val.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        State{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.state_id}
                        </span>
                      </label>
                      <Select
                        name="state_id"
                        value={
                          stateList.find(
                            (option) => option.value === formData.state_id
                          ) || null
                        }
                        options={stateList}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            state_id: val.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="form-group">
                      <label>
                        .{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.submit}
                        </span>
                      </label>
                      <input
                        name="submit"
                        id="submit"
                        type="submit"
                        className="form-control btn btn-primary"
                        onClick={() => {
                          setReload(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: "Booking ID",
      selector: (row) => row.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.id)}`}>
          {row.booking_id}
        </NavLink>
      ),
    },
    {
      name: <div className="word-wrap">Customer Name / Mobile No</div>,
      wrap: true,
      selector: (row) =>
        row.user_detail["customer_name"] +
        "/ " +
        row.booking_primary_mobile_number,
      cell: (row) => (
        <div className="word-wrap">
          {row.user_detail["customer_name"]}
          <br />
          <NavLink to={`/search?keyword=${row.booking_primary_mobile_number}`}>
            {row.booking_primary_mobile_number}
          </NavLink>
        </div>
      ),
    },
    {
      name: <div className="word-wrap">{LABEL_APPLIACE_NAME}</div>,
      selector: (row) =>
        row.appliance["appliance_name"] + "/ " + row.calltype_group["type"],
      wrap: true,
    },
    {
      name: <div className="word-wrap">{LABEL_BRAND}</div>,
      selector: (row) =>
        row.user_appliance_detail["brand"].brand_name +
        "/" +
        row.user_appliance_detail["category"].category_name,
      cell: (row) => (
        <div className="word-wrap">
          {`${row.user_appliance_detail["brand"].brand_name}
               /
               ${row.user_appliance_detail["category"].category_name}`}
        </div>
      ),
    },
    {
      name: <div className="word-wrap">{LABEL_JOB + " Date"}</div>,
      selector: (row) => moment(row.booking_date).format("DD-MMM-YYYY"),
      cell: (row) => (
        <div className="word-wrap">
          {moment(row.booking_date).format("DD-MMM-YYYY")}
        </div>
      ),
    },
    {
      name: "Status",
      wrap: true,
      selector: (row) =>
        row.partner_current_status + " / " + row.partner_internal_status,
      cell: (row) => (
        <div className="word-wrap">
          {row.partner_current_status} / <br />
          {row.partner_internal_status}
        </div>
      ),
    },
    Number(loggedInUser.entity_type) === 1 && {
      name: <div className="word-wrap">Service Center</div>,
      wrap: true,
      selector: (row) => row.service_centre["display_name"],
      cell: (row) => (
        <div className="word-wrap">{row.service_centre["display_name"]}</div>
      ),
    },
    {
      name: "State",
      wrap: true,
      selector: (row) => row.state.state,
      cell: (row) => <div className="word-wrap">{row.state.state}</div>,
    },
    {
      name: "Job card",
      cell: (row) => (
        <>
          <button
            target={"_blank"}
            className="btn btn-sm btn-primary"
            onClick={() => {
              openSignUrl(row.jobcard_file);
            }}
          >
            <i className="fas fa-download" />
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },

  ].filter(Boolean);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Cancelled Booking"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            {isFilterOpen && <FilterComponent />}

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Cancelled {LABEL_JOB} List</h3>
                        <div
                          className="card-tools"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setisFilterOpen(true);
                          }}
                        >
                          <i className="fas fa-filter"></i>
                        </div>
                      </div>
                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={`getcompletedbookinglist?status=${CANCELLED_STATUS}&partner_id=${formData.partner_id}&calltype_id=${formData.calltype_id}&appliance_id=${formData.appliance_id}&state_id=${formData.state_id}`}
                        setReload={setReload}
                        exportName={"cancelleddlist"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default CancelledBookingList;
