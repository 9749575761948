import React, { useEffect, useState } from "react";
import Select from "react-select";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import MyDataTable from "../../../component/MyDataTable";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { PARTNER_TYPE } from "../../../constant/status_constant";

const MslInTransit = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({sender_type: 2});
  const [senderlist, setSenderlist] = useState([]);
  const [warehouselist, setWarehouselist] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [reloadList, setReload] = useState(true);
  const entityTypeList = [
    { value: 2, label: "Partner" },
    // { value: 3, label: "Service Center" },
  ];

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  useEffect(() => {
    (async () => {
      const warehouse = await httpRequest("getcwhdropdownlist", {}, "GET");
      if (warehouse.status === true && warehouse.data) {
        setWarehouselist(warehouse.data);
      }
    })();
  }, []);

  useEffect(() => {

    if(Number(loggedInUser.entity_type) === PARTNER_TYPE){
      setFormData({
        ...formData, sender_type: PARTNER_TYPE, sender_id: loggedInUser.entity_id
      })
    }

  }, [loggedInUser])

  useEffect(() => {
    (async () => {
      if (formData.sender_type && formData.receiver_id) {
        const sender = await httpRequest(
          `getmslsenderdropdownlist?receiver_id=${formData.receiver_id}&sender_type=${formData.sender_type}`,
          {},
          "GET"
        );
        if (sender.status === true && sender.data) {
          setSenderlist(sender.data);
        }
      }
    })();
  }, [formData.sender_type, formData.receiver_id]);

  const validateFrom = (value) => {
    const errors = {};
    if (!value.receiver_id) {
      errors.receiver_id = "Central Warehouse is Required";
    }
    if (!value.sender_type) {
      errors.sender_type = "Sender Type is Required";
    }
    if (!value.sender_id) {
      errors.sender_id = "Sender is Required";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        setReload(true);
        setSubmit(false);
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const handleRjectAction = async (row) => {
    const shouldPerformAction = window.confirm(
      "Are you sure you want to perform this action?"
    );
    if (shouldPerformAction) {
      const data = await httpRequest("rejectmslpid/" + row.msl_pid, {}, "POST");
      if (data.status === true && data.message) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setReload(true);
    }
  };

  const columns = [
    {
      name: "SNo",
      selector: (row, index) => index + 1,
    },
    {
      name: "MSL ID",
      selector: (row) => row.msl_id,
      cell: (row) => (
        <NavLink to={"/getmslpartlist/" + row.msl_pid} className="nav-link">
          {row.msl_id}
        </NavLink>
      ),
    },
    {
      name: "MSL Date",
      selector: (row) => moment(row.msl_date).format("DD-MM-YYYY"),
    },
    {
      name: "Courier Name",
      selector: (row) => row.courier_name,
    },
    {
      name: <div className="word-wrap">Docket Number</div>,
      selector: (row) => row.awb_number,
    },
    {
      name: <div className="word-wrap">Courier Shippment Date</div>,
      selector: (row) =>
        moment(row.courier_shippment_date).format("DD-MM-YYYY"),
    },
    {
      name: <div className="word-wrap">Total Quantity</div>,
      selector: (row) => row.msl_quantity,
    },
    {
      name: <div className="word-wrap">Quantity To be Acknowledged</div>,
      selector: (row) => row.quantity_to_be_ack,
    },
    Number(loggedInUser.entity_type) !== PARTNER_TYPE && {
      name: "Receive",
      cell: (row) => (
        <>
          <NavLink
            to={"/getmslackpartlist/" + row.msl_pid}
            className="btn btn-sm btn-primary"
          >
            Receive
          </NavLink>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: "Reject",
      cell: (row) => (
        <>
          {row.quantity_to_be_ack == row.msl_quantity && (
            <>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => {
                  handleRjectAction(row);
                }}
              >
                Reject
              </button>
            </>
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);

  return (
    <div>
      <MainBody
        breadcrumbTitle="In-Tranist MSL"
        breadcrumbSubTitle={"List"}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <form
                  id="mslintranist"
                  onSubmit={onSubmitHandler}
                  encType="multipart/form-data"
                >
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Filter</h3>
                      
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Central Warehouse *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_id}
                              </span>
                            </label>
                            <Select
                              name="receiver_id"
                              value={
                                warehouselist.find(
                                  (option) =>
                                    option.value === formData.receiver_id
                                ) || null
                              }
                              options={warehouselist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  receiver_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        {Number(loggedInUser.entity_type) !== PARTNER_TYPE && (
                          <>
                           <div className="col-md-3">
                          <div className="form-group is-invalid ">
                            <label>
                              Sender Type *
                              <span style={{ color: "red" }}>
                                {formErrors.sender_type}
                              </span>
                            </label>
                            <Select
                              name="sender_type"
                              options={entityTypeList}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  sender_type: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Sender Name *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.entity_id}
                              </span>
                            </label>
                            <Select
                              name="sender_id"
                              value={
                                senderlist.find(
                                  (option) =>
                                    option.value === formData.sender_id
                                ) || null
                              }
                              options={senderlist}
                              onChange={(val) => {
                                setFormData({
                                  ...formData,
                                  sender_id: val.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                          </>
                        )}
                       

                        <div className="col-md-3">
                          {/* /.form-group */}
                          <div className="form-group">
                            <label>
                              .{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.part_file}
                              </span>
                            </label>
                            <input
                              type="submit"
                              className="form-control btn btn-primary"
                              value={"Submit"}
                            />
                          </div>

                          {/* /.form-group */}
                        </div>
                        {/* /.col */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Msl Intransit</h3>
                      </div>

                      <MyDataTable
                        reloadList={reloadList}
                        columns={columns}
                        getDataListURL={`getintransitmsl?receiver_id=${formData.receiver_id}&sender_type=${formData.sender_type}&sender_id=${formData.sender_id}`}
                        setReload={setReload}
                        exportName={"intransitmsl"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default MslInTransit;
