import React, { useState, useEffect } from "react";
import Select from "react-select";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "../../../asset/css/excelcss.css";
import httpRequest from "../../../utils/Http";
import MyDataTable from "../../../component/MyDataTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { openSignUrl } from "../../../utils/HelperFunction";
import { ONLY_RM_ASM_ACTION_PERMISSION } from "../../../constant/action_permission";

const UploadSfServiceablefile = ({ sfdetails, roleId }) => {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [applianceops, setApplianceOps] = useState([]);
  const [rows, setRows] = useState(null);
  const [cols, setcols] = useState(null);
  const [isSubmit, setSubmit] = useState(false);
  const [reloadList, setReload] = useState(true);
  const [filterApplianceId, setFilterApplianceId] = useState("");

  useEffect(() => {
    (async () => {
      const listdata = await httpRequest(
        "getappliancelist?isactive=1",
        {},
        "GET"
      );
      if (listdata.status === true && listdata.data) {
        console.log(listdata.data["rows"]);
        setApplianceOps(listdata.data["rows"]);
      }
    })();
    //eslint-disable-next-line
  }, []);

  const validateFrom = (value) => {
    const errors = {};
    //console.log(value);
    if (!value.appliance_id) {
      errors.appliance_id = "Appliance Name is Required";
    }

    if (!value.file) {
      errors.pincode_file = "Pincode File is Required (Only xlsx & xls file)";
    }

    return errors;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    //console.log(formData);
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };
  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let formData1 = formData;
        //  let formData1 = new FormData();
        //  formData1.append("file", formData.file);
        //  formData1.append("appliance_id", formData.appliance_id);
        //  formData1.append("sf_id", sfdetails.id);

        // formData.sf_id = sfdetails.id;
        formData1 = {
          ...formData1,
          sf_id: sfdetails.id,
          appliance_id_json: JSON.stringify(formData1.appliance_id),
        };
        document.getElementById("uploadServiceabilityfile").reset();
        const data = await httpRequest(
          "uploadserviceablefile",
          formData1,
          "POST",
          "multipart/form-data"
        );

        if (data.status === true && data.message) {
          toast.success(data.message);
          setRows(null);
          setcols(null);
          setReload(true);
        } else {
          toast.error(data.message);
        }
        setSubmit(false);
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const columns = [
    {
      name: "SNo.",
      selector: (_, index) => index + 1,
    },
    {
      name: "File Name",
      selector: (row) => row.file_name,
      cell: (row) => (
        <Link
          to={"javascript:void(0)"}
          className="btn-link text-primary"
          onClick={() => openSignUrl(row.file_path)}
        >
          {row.file_name}
        </Link>
      ),
    },
    {
      name: "Agent Name",
      selector: (row) => row.agent_detail.name
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
  ];


  const pincodeColumns = [
    {
      name: "SNo.",
      selector: (_, index) => index + 1,
    },
    {
      name: "Pincode",
      selector: (row) => row.pincode,
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
    {
      name: "Delete",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => deleteHandler(row.id)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ];

  const deleteHandler = async(id) => {
    if(id){
      const data = await httpRequest("deleteserviceablepincode?id="+id, {}, "POST");
      if (data.status === true) {
        toast.success(data.message);
        setReload(true);
      } else {
        toast.error(data.message);
      }
    }
  }

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <form
            id="uploadServiceabilityfile"
            onSubmit={onSubmitHandler}
            encType="multipart/form-data"
          >
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Upload Serviceable File</h3>
                <span className="float-right">
                  <Link
                    to={"javascript:void(0)"}
                    className="btn-link text-primary"
                    onClick={() =>
                      openSignUrl("samplefolder/sfpincodeserviceable.xlsx")
                    }
                  >
                    Download Sample File
                  </Link>
                </span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group is-invalid ">
                      <label>
                        Appliance *
                        <span style={{ color: "red" }}>
                          {formErrors.appliance_id}
                        </span>
                      </label>
                      <Select
                        name="appliance_id"
                        options={applianceops}
                        isMulti={true}
                        onChange={(val) => {
                          setFormData({ ...formData, appliance_id: val });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* /.form-group */}
                    <div className="form-group">
                      <label>
                        Upload Pincode File *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.pincode_file}
                        </span>
                      </label>
                      <input
                        name="file"
                        type="file"
                        className="form-control"
                        accept=".xlsx, .xls"
                        onChange={(e) => {
                          var files = e.target.files;
                          if (files && files[0]) {
                            var file_name = e.target.files[0]["name"];
                            const regex = new RegExp("(.*?)(xlsx|xls)$");
                            if (!regex.test(file_name.toLowerCase())) {
                              setFormErrors({
                                pincode_file:
                                  "Pincode File is Required (Only xlsx & xls file)",
                              });
                            } else {
                              setRows(null);
                              setcols(null);
                              ExcelRenderer(e.target.files[0], (err, resp) => {
                                //handle fail case here
                                if (err) {
                                  // console.log(err);
                                }
                                //handle success case here
                                else {
                                  //  console.log(resp.cols);
                                  setRows(resp.rows);
                                  setcols(resp.cols);
                                }
                              });
                              setFormData({
                                ...formData,
                                file: e.target.files[0],
                              });
                            }
                          } else {
                            setFormErrors({
                              model: "Only xlsx & xls file allow",
                            });
                          }
                        }}
                      />
                    </div>

                    {/* /.form-group */}
                  </div>
                  {/* /.col */}
                </div>
                <div className="row">
                  <div className={rows && "col-md-12 restrict-card"}>
                    {rows && (
                      <OutTable
                        data={rows}
                        columns={cols}
                        tableClassName="table table-bordered table-striped dataTable dtr-inline"
                        tableHeaderRowClass="heading"
                      />
                    )}
                  </div>
                </div>
                <br />
                {ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && (
                  <div className="card-footer">
                    <div className="row">
                      <input
                        type="submit"
                        className="form-control btn btn-primary"
                        value={"Submit"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a
                          id="filehistory_tab"
                          className="nav-link active tabc"
                          href="#filehistory"
                          data-toggle="tab"
                        >
                          File Upload History
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          id="uploaded_pincode_tab"
                          className="nav-link tabc"
                          href="#uploaded_pincode"
                          data-toggle="tab"
                        >
                          Uploaded Pincode
                        </a>
                      </li>
                    </ul>
                  </h3>
                </div>
                <div className="tab-content">
                  <div className="tab-pane tabc active" id="filehistory">
                    <MyDataTable
                      reloadList={reloadList}
                      columns={columns}
                      getDataListURL={
                        `getfileuploadhistory?type=SF_PINCODE_EXCEL&entity_id=${sfdetails.id}`
                      }
                      setReload={setReload}
                      exportName={"fileuploadlist"}
                    />
                  </div>
                  <div className="tab-pane tabc " id="uploaded_pincode">
                    <div className="row" style={{ margin: "20px" }}>
                      <div className="col-md-6">
                        <div className="form-group is-invalid ">
                          <label>Appliance *</label>
                          <Select
                            name="appliance_id"
                            options={applianceops}
                            onChange={(val) => {
                              setFilterApplianceId(val.value);
                              setReload(true)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {filterApplianceId && sfdetails && (
                      <MyDataTable
                        reloadList={reloadList}
                        columns={pincodeColumns}
                        getDataListURL={`getuploadedserviceablepincode?appliance_id=${filterApplianceId}&sf_id=${sfdetails.id}`}
                        setReload={setReload}
                        exportName={"fileuploadlist"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadSfServiceablefile;
