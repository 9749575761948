import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import MainBody from "../../../component/MainBody";
import MyDataTable from "../../../component/MyDataTable";
import { openSignUrl } from "../../../utils/HelperFunction";
import moment from "moment";
import { PARTNER_TYPE, SF_TYPE } from "../../../constant/status_constant";
import httpRequest from "../../../utils/Http";
import { useSelector } from "react-redux";

const InvoiceSummary = () => {
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);
  const { entype } = useParams();
  const [entity_type, setEntityType] = useState(null);
  const [reloadList, setReload] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [formData, setFormData] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [transactionReload, setTransactionReload] = useState(true);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (entype) {
      const entity_type = atob(entype);
      setEntityType(entity_type);

      if (
        loggedInUser.entity_type !== PARTNER_TYPE &&
        loggedInUser.entity_type !== SF_TYPE
      ) {
        (async () => {
          if (Number(entity_type) === PARTNER_TYPE) {
            const partners = await httpRequest("getpartnerlist", {}, "GET");
            if (partners.status === true && partners.data) {
              setCompanyList(partners.data["rows"]);
              setFormData({});
            }
          } else if (Number(entity_type) === SF_TYPE) {
            const service_center = await httpRequest(
              "getsfdropdownlist",
              {},
              "GET"
            );
            if (service_center.status === true && service_center.data) {
              setCompanyList(service_center.data);
              setFormData({ ...formData, company_id: loggedInUser.entity_id });
            }
          }
        })();
      } else {
        setFormData({ ...formData });
      }
    }
  }, [entype]);

  useEffect(() => {
    //getinvoicecategory
    (async () => {
      const partners = await httpRequest(`getinvoicecategory`, {}, "GET");
      if (partners.status === true && partners.data) {
        setCategoryList(partners.data);
      }
    })();
  }, []);

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="wrap">Invoice Number</div>,
      selector: (row) => row.invoice_number,
      cell: (row) => (
        <div>
          <div>{row.invoice_number}</div>
          {row.main_file && (
            <div>
              <Link
                to={"#"}
                onClick={() => {
                  openSignUrl(row.main_file);
                }}
                style={{ marginTop: "7px" }}
              >
                Main File
              </Link>
            </div>
          )}
          {row.annx_file && (
            <div>
              <Link
                to={"#"}
                onClick={() => {
                  openSignUrl(row.annx_file);
                }}
                style={{ marginTop: "7px" }}
              >
                Annx File
              </Link>
            </div>
          )}
        </div>
      ),
      wrap: true,
    },

    {
      name: <div className="wrap">Category</div>,
      selector: (row) => row.category,
      wrap: true,
    },
    {
      name: <div className="wrap">Invoice Date</div>,
      selector: (row) => moment(row.invoice_date).format("D MMM YYYY"),
      wrap: true,
    },
    {
      name: <div className="wrap">Period</div>,
      selector: (row) =>
        row.start_date &&
        `${moment(row.start_date).format("D MMM YYYY")} - ${moment(
          row.end_date
        ).format("D MMM YYYY")}`,
      wrap: true,
    },
    {
      name: <div className="wrap">Quantity</div>,
      selector: (row) => row.quantity,
      wrap: true,
    },
    {
      name: <div className="wrap">Taxable Value</div>,
      selector: (row) => row.taxable_value,
      wrap: true,
    },
    {
      name: <div className="wrap">Gst Amount</div>,
      selector: (row) => row.gst_amount,
      wrap: true,
    },
    {
      name: <div className="wrap">Amount To Be Paid</div>,
      selector: (row) =>
        row.amount_to_be_collected < 0 ? row.amount_to_be_collected : 0,
      wrap: true,
    },
    {
      name: <div className="wrap">Amount To Be Received</div>,
      selector: (row) =>
        row.amount_to_be_collected > 0 ? row.amount_to_be_collected : 0,
      wrap: true,
    },
  ];

  const statementColumns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="wrap">Transaction Date</div>,
      selector: (row) => moment(row.transaction_date).format("D MMM YYYY"),
      wrap: true,
    },
    {
      name: <div className="wrap">Transaction Id</div>,
      selector: (row) => row.transaction_id,
      wrap: true,
    },

    {
      name: <div className="wrap">Remarks</div>,
      selector: (row) => row.remarks,
      wrap: true,
    },
    {
      name: <div className="wrap">Amount Received</div>,
      selector: (row) => (row.credit_debit === "Credit" ? row.amount : 0),
      wrap: true,
    },
    {
      name: <div className="wrap">Amount Paid</div>,
      selector: (row) => (row.credit_debit === "Debit" ? row.amount : 0),
      wrap: true,
    },
    {
      name: <div className="wrap">Bank Name / Mode</div>,
      selector: (row) => row.bank_name + " / " + row.transaction_mode,
      wrap: true,
    },
  ];

  useEffect(() => {
    if (entity_type && formData.company_id) {
      (async () => {
        const partners = await httpRequest(
          `getbalancesummary?entity_type=${entity_type}&entity_id=${formData.company_id}`,
          {},
          "GET"
        );
        if (partners.status === true) {
          setBalance(partners.balanceDifference);
        }
      })();
    }
  }, [entity_type, formData.company_id]);

  return (
    <div>
      <div>
        {entity_type !== null && (
          <MainBody
            breadcrumbTitle="Invoice Summary"
            breadcrumbSubTitle={"List"}
            navButton={""}
            title={
              <div className="row">
                {loggedInUser.entity_type !== PARTNER_TYPE &&
                  loggedInUser.entity_type !== SF_TYPE && (
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Company Name * </label>
                        <Select
                          name="company_id"
                          value={
                            companyList.find(
                              (option) => option.value === formData.company_id
                            ) || null
                          }
                          options={companyList}
                          onChange={(val) => {
                            setFormData({
                              ...formData,
                              company_id: val.value,
                            });
                            setReload(true);
                          }}
                        />
                      </div>
                    </div>
                  )}

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Category * </label>
                    <Select
                      name="category"
                      value={
                        categoryList.find(
                          (option) => option.label === formData.category
                        ) || null
                      }
                      options={categoryList}
                      isClearable
                      placeholder="Select Category"
                      onChange={(val) => {
                        setFormData({
                          ...formData,
                          category: val ? val.label : "undefined",
                        });
                        setReload(true);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3 ml-auto">
                  <div className="info-box">
                    <span className="info-box-icon bg-info">
                      <i className="fas fa-rupee-sign"></i>
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Balance</span>
                      <span className="info-box-number">
                        <b>
                          {balance > 0 ? (
                            <span style={{ color: "green" }}>{balance}</span>
                          ) : balance < 0 ? (
                            <span style={{ color: "red" }}>{balance}</span>
                          ) : (
                            balance
                          )}
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
            component={
              <>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="card" style={{ padding: "10px" }}>
                          <div className="card-header">
                            <h3 className="card-title">Invoice</h3>
                          </div>
                          <div className="card-body">
                            {entity_type && formData.company_id ? (
                              <MyDataTable
                                reloadList={reloadList}
                                columns={columns}
                                getDataListURL={`getinvoicedetails?entity_type=${entity_type}&entity_id=${formData.company_id}&category=${formData.category}`}
                                setReload={setReload}
                                exportName={"invoicesummary"}
                              />
                            ) : (
                              "No Record Found"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="card" style={{ padding: "10px" }}>
                          <div className="card-header">
                            <h3 className="card-title">Bank Statement</h3>
                          </div>
                          <div className="card-body">
                            {entity_type && formData.company_id ? (
                              <MyDataTable
                                reloadList={transactionReload}
                                columns={statementColumns}
                                getDataListURL={`getbanktransactionlist?entity_type=${entity_type}&entity_id=${formData.company_id}`}
                                setReload={setTransactionReload}
                                exportName={"bankstatement"}
                              />
                            ) : (
                              "No Record Found"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceSummary;
