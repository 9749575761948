import React from "react";
import Header from "../header/partner/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/admin/Dashboard";
import Footer from "../header/Footer";
import CreateBooking from "../pages/admin/booking/CreateBooking";
import PendingList from "../pages/admin/booking/PendingList";
import BookingDetails from "../pages/admin/booking/BookingDetails";
import DATA_NOT_FOUND from "../pages/404";
import AdvanceSearch from "../pages/admin/booking/AdvanceSearch";
import SpareParts from "../pages/partner/SpareParts";
import SingleDispatchParts from "../pages/partner/SingleDispatchParts";
import CompletedBookingList from "../pages/admin/booking/CompletedBookingList";
import CancelledBookingList from "../pages/admin/booking/CancelledBookingList";
import WarehouseStock from "../pages/admin/warehouse/WarehouseStock";
import PartList from "../pages/admin/partmaster/PartList";
import BomList from "../pages/admin/partmaster/BomList";
import MslInward from "../pages/admin/warehouse/MslInward";
import MslInTransit from "../pages/admin/warehouse/MslInTransit";
import MSLPartList from "../pages/admin/warehouse/MSLPartList";
import MslPartLineItem from "../pages/admin/warehouse/MslPartLineItem";
import MslDisputedList from "../pages/admin/warehouse/MslDisputedList";
import BookingSummary from "../pages/admin/reports/BookingSummary";
import SpareSummary from "../pages/admin/reports/SpareSummary";
import CustomerOOWInvoice from "../pages/admin/invoice/CustomerOOWInvoice";
import InvoiceSummary from "../pages/admin/invoice/InvoiceSummary";
import ResetPassword from "../pages/ResetPassword";


const PartnerRoutes = ({isLoggedIn}) => {

  const createRoutes = () => (
    <>
      <Route excat path="/dashboard" element={<Dashboard />} />
      <Route excat path="/createbooking" element={<CreateBooking/>} />
      <Route excat path="/pendingbooking" element={<PendingList/>} />
      <Route excat path="/getbookingdetails" element={<BookingDetails />} />
      <Route excat path="/updatebooking/:bid" element={<CreateBooking />} />
      <Route excat path="/spareparts" element={<SpareParts />} />
      <Route excat path="/dispatchparts/:id" element={<SingleDispatchParts />} />
      <Route excat path="/search" element={<AdvanceSearch />} />
      <Route excat path="/getcompletedlist" element={<CompletedBookingList />} />
      <Route excat path="/getcancelledlist" element={<CancelledBookingList />} />
      <Route excat path="/warehousestock" element={<WarehouseStock />} />
      <Route excat path="/partlist" element={<PartList />} />
      <Route path="/bomlist" element={<BomList />} />
      <Route excat path="/uploadinwardmsl" element ={<MslInward />} />
      <Route excat path="/whintranistmsl" element={<MslInTransit />} />
      <Route path="/getmslpartlist/:msl_pid" element={<MSLPartList />} />
      <Route path="/getpartitem/:purchase_item_id/:part_number" element={<MslPartLineItem />} />
      <Route excat path="/whmsldisputed" element={<MslDisputedList/>} />
      <Route excat path="/bookingsummary" element={<BookingSummary />} />
      <Route excat path="/bookingsummary/:filter" element={<BookingSummary />} />
      <Route excat path="/sparesummary" element={<SpareSummary />} />
      <Route excat path="/customeroowinvoice" element={<CustomerOOWInvoice />} />
      <Route excat path="/invoicesummary/:entype" element={<InvoiceSummary />} />
      <Route excat path="/404" element={<DATA_NOT_FOUND />} />
      <Route excat path="/resetpassword" element={<ResetPassword />} />
    </>
  );
  return (
    <div>
      <Header />
      <Routes>
        {createRoutes()}
        <Route
          excat
          path="*"
          element={<Navigate to={isLoggedIn ? "/dashboard" : "/"} />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default PartnerRoutes;