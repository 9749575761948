import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import httpRequest from "../../../utils/Http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddSFBasicDetailsTab({
  sfdetails,
  SetSfDetails,
  roleId,
}) {
  const navigate = useNavigate();
  const getCompanyType = [
    { value: "Individual", label: "Individual" },
    { value: "Proprietorship Firm", label: "Proprietorship Firm" },
    { value: "Partnership Firm", label: "Partnership Firm" },
    { value: "Private Ltd Company", label: "Private Ltd Company" },
    { value: "Public Ltd Company", label: "Public Ltd Company" },
  ];

  const getnon_working_days = [
    { value: "0", label: "All Working Days" },
    { value: "1", label: "Sunday" },
    { value: "2", label: "Monday" },
    { value: "3", label: "Tuesday" },
    { value: "4", label: "Wednesday" },
    { value: "5", label: "Thrusday" },
    { value: "6", label: "Friday" },
    { value: "7", label: "Saturday" },
  ];

  const fdata = {
    id: sfdetails.id,
    display_name: sfdetails.display_name,
    company_name: sfdetails.company_name,
    address: sfdetails.address,
    pincode: sfdetails.pincode,
    state_id: sfdetails.state_id,
    city: sfdetails.city,
    company_type: sfdetails.company_type,
    non_working_days: sfdetails.non_working_days,
    rm_id: sfdetails.rm_id,
    asm_id: sfdetails.asm_id,
  };

  const [cityOption, setCityOption] = useState([]);
  const [cityValue, setCityValue] = useState(fdata.city);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState(fdata);
  const [rmOption, setRMOption] = useState([]);
  const [rmValue, setRMValue] = useState(fdata.rm_id);
  const [asmOption, setASMOption] = useState([]);
  const [asmValue, setASMValue] = useState(fdata.asm_id);
  const [ctypeValue, setCtypeValue] = useState(
    getCompanyType.find((x) => x.value === sfdetails.company_type)
  );

  const [nonWValue, setNonWValue] = useState(
    getnon_working_days.find((x) => x.value === sfdetails.non_working_days)
  );

  const getAllCity = async (pincode, callback) => {
    setCityOption([]);
    setCityValue(null);

    const listdata = await httpRequest(
      `getAllCity?pincode=${pincode}`,
      {},
      "GET"
    );

    if (listdata.status === true && listdata.message) {
      setCityOption(listdata.message);
      setFormData((prevFormData) => ({
        ...prevFormData,
        state_id: listdata.message[0].state.id,
        pincode,
      }));

      if (sfdetails.city) {
        const c = listdata.message.find(
          (option) => option.label === sfdetails.city
        );
        setCityValue(c);
      }

      // Execute the callback after updating city options
      if (callback) {
        callback();
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (sfdetails.pincode) {
        getAllCity(sfdetails.pincode);
      }

      const rmData = await httpRequest(
        "getrm-asm-list",
        { role: "Regional Manager" },
        "POST"
      );
      if (rmData.status === true && rmData.data) {
        setRMOption(rmData.data);
        setRMValue(rmData.data.find((x) => x.value === sfdetails.rm_id));
      }

      const asmData = await httpRequest(
        "getrm-asm-list",
        { role: "Area Sales Manager" },
        "POST"
      );
      if (asmData.status === true && asmData.data) {
        setASMOption(asmData.data);
        setASMValue(asmData.data.find((x) => x.value === sfdetails.asm_id));
      }
    })();

    //eslint-disable-next-line
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    //console.log(value);
    if (!value.display_name) {
      errors.display_name = "Display Name is Required";
    }

    if (!value.company_name) {
      errors.company_name = "Company Name is Required";
    }
    if (!value.company_type) {
      errors.company_type = "Company Type is Required";
    }
    if (!value.pincode) {
      errors.pincode = "Pincode is Required";
    }

    if (!value.state_id) {
      errors.state_id = "State is Required";
    }

    if (!value.city) {
      errors.city = "City is Required";
    }
    if (!value.address) {
      errors.address = "Address is Required";
    }
    if (!value.non_working_days) {
      errors.non_working_days = "Non Working Day is Required";
    }

    if (!value.rm_id) {
      errors.rm_id = "RM Name is Required";
    }

    //console.log(errors);
    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const data = await httpRequest(
          "addupdatebasicdetails",
          formData,
          "POST"
        );
        setSubmit(false);

        if (data.status === true && data.message) {
          SetSfDetails(data.data);
          toast.success(data.message);
          if (!formData.id) {
            navigate(`/updateservicecenter/${data.data.id}`);
          }
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <form id="addbasicdetails" onSubmit={onSubmitHandler}>
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Company Details</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Display Name *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.display_name}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        name="display_name"
                        defaultValue={sfdetails.display_name}
                        placeholder="Enter Display Name"
                        id="display_name"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            display_name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* /.form-group */}
                    <div className="form-group">
                      <label>
                        Address *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.address}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        name="address"
                        defaultValue={sfdetails.address}
                        placeholder="Enter Company Address"
                        id="address"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            address: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* /.form-group */}
                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Company Name *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.company_name}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        name="company_name"
                        defaultValue={sfdetails.company_name}
                        placeholder="Enter Company Name"
                        id="display_name"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            company_name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* /.form-group */}
                    <div className="form-group">
                      <label>
                        Pincode *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.pincode}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength="6"
                        name="pincode"
                        defaultValue={sfdetails.pincode}
                        placeholder="Enter Pincode"
                        id="pincode"
                        onChange={(e) => {
                          if (`${e.target.value}`.length === 6) {
                            console.log(e.target.value);
                            getAllCity(e.target.value);
                            setFormData({
                              ...formData,
                              pincode: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                    {/* /.form-group */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}

                <div className="row">
                  {/* /.col */}
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        City * {cityValue === null && "Loading..."}{" "}
                        <span style={{ color: "red" }}>{formErrors.city}</span>
                      </label>
                      <div className="select2-purple">
                        <Select
                          name="city"
                          placeholder="Select City"
                          value={cityValue}
                          options={cityOption}
                          onChange={(val) => {
                            setFormData({ ...formData, city: val.value });
                            setCityValue(val);
                          }}
                        />
                      </div>
                    </div>
                    {/* /.form-group */}
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Company Type *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.company_type}
                        </span>
                      </label>
                      <div className="select2-purple">
                        <Select
                          name="company_type"
                          value={ctypeValue}
                          options={getCompanyType}
                          placeholder="Select Company Type"
                          onChange={(val) => {
                            setFormData({
                              ...formData,
                              company_type: val.value,
                            });
                            setCtypeValue(val);
                          }}
                        />
                      </div>
                    </div>
                    {/* /.form-group */}
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Non Working Day *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.non_working_days}
                        </span>
                      </label>
                      <div className="select2-purple">
                        <Select
                          name="non_working_days"
                          value={nonWValue}
                          options={getnon_working_days}
                          placeholder="Select Non Working Day"
                          onChange={(val) => {
                            setFormData({
                              ...formData,
                              non_working_days: val.value,
                            });
                            setNonWValue(val);
                          }}
                        />
                      </div>
                    </div>
                    {/* /.form-group */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {/* Visit <a href="https://select2.github.io/">Select2 documentation</a> for more examples and information about
        the plugin. */}
              </div>
            </div>

            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">RM & ASM Details</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        RM Name *{" "}
                        <span style={{ color: "red" }}>{formErrors.rm_id}</span>
                      </label>

                      <Select
                        name="rm_id"
                        value={rmValue}
                        options={rmOption}
                        placeholder="Select RM"
                        onChange={(val) => {
                          setRMOption(val);
                          setFormData({ ...formData, rm_id: val.value });
                        }}
                      />
                    </div>
                    {/* /.form-group */}
                  </div>
                  {/* /.col */}
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>ASM Name </label>
                      <div className="select2-purple">
                        <Select
                          name="asm_id"
                          placeholder="Select ASM"
                          value={asmValue}
                          options={asmOption}
                          onChange={(val) => {
                            setFormData({ ...formData, asm_id: val.value });
                            setASMOption(val);
                          }}
                        />
                      </div>
                    </div>
                    {/* /.form-group */}
                  </div>

                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {/* Visit <a href="https://select2.github.io/">Select2 documentation</a> for more examples and information about
        the plugin. */}
              </div>
            </div>
            {/* /.card */}
            {/* /.row */}
            {roleId == 1 && (
              <div className="card card-default">
                {/* /.card-header */}
                <div className="card-body">
                  <div className="row">
                    <input
                      type="submit"
                      className="form-control btn btn-primary"
                      value={"Submit"}
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  );
}
