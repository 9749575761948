import React, { useEffect, useState } from "react";
import MainBody from "../../../component/MainBody";
import { openSignUrl } from "../../../utils/HelperFunction";
import httpRequest from "../../../utils/Http";
import { useSelector } from "react-redux";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import MyDataTable from "../../../component/MyDataTable";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PARTNER_TYPE,
} from "../../../constant/status_constant";

const SpareSummary = () => {
  const loggedInUser = useSelector((state) => state.authReducer.currentUser);

  const [partnerList, setPartnerList] = useState([]);
  const [applianceList, setApplianceList] = useState([]);
  const [formData, setFormData] = useState({});
  const [reloadList, setReload] = useState(false);
  const [fileHistory, setFileHistory] = useState(true);
  const [dateOfRequest, setDateOfRequest] = useState([]);
  const [forwardDispatch, setForwardDispatch] = useState([]);
  const formErrors = {}


  useEffect(() => {
    if (Number(loggedInUser.entity_type) === PARTNER_TYPE) {
      setFormData((prev) => ({
        ...prev,
        partner_id: loggedInUser.entity_id,
      }));
    }
  }, [loggedInUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [partnerRes, applianceRes] = await Promise.all([
          
          httpRequest("getpartnerlist?is_active=1", {}, "GET"),
          httpRequest("getappliancelist?isactive=1", {}, "GET"),
        ]);

        if (partnerRes.status) setPartnerList(partnerRes.data?.rows || []);
        if (applianceRes.status) setApplianceList(applianceRes.data?.rows || []);

      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const formattedData = {
      ...formData,
      dateOfRequest: dateOfRequest,
      forwardDispatch: forwardDispatch,
    };
    setFormData(formattedData);
  }, [dateOfRequest, forwardDispatch]);

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "File Name",
      selector: (row) => row.file_name,
      cell: (row) => (
        row.file_path ? (
        <Link
          to="#"
          className="btn-link text-primary"
          onClick={() => openSignUrl(row.file_path)}
        >
          {row.file_name}
        </Link>
        ) :(
            <>
            {row.file_name}
            </>
        )
        
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
  ];

  useEffect(() => {
    if (reloadList) {
      const fetchData = async () => {
        try {
          const response = await httpRequest("getsparesummaryreports", formData, "POST");
          if (response.status) {
            setFileHistory(true);
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          console.error("Error fetching completed TAT report:", error);
          toast.error(error.message);
        } finally {
          setReload(false);
        }
      };

      fetchData();
    }
  }, [reloadList, formData]);

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value ? value.value : null }));
  };

  return (
    <div>
      <MainBody
        breadcrumbTitle="Spare Summary Reports"
        breadcrumbSubTitle=""
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <section className="col-lg-12 connectedSortable ui-sortable">
                    <div className="card">
                      <div className="card-header ui-sortable-handle" style={{ cursor: "move" }}>
                        <h3 className="card-title">
                          <i className="fa-solid fa-file-export mr-1"></i>
                          Spare Summary Reports
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="tab-content p-0">
                          <div className="card-header ui-sortable-handle" style={{ cursor: "move" }}>
                            <div className="row">
                              {Number(loggedInUser.entity_type) !== PARTNER_TYPE && (
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>
                                      Partner{" "}
                                      <span style={{ color: "red" }}>
                                        {formErrors.partner_id}
                                      </span>
                                    </label>
                                    <Select
                                      name="partner_id"
                                      value={partnerList.find(option => option.value === formData.partner_id) || null}
                                      options={partnerList}
                                      onChange={(val) => handleChange("partner_id", val)}
                                      isClearable
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    Appliance{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.appliance_id}
                                    </span>
                                  </label>
                                  <Select
                                    name="appliance_id"
                                    value={applianceList.find(option => option.value === formData.appliance_id) || null}
                                    options={applianceList}
                                    onChange={(val) => handleChange("appliance_id", val)}
                                    isClearable
                                  />
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    Date of Request{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.dateOfRequest}
                                    </span>
                                  </label>
                                  <Flatpickr
                                    value={dateOfRequest}
                                    placeholder="Date Of Request"
                                    style={{ backgroundColor: "#fff" }}
                                    onChange={(dates) => setDateOfRequest(dates)}
                                    className="form-control"
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    Forward Dispatch Date{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.forwardDispatch}
                                    </span>
                                  </label>
                                  <Flatpickr
                                    value={forwardDispatch}
                                    style={{ backgroundColor: "#fff" }}
                                    onChange={(dates) => setForwardDispatch(dates)}
                                    placeholder="Forward Dispatch"
                                    className="form-control"
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>
                                    .{" "}
                                    <span style={{ color: "red" }}>
                                      {formErrors.submit}
                                    </span>
                                  </label>
                                  <input
                                    name="submit"
                                    id="submit"
                                    type="submit"
                                    className="form-control btn btn-primary"
                                    onClick={() => setReload(true)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">File Requested</h3>
                      </div>
                      <MyDataTable
                        reloadList={fileHistory}
                        columns={columns}
                        getDataListURL="getfileuploadhistory?type=SPARE-SUMMARY"
                        setReload={setFileHistory}
                        exportName="summaryreport"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default SpareSummary;
