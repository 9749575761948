import React, { useEffect, useState } from "react";
import MainBody from "../../../component/MainBody";
import AddUpdateSF from "./AddUpdateSF";
import { useParams } from "react-router-dom";
import httpRequest from "../../../utils/Http";
import { useSelector } from "react-redux";

const AddUpdateServiceCenter = () => {
  const user = useSelector((state) => state.authReducer.currentUser);
  const [sfdetails, SetSfDetails] = useState({});
  const { id } = useParams();


  useEffect(() => {
    (async () => {
      if (id)
      {
        const listdata = await httpRequest(
           "getsfdetails?id=" + id, {}, "GET");

        if (listdata.status === true && listdata.data)
        {
          SetSfDetails(listdata.data['rows'][0]);

        }
      }

    })();

  }, [id]);

  return (
    <div>
      {id && Object.keys(sfdetails).length == 0 && (
        <>
          <MainBody
            breadcrumbTitle="Invalid Url"
            breadcrumbSubTitle={""}
            navButton={""}
            title={""}
            component={<></>}
          />
        </>
      )}

      {Object.keys(sfdetails).length > 0 && (
        <>
          <MainBody
            breadcrumbTitle="Update Service Center"
            breadcrumbSubTitle={""}
            navButton={""}
            title={""}
            component={
              <AddUpdateSF 
              sfdetails={sfdetails} 
              SetSfDetails={SetSfDetails} 
              roleId ={user.role_id}
              />
            }
          />
        </>
      )}

      {!id && Object.keys(sfdetails).length == 0 && (
        <>
          <MainBody
            breadcrumbTitle="Add Service Center"
            breadcrumbSubTitle={""}
            navButton={""}
            title={""}
            component={
              <AddUpdateSF sfdetails={sfdetails} SetSfDetails={SetSfDetails}  roleId ={user.role_id}/>
            }
          />
        </>
      )}
    </div>
  );
};

export default AddUpdateServiceCenter;
