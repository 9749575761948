import React, { useEffect, useState } from "react";
import httpRequest from "../../../utils/Http";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyDataTable from "../../../component/MyDataTable";
import Switch from "../../../component/Switch";
import { ONLY_RM_ASM_ACTION_PERMISSION } from "../../../constant/action_permission";

const AddUpdateSubOffice = ({ sfdetails, roleId }) => {
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({ sf_id: sfdetails.id });
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isOpenAddForm, setOpenAddForm] = useState(false);
  const [reloadList, setReload] = useState(true);

  useEffect(() => {
    (async () => {
      const state = await httpRequest("getallstatelist", {}, "GET");
      //console.log(partners);
      if (state.status === true && state.message) {
        setStateList(state.message);
      } else {
        setStateList([]);
      }
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formData.state_id) {
      (async () => {
        const city = await httpRequest(
          `getallcityfromstate?state_id=${formData.state_id}`,
          {},
          "GET"
        );
        //console.log(partners);
        if (city.status === true && city.message) {
          setCityList(city.message);
        } else {
          setFormData({ ...formData, city: null });
          setCityList([]);
        }
      })();
    }
    //eslint-disable-next-line
  }, [formData.state_id]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};
    console.log(value);
    // Required fields with corresponding error messages
    const requiredFields = [
      { field: "state_id", message: "State is Required" },
      { field: "city", message: "City is Required" },
      { field: "pincode", message: "Pincode is Required" },
      { field: "rate", message: "Rate is Required" },
      { field: "municipal_limit", message: "Municipal Limit is Required" },
    ];

    // Check if required fields are present and not empty
    requiredFields.forEach(({ field, message }) => {
      if (!value[field]) {
        errors[field] = message;
      }
    });

    const pincode = value.pincode ? value.pincode.toString().trim() : "";

    // Debugging statement to check the value of pincode
    console.log("Pincode:", value.pincode.length);

    // Validate pincode length
    if (pincode && pincode.length !== 6) {
      errors.pincode = "Enter Valid Pincode";
    }

    // Validate municipal limit if it is less than 0
    if (value.municipal_limit && value.municipal_limit < 0) {
      errors.municipal_limit = "Municipal Limit is Required";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        document.getElementById("addsubform").reset();

        console.log(formData);
        const data = await httpRequest("addupdatesuboffice", formData, "POST");

        setSubmit(false);
        if (data.status === true && data.message) {
          setFormData({ sf_id: sfdetails.id });
          setReload(true);
          setOpenAddForm(false);
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const updateToggleHandler = async (id, value) => {
    try {
      setReload(false);
      let postData = {
        id,
        is_active: value === true ? 1 : 0,
      };
      const data = await httpRequest("updatesfsubstatus", postData, "POST");

      if (data.status === true && data.message) {
        setReload(true);

        toast.success(data.message);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    } catch (error) {}
  };

  const columns = [
    {
      name: "SNo.",
      selector: (row, index) => index + 1,
    },
    {
      name: "State",
      selector: (row) => row.state.state,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Pincode",
      selector: (row) => row.pincode,
    },
    {
      name: "Municipal Limit",
      selector: (row) => row.municipal_limit,
    },
    {
      name: "Rate",
      selector: (row) => `${row.rate} /KM`,
    },
    ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && {
      name: "Status",
      selector: (row) =>
        Number(row.active) === 1 ? "Activated" : "DeActivated",
      cell: (row) => (
        <Switch
          isOn={Number(row.is_active) === 1 ? true : false}
          id={row.id}
          handleToggle={(e) => {
            updateToggleHandler(row.id, e.target.checked);
          }}
          onColor="#EF476F"
        />
      ),
    },
    {
      name: "Create Date",
      selector: (row) => new Date(row.createdAt).toDateString(),
    },
    ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && {
      name: "Update",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            setFormData({
              sf_id: sfdetails.id,
              id: row.id,
              state_id: row.state_id,
              city: row.city,
              pincode: row.pincode,
              rate: row.rate,
              municipal_limit: row.municipal_limit,
            });
            setOpenAddForm(true);
          }}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
  ].filter(Boolean);

  return (
    <div>
      <section className={`content ${isOpenAddForm ? "" : "hide_display"}`}>
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">Add Sub District Office</h3>
              <div
                className="card-tools"
                onClick={() => {
                  setOpenAddForm(false);
                  setFormData({ sf_id: sfdetails.id });
                }}
              >
                <i className="fas fa-minus"></i>
              </div>
            </div>
            <form id="addsubform" onSubmit={onSubmitHandler}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        State *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.state_id}
                        </span>
                      </label>
                      <Select
                        name="state_id"
                        value={
                          stateList.find(
                            (option) => option.value === formData.state_id
                          ) || null
                        }
                        options={stateList}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            state_id: val.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        City *{" "}
                        <span style={{ color: "red" }}>{formErrors.city}</span>
                      </label>
                      <Select
                        name="state_id"
                        value={
                          cityList.find(
                            (option) => option.value === formData.city
                          ) || null
                        }
                        options={cityList}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            city: val.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        Pincode *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.pincode}
                        </span>
                      </label>
                      <input
                        name="pincode"
                        id="pincode"
                        placeholder="Enter Pincode"
                        className="form-control"
                        defaultValue={formData.pincode}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            pincode: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        Municipal Limit *{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.municipal_limit}
                        </span>
                      </label>
                      <input
                        name="municipal_limit"
                        id="municipal_limit"
                        placeholder="Enter Municipal Limit"
                        className="form-control"
                        defaultValue={formData.municipal_limit}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        Rate *{" (Per KM) "}
                        <span style={{ color: "red" }}>{formErrors.rate}</span>
                      </label>
                      <input
                        name="rate"
                        id="rate"
                        placeholder="Enter Rate"
                        className="form-control"
                        defaultValue={formData.rate}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        .{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.submit}
                        </span>
                      </label>
                      <input
                        name="submit"
                        id="submit"
                        type="submit"
                        className="form-control btn btn-primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card" style={{ padding: "10px" }}>
                <div className="card-header">
                  <h3 className="card-title">Sub District Office List</h3>
                  {ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && (
                    <button
                      className="btn btn-sm btn-primary float-right"
                      onClick={() => {
                        setOpenAddForm(true);
                      }}
                    >
                      Add
                    </button>
                  )}
                </div>
                <div className="card-body">
                  <MyDataTable
                    reloadList={reloadList}
                    columns={columns}
                    getDataListURL={`getsfsuboffice?sf_id=${sfdetails.id}`}
                    setReload={setReload}
                    exportName={"subofficelist"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddUpdateSubOffice;
